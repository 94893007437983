import React from 'react';
import { Button, Modal, connectModal, Alert } from '@gsa/afp-component-library';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import z from 'zod';

function CancelLeasingRequisitionModal({ handleClose, handleCancel, requisitionNumber }) {
  const schema = z.object({
    comment: z.string().min(1, 'Please provide a reason for cancelling the requisition.')
      .max(500, 'Comment cannot be more than 500 characters')
  })

  const content = {
    buttonControls: { submit: false, cancel: false },
    sections: [
      {
        fields: [
          {
            id: 'comment',
            type: 'textarea',
            label: 'Please provide comments for this cancellation',
            required: true,
            element: {
              control: {
                placeholder: 'Enter comments here',
                maxLength: 500,
                countdown: true,
              }
            }
          }
        ]
      }
    ]
  }

  const handleSubmit = (data) => {
    handleCancel(data.comment);
    handleClose();
  }

  return (
    <Modal
      title="Are you sure you want to cancel this requisition?"
      onClose={handleClose}
      actions={
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <Button
            type="button"
            variant="unstyled"
            onClick={handleClose}
            label="Go back"
          />
          <Button
            type="submit"
            variant="secondary"
            form='cancel-leasing-requisition-form'
            label="Confirm cancel"
          />
        </div>
      }
    >
      <div>{requisitionNumber}</div>
      <FormGenerator
        className='margin-top-2 margin-bottom-5'
        schema={schema}
        content={content}
        onSubmit={handleSubmit}
        id='cancel-leasing-requisition-form'
      />

      <Alert type='info'>
        Canceled requisitions can be reopened through the Actions column. Canceled requisitions will be deleted after 365 days.
      </Alert>
    </Modal>
  );
}

export default connectModal(CancelLeasingRequisitionModal);