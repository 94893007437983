import { cloneDeep } from "lodash";
import AccordionContent from "@/components/AddOptions/components/AccordionContent/AccordionContent.jsx";
import React, { useMemo } from "react";
import { Accordion, } from '@gsa/afp-component-library';
import { useDispatch, useSelector } from "react-redux";
import { OPTION_GROUP_SELECTION_TYPE } from "../../../../utilities/CompareVehicleUtils.jsx";
import {
    setCalculatePriceDisabled,
    setSelectedDeliveryOption,
    setSelectedOptions
} from "../../../../reducers/leasing.jsx";

export const DeliveryOptionsSection = ({ deliveryOptions, handleRowCheckBoxClick }) => {

    const {
        selectedOptions,
        selectedDeliveryOption,
        pagination
    } = useSelector((state) => state.leasingReducer.compareSelect);
    const {page, offset} = pagination;
    const localDeliveryOptions = cloneDeep(deliveryOptions);
    const start = page * offset;
    const end = start + offset;
    const dispatch = useDispatch();

    const deliveryOptionsToDisplay = useMemo(() => {
        localDeliveryOptions.forEach((optCat) => {
            optCat.options.forEach((optn) => {
                const modOptn = optn;
                const isOptionSelected = selectedDeliveryOption.find(
                    (opt) => opt.optionCode === optn.optionCode,
                );
                if (isOptionSelected) {
                    modOptn.isChecked = true;
                    modOptn.optionValue = isOptionSelected.optionQuantity
                        ? isOptionSelected.optionQuantity
                        : 1;
                }else {
                    modOptn.isChecked = false;
                    modOptn.optionValue = 1;
                }
                modOptn.vendorValues =
                    modOptn.vendorValues.slice(start, end);
            });
        });
        return localDeliveryOptions;
    }, [selectedDeliveryOption, localDeliveryOptions]);

    const handleDeliveryOptionSelection = (
        selectedOptCode
    ) => {
        const prevSelectedDeliveryOption = selectedOptions.find(opt => opt?.isDeliveryOption);
        if (prevSelectedDeliveryOption?.optionCode === selectedOptCode) {
            dispatch(setSelectedDeliveryOption([]))
            dispatch(setSelectedOptions(selectedOptions.filter(opt => !opt?.isDeliveryOption)))
            dispatch(setCalculatePriceDisabled(false))
        } else {
            let newSelectedOption = [];
            deliveryOptionsToDisplay.forEach((deliveryOption) => {
                if (deliveryOption.selectionType === OPTION_GROUP_SELECTION_TYPE.SINGLE) {
                    deliveryOption.options.forEach((option) => {
                        if (option.optionCode === selectedOptCode) {
                            newSelectedOption = [{...option, isChecked: true, isDeliveryOption: true}];
                        }
                    });
                }
            });

            dispatch(setSelectedDeliveryOption(newSelectedOption));
            dispatch(setSelectedOptions([
                ...selectedOptions.filter(opt => !opt?.isDeliveryOption),
                ...newSelectedOption
            ]))
            dispatch(setCalculatePriceDisabled(false))
        }
    };
    const getDeliveryOptionsContent = () => {
        return [
            {
                title: (
                    <div
                        className="add-options-accordion-title"
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        Delivery Options (choose one)
                    </div>
                ),
                content: (
                    <>
                        {deliveryOptionsToDisplay && !!deliveryOptionsToDisplay.length ? (
                            <>
                                <div className="option-table">
                                    <table
                                        className="add-option-main-container delivery-option-accordion"
                                        role="presentation"
                                    >
                                        <tbody>
                                        <AccordionContent
                                            addOptionsState={deliveryOptionsToDisplay}
                                            handleRowCheckBoxClick={handleRowCheckBoxClick}
                                            onNumInputChange={() => {}}
                                            onNumInputFocusChanged={() => {}}
                                            handleRadioSelection={handleDeliveryOptionSelection}
                                            lowestBidData={{}}
                                        />
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        ) : (
                            <>Not Available</>
                        )}
                    </>
                ),
                expanded: false,
            }, // eo Delivery Option section
        ];
    };

    return (
        <div id="add-options">
            <Accordion
                items={getDeliveryOptionsContent()}
                id='delivery-options-accordion'
                className="add-delivery-options-accordion"
                bordered={false}
            />
        </div>
    );
}
export default DeliveryOptionsSection;