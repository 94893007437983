import { Navigate, useParams, Outlet } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from './components/Breadcrumbs';
import {
  StepIndicator,
  Spinner,
  Button,
  useModal,
  StatusBadge,
  Alert
} from '@gsa/afp-component-library';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCurrentStep,
  setLeasingAccount,
  setLeasingSteps,
  setShipmentState,
  setShipmentLocation,
  setShipmentCountry,
  setReplacementVehicles,
  setRequisitionId,
  setSelectedStandardItem,
  setZoneData,
  setSelectedOptions,
  setSelectedColors,
  setSelectedContract,
  setSelectedDeliveryOption,
  setVehicleQuantity,
  setPaintAndGraphicsDescription,
  setCreatedByUserId,
  setMarshallingAddress,
  setDealershipDeliveryAddress,
  setRequisitionCustomerAccount,
  setBillingInformation,
  setRequisitionName,
  setRequisitionStatus,
  setViewMode,
  setRequisitionNumber,
  setDestinationType,
  setSelectedVehicleType,
  setSelectedVehicleSubType,
  setNonLowBidJustification,
  setReferralReplacement,
} from '../../reducers/leasing.jsx';
import {
  LeasingSteps,
  InitialLeasingSteps,
  RequisitionViewMode,
} from './leasing-consts.js';
import SaveDraftModal from './components/save-draft/save-draft-modal.jsx';
import { RequisitionStatus } from '../ReviewDetails/RequisitionDetailsUtils';
import {
  useGetLeasingReplacementVehicles,
  useGetLeasingRequisition,
  useGetVehicleByFilterWithZones,
  useGetZones,
} from '../../requests/leasing.jsx';
import useLeasingNavigation from '../../hooks/useLeasingNavigation.jsx';
import { NotFound } from '@gsa/afp-shared-ui-utils';
import { RequisitionStatusType } from '../my-requisitions/tableHelpers.jsx';
import './leasing-steps.scss';
import useSubmitLeasingRequisition from '../../hooks/useSubmitLeasingRequisition.jsx';

export const getStep = (steps, step) => {
  const foundStepIndex = steps.findIndex((s) => s.key === step);

  if (foundStepIndex === -1) return null;

  return steps[foundStepIndex];
};

const LeasingStepsPage = () => {
  const [ready, setReady] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const params = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const pathEndsWith = location.pathname.replace(/\/$/, '').split('/').pop();
  const requisitionId = params.requisitionId;
  const {
    leasingSteps,
    currentStep,
    account,
    replacementVehiclesTableState,
    viewMode,
    requisitionName,
    requisitionStatus,
    requisitionNumber,
    selectedStandardItem,
    shipmentLocation,
  } = useSelector((state) => state.leasingReducer);
  const { isOpen, openModal, closeModal } = useModal();
  const { removeRequisitionIdFromUrl } = useLeasingNavigation();
  const [getReplacementVehicles, { loading: loadingReplacementVehicles }] =
    useGetLeasingReplacementVehicles();
  const [getVehicleByFilter, { loading: loadingAccount }] =
    useGetVehicleByFilterWithZones();

  let heading = {
    current: currentStep?.stepNumber,
    text: currentStep?.label,
  };

  useGetZones().then(({ data: zones }) => {
    dispatch(setZoneData(zones));
  });

  const { determineRequisitionViewMode } = useSubmitLeasingRequisition();

  useGetLeasingRequisition(requisitionId).then(
    async ({ data: requisition }) => {
      let validSteps = [...InitialLeasingSteps];
      if (
        requisition?.clientData?.clientState?.selectedContract
          ?.isLowBidContract === false
      ) {
        const nonLowBidJustification = {
          stepNumber: 4,
          key: LeasingSteps.NON_LOW_PRICE_JUSTIFICATION,
          label: 'Non-low price justification',
          status: 'not completed',
        };

        validSteps = [
          ...validSteps.slice(0, 3),
          nonLowBidJustification,
          ...validSteps.slice(3).map((step) => ({
            ...step,
            stepNumber: step.stepNumber + 1,
          })),
        ];
      }

      let stepIndicator = getStep(validSteps, pathEndsWith);
      const clientState = requisition?.clientData?.clientState;
      const selectedOptionsFromDraft =
        requisition?.clientData?.selectedOptions?.selectedOptions || [];
      if (pathEndsWith === requisition?.requisitionId) {
        stepIndicator = getStep(validSteps, clientState?.currentStep?.key);
      }

      let steps = validSteps.map((step) => {
        if (stepIndicator?.stepNumber === step?.stepNumber) {
          return { ...step, status: 'current' };
        } else if (stepIndicator?.stepNumber > step?.stepNumber) {
          return { ...step, status: 'completed' };
        }
        return step;
      });

      const viewMode = determineRequisitionViewMode(requisition);
      dispatch(setViewMode(viewMode));

      if (!requisition && requisitionId) {
        // removeRequisitionIdFromUrl();
      }
      if (selectedOptionsFromDraft?.length > 0) {
        const deliveryOption = selectedOptionsFromDraft.find(
          (option) => option.isDeliveryOption,
        );
        if (deliveryOption) {
          dispatch(setSelectedDeliveryOption([deliveryOption]));
        }
      }
      dispatch(setReferralReplacement(requisition?.referralReplacement));
      dispatch(setRequisitionName(requisition?.friendlyName));
      dispatch(setRequisitionNumber(requisition?.requisitionNumber));
      dispatch(setCreatedByUserId(requisition?.createdBy));
      dispatch(setRequisitionStatus(requisition?.requisitionStatus));
      dispatch(setLeasingSteps(steps));
      dispatch(
        setDestinationType({
          type: clientState?.destinationType,
          loadedFromDraft: true,
        }),
      );
      dispatch(setShipmentState(clientState?.selectedState || 'Select state'));
      dispatch(setShipmentLocation(clientState?.shipmentLocation));
      dispatch(
        setShipmentCountry(clientState?.shipmentCountry || 'Select country'),
      );
      dispatch(setSelectedVehicleType(clientState?.selectedVehicleType));
      dispatch(setSelectedVehicleSubType(clientState?.selectedVehicleSubType));
      dispatch(setNonLowBidJustification(clientState?.nonLowBidJustification));
      dispatch(setCurrentStep(stepIndicator));
      dispatch(setSelectedStandardItem(clientState?.selectedStandardItem));
      dispatch(setSelectedOptions(selectedOptionsFromDraft));
      dispatch(
        setPaintAndGraphicsDescription(
          clientState?.paintAndGraphicsDescription,
        ),
      );
      dispatch(
        setSelectedColors([
          {
            color: {
              value: requisition?.vehicles[0]?.makeColorCode,
              label: requisition?.vehicles[0]?.makeColorName,
              price: requisition?.vehicles[0]?.makeColorPriceToCustomer,
              vendorPrice: requisition?.vehicles[0]?.makeColorPriceToGsa,
            },
            quantity: requisition?.quantity,
          },
        ]),
      );
      dispatch(setMarshallingAddress(clientState?.marshallingAddress));
      dispatch(
        setDealershipDeliveryAddress(clientState?.dealershipDeliveryAddress),
      );

      if (clientState?.selectedContract && requisition?.optionalEquipmentRate) {
        clientState.selectedContract.leaseRates.optionalEquipmentRate =
          requisition.optionalEquipmentRate;
      }

      if (clientState?.selectedContract && requisition?.optionalEquipmentRate) {
        clientState.selectedContract.leaseRates.optionalEquipmentRate = requisition.optionalEquipmentRate;
      }
      
      dispatch(setSelectedContract(clientState?.selectedContract));
      if (requisition) {
        dispatch(
          setBillingInformation({
            requisitionNumber: requisition?.requisitionNumber,
            agencyOrderNumber: requisition?.agencyOrderNumber,
            finSignalCode: requisition?.finSignalCode,
            finFundCode: requisition?.finFundCode,
            agency: {
              agencyCode: requisition?.agencyCode,
              agencyName: requisition?.agencyInfo?.name,
            },
            bureau: {
              bureauCode: requisition?.bureauCode,
              bureauName: requisition?.bureauInfo?.name,
            },
            office: {
              officeCode: requisition?.officeCode,
              officeName: requisition?.officeInfo?.officeName,
            },
          }),
        );
        dispatch(setVehicleQuantity(requisition?.quantity));
        dispatch(setRequisitionId(requisition.requisitionId));
        dispatch(
          setRequisitionCustomerAccount(
            requisition?.requisitionLeasingCustomerAccount,
          ),
        );
        getReplacementVehicles({
          variables: {
            requisitionId: requisition.requisitionId,
            order: replacementVehiclesTableState.order,
            limit: replacementVehiclesTableState.limit,
            offset:
              (replacementVehiclesTableState.currentPage - 1) *
              replacementVehiclesTableState.limit,
          },
        }).then(({ data }) => {
          const replacementVehicles = data.rows;
          if (replacementVehicles?.length > 0) {
            dispatch(setReplacementVehicles(replacementVehicles));
            // get leasing account
            getVehicleByFilter({
              variables: {
                filters: [
                  {
                    operator: '$and',
                    conditions: [
                      {
                        key: 'id',
                        operator: '$exact',
                        value: replacementVehicles[0].assetId,
                      },
                    ],
                  },
                ],
              },
            }).then(({ data: account }) => {
              dispatch(setLeasingAccount(account));
              setReady(true);
            });
          } else {
            setReady(true);
          }
        });
      } else {
        setReady(true);

        if (requisitionId) {
          setNotFound(true);
        }
      }
    },
  );

  const handleSaveDraft = () => {
    openModal();
  };

  const loading =
    (leasingSteps?.length === 0 || !currentStep?.key || !ready) &&
    !(
      requisitionStatus === RequisitionStatus.ORDER_RECEIVED ||
      requisitionStatus === RequisitionStatusType.RECEIVED_BY_GSA
    );

  if (!ready) {
    if (
      !requisitionId &&
      !Object.values(LeasingSteps).some((step) => pathEndsWith === step)
    ) {
      return <Navigate to="/lease" />;
    }
  } else if (requisitionId && pathEndsWith === requisitionId) {
    if (
      requisitionStatus === RequisitionStatus.ORDER_RECEIVED ||
      requisitionStatus === RequisitionStatusType.RECEIVED_BY_GSA
    ) {
      return (
        <Navigate
          to={`/lease/${requisitionId}/${LeasingSteps.REVIEW_SUBMIT}`}
          replace
        />
      );
    } else {
      return (
        <Navigate
          to={`/lease/${requisitionId}/${
            currentStep?.key || LeasingSteps.REPLACEMENT_VEHICLES
          }`}
          replace
        />
      );
    }
  }

  const hasDealerTag = selectedStandardItem?.tags?.value?.includes('DEALER');

  const requisitionStatusBadge = requisitionStatus
    ? RequisitionStatusType[requisitionStatus] || {
        color: 'Warning-Gray',
        label: `INVALID STATUS (${value})`,
      }
    : null;

  if (notFound) {
    return (
      <NotFound
        goBackLink={{
          href: '/lease',
          text: 'Go back to lease requisitions start',
        }}
      />
    );
  }

  return (
    <>
      <div>
        <div className="leasing-top-section">
          <Breadcrumbs />
        </div>
        {loading ? (
          <Spinner size="large" className="margin-y-5" />
        ) : (
          <>
            <div className="leasing-requisition-header">
              {viewMode !== RequisitionViewMode.EDIT ? (
                <div className="requisition-heading">
                  <h1>{requisitionName}</h1>
                  {requisitionStatusBadge && (
                    <StatusBadge variant={requisitionStatusBadge?.color}>
                      {requisitionStatusBadge?.label}
                    </StatusBadge>
                  )}
                </div>
              ) : (
                <div className="requisition-heading-edit-wrapper">
                  {(hasDealerTag && currentStep.key === LeasingSteps.COMPARE_AND_SELECT) && (
                    <Alert type='info' className='dealer-delivery-alert'>
                      This order is for <strong>{account.customer.customerAgency.id}-{account.customer.customerAgency.name}/{account.customer.customerBureau.id}-{account.customer.customerBureau.name}</strong> to be shipped
                      to <strong>{shipmentLocation}</strong>.<br />Dealership delivery is standard for this vehicle. Other delivery options may be available under the Delivery option section.
                    </Alert>
                  )}

                  <div className="requisition-heading-edit">
                    <h1>Build a requisition </h1>
                    {requisitionNumber && (
                      <p>
                        <strong>{requisitionNumber}</strong>
                      </p>
                    )}
                    <p>
                      Use this feature to take the request approved by a GSA
                      leasing customer to create a requisition that becomes a
                      vehicle order.
                    </p>
                  </div>
                  <div>
                    {!requisitionId && !!account && (
                      <Button
                        label="Save draft"
                        variant="outline"
                        onClick={handleSaveDraft}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
            {viewMode === RequisitionViewMode.EDIT && (
              <StepIndicator
                className="leasing-step-indicator"
                counterSize="big"
                heading={heading}
                steps={leasingSteps}
              />
            )}
            <Outlet />
          </>
        )}
      </div>
      <SaveDraftModal isOpen={isOpen} onClose={closeModal} />
    </>
  );
};

export default LeasingStepsPage;
