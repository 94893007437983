import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button } from '@gsa/afp-component-library';
import CheckedIcon from '../../../assets/images/checked-icon';
import { SUBMIT_FOR_ENGINEERING_REVIEW } from '../../../services/data-layer';
import './SubmitToEngineerButton.scss';

export const ENGINEERING_REVIEW_STATUS = 'engineering-review';

const SubmitToEngineerButton = ({ requisitionId, requisitionName }) => {
  const navigate = useNavigate();

  const [submitForEngineerReviewMutation] = useMutation(
    SUBMIT_FOR_ENGINEERING_REVIEW,
    {
      onError: (error) => {
        // TODO
        // not covered in user story yet
        // eslint-disable-next-line no-console
        console.log(
          'Submit For Engineer errored out: ',
          error?.graphQLErrors[0].message,
        );
      },
      onCompleted: () => {
        navigate(
          `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${ENGINEERING_REVIEW_STATUS}`,
        );
      },
    },
  );

  const handleSubmitToEngineer = () => {
    submitForEngineerReviewMutation({
      variables: {
        requisitionId,
      },
    });
  };

  return (
    <>
      <Button
        data-testid="submit-to-engineer-button"
        type="button"
        label={
          <>
            <CheckedIcon /> Submit to engineering review
          </>
        }
        onClick={handleSubmitToEngineer}
      />
    </>
  );
};

SubmitToEngineerButton.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
};

export default SubmitToEngineerButton;
