import React, {
  useImperativeHandle,
  useMemo,
  useEffect,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import cn from 'classnames';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Button, useModal, connectModal } from '@gsa/afp-component-library';
import {
  SUBMIT_CONTRACTING_REVIEW,
  SUBMIT_CONTRACTING_REVIEW_FROM_CO_TO_ENGINEER,
} from '../../../services/data-layer';
import DeleteAdditionalReqFileModal from '../../../components/MultipleAdditionalRequirements/DeleteAdditionalReqFileModal/DeleteAdditionalReqFileModal';
import { transformAttachmentPayload } from '../../non-standard-purchase/urgent-requisition/utils/UrgReqCommonUtils';
import AreqVendorSection from '../AreqVendorSection/AreqVendorSection';
import { extractVendorInfoFromActiveContracts } from '../areqMultipleVendorUtil';
import { useNavigate } from 'react-router-dom';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

let currentIndex = 0;
const AreqAddMultipleVendors = ({
  reference,
  draftId,
  requisitionName,
  customClass,
  fromCoToEngineer,
  visible = true,
  activeContracts,
  selectedOptions,
  vendorQuotes,
  contractCostBreakDowns,
  isLeasing,
}) => {
  const navigate = useNavigate();

  const {
    state: { areqVendorQuoteDetails },
  } = useContext(VehicleRequisitionContext);

  const formProps = useForm({
    defaultValues: {
      vendorQuoteDetails: areqVendorQuoteDetails,
    },
    reValidateMode: 'onChange',
  });

  const { vendors = [], contractInfo = {} } = useMemo(() => {
    const buildableContracts = activeContracts.filter((contract) => {
      if (!contractCostBreakDowns || isLeasing) return true;

      return !contractCostBreakDowns.some(
        (contractCostBreakDown) =>
          contractCostBreakDown.contractLineId === contract.id &&
          contractCostBreakDown.isUnBuildable,
      );
    });

    return extractVendorInfoFromActiveContracts(
      buildableContracts,
      selectedOptions,
    );
  }, [activeContracts, contractCostBreakDowns, isLeasing, selectedOptions]);

  const { handleSubmit, getValues, setValue, reset } = formProps;

  const [submitContractingReviewMutation] = useMutation(
    fromCoToEngineer
      ? SUBMIT_CONTRACTING_REVIEW_FROM_CO_TO_ENGINEER
      : SUBMIT_CONTRACTING_REVIEW,
    {
      fetchPolicy: 'no-cache',
    },
  );
  const {
    fields: allVendorQuotes,
    append,
    remove: removeVendor,
    control,
  } = useFieldArray({
    control: formProps.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'vendorQuoteDetails', // unique name for your Field Array
  });

  useEffect(() => {
    if(areqVendorQuoteDetails?.length > vendorQuotes?.length){
      setValue("vendorQuoteDetails", areqVendorQuoteDetails);

    } else if (vendorQuotes?.length && vendors?.length) {
      const mappedQuotes = vendorQuotes.map((quote) => ({
        vendorId: vendors.find(({ label }) => quote.vendorName === label)
          ?.value,
        contractLineId: quote.contractLineId,
        makeCode: quote.makeCode,
        modelCode: quote.modelCode,
        makeModel: quote.makeModel,
        vendorQuote: [quote.quoteAttachment],
        description: quote.contractingOfficerComments,
        attachments: quote.supportingAttachments,
        priceEstimate: quote.areqPrice,
      }));
      setValue("vendorQuoteDetails", mappedQuotes);

    }
  }, [vendorQuotes, vendors]);

  const onSubmit = async (data) => {
    const vendorDetailsArr = [];
    data.vendorQuoteDetails.forEach((li) => {
      const supportingAttachments = transformAttachmentPayload(
        li.attachments || [],
      );
      const contractLine = contractInfo.find(
        (x) => x.contractLineId === +li.contractLineId,
      );
      if (!contractLine) throw new Error('contractLine not found');

      vendorDetailsArr.push({
        vendorId: li.vendorId,
        vendorName: contractLine.vendorName || '',
        makeCode: +contractLine.makeCode,
        modelCode: contractLine.modelCode,
        makeModel: li.makeModel,
        contractLineId: contractLine.contractLineId,
        contractingOfficerComments: li.description,
        totalPrice: Number(li.priceEstimate),
        extendedShippingDays: +li.extendedShippingDays || 0,
        quote: {
          name: li.vendorQuote[0].name,
          metadataId: li.vendorQuote[0].metadataId,
          description: li.vendorQuote[0].description,
          signedUrl: li.vendorQuote[0].signedUrl,
        },
        supportingDocuments: supportingAttachments,
      });
    });
    const payload = {
      requisitionId: draftId,
      requisitionQuotes: vendorDetailsArr,
    };

    await submitContractingReviewMutation({
      variables: payload,
    });

    navigate(
      `/my-requisitions?requisitionId=${draftId}&draftName=${requisitionName}`,
    );
  };

  const allFieldsData = getValues();
  useImperativeHandle(reference, () => ({
    ...reference.current,
    submitAreqMultipleVendors() {
      handleSubmit(onSubmit)();
    },
  }));


  const deleteFileModal = useModal();
  const DisplayDeleteFileModal = connectModal(DeleteAdditionalReqFileModal);

  const handleDeleteModal = (index) => {
    currentIndex = index;
    deleteFileModal.openModal();
  };

  const deleteFile = () => {
    setValue(`vendorQuoteDetails.${currentIndex}.vendorQuote`, []);
    deleteFileModal.closeModal();
  };

  if (!visible) {
    return null;
  }

  return (
    <FormProvider {...formProps}>
      <form
        data-testid="vendor-quote-form"
        onSubmit={handleSubmit}
        className={cn('vendor-quote-form', { [customClass]: customClass })}
      >
        <div className="container">
          <div className="areq-information-tab" role="tab" tabIndex="0">
            <div className="add-req-btn-container">
              <Button
                data-testid="add-vendor-btn"
                type="button"
                variant="primary"
                label="Add Vendor"
                leftIcon={{ name: 'add', className: 'plus-icon' }}
                onClick={() => {
                  append({
                    vendor: '',
                    makeModel: '',
                    vendorQuote: [],
                    description: '',
                    attachments: [],
                    priceEstimate: null,
                  });
                }}
              />
            </div>
            <div role="tab" tabIndex="0" className="additional-reqs-container">
              {allVendorQuotes.map((additionalReq, index) => (
                <AreqVendorSection
                  draftId={draftId}
                  index={index}
                  indexChar={String.fromCharCode('A'.charCodeAt() + index)}
                  removeVendor={removeVendor}
                  control={control}
                  isDeleteModaOpen={deleteFileModal.isOpen}
                  handleDelete={(val) => handleDeleteModal(val)}
                  vendors={vendors}
                  contractInfo={contractInfo}
                />
              ))}
            </div>

            <DisplayDeleteFileModal
              isOpen={deleteFileModal.isOpen}
              handleClose={deleteFileModal.closeModal}
              handleDelete={deleteFile}
              filename={
                allFieldsData.vendorQuoteDetails[currentIndex]?.vendorQuote[0]
                  ?.name
              }
            />
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

AreqAddMultipleVendors.propTypes = {
  reference: PropTypes.func.isRequired,
  draftId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  fromCoToEngineer: PropTypes.bool,
  visible: PropTypes.bool,
  activeContracts: PropTypes.instanceOf(Array),
  selectedOptions: PropTypes.instanceOf(Array),
  vendorQuotes: PropTypes.instanceOf(Array),
  contractCostBreakDowns: PropTypes.instanceOf(Array),
  isLeasing: PropTypes.bool,
};

AreqAddMultipleVendors.defaultProps = {
  customClass: '',
  fromCoToEngineer: false,
  visible: true,
  activeContracts: [],
  selectedOptions: [],
  vendorQuotes: [],
  contractCostBreakDowns: [],
  isLeasing: false,
};

export default AreqAddMultipleVendors;
