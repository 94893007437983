import React, { use, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { Button } from '@gsa/afp-component-library';
import './CancelRequisitionButton.scss';
import CancelRequisitionModal from '../../../components/CancelRequisitionModal/CancelRequisitionModal';
import { canUpdateRequisitionCart } from '../../../utilities/authorization';

const CancelRequisitionButton = ({ requisitionId, forceDisplay }) => {
  const ability = useAppAbility();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleCancelRequisitionModal = async () => {
    setModalOpen(true);
  };

  if (!forceDisplay && !canUpdateRequisitionCart(ability)) {
    return null;
  }

  return (
    <>
      <Button
        className="cancel-requisition-btn"
        variant="secondary"
        type="button"
        data-testid="cancel-requisition-btn"
        onClick={handleCancelRequisitionModal}
        leftIcon={{ name: 'cancel' }}
        label="Cancel requisition"
      />

      {isModalOpen && (
        <CancelRequisitionModal
          requisitionId={requisitionId}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
};

CancelRequisitionButton.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  forceDisplay: PropTypes.bool,
};

CancelRequisitionButton.defaultProps = {
  forceDisplay: false,
};

export default CancelRequisitionButton;
