import { Button, useModal } from "@gsa/afp-component-library";
import { useSelector ,useDispatch} from "react-redux";
import useLeasingNavigation from "../../../hooks/useLeasingNavigation";
import useSubmitLeasingRequisition from "../../../hooks/useSubmitLeasingRequisition";
import { useCurrentUser } from "@gsa/afp-shared-ui-utils";
import { RequisitionViewMode } from "../leasing-consts";
import {
setSubmitButtonDisabled
} from '../../../reducers/leasing'
import CancelLeasingRequisitionModal from "./cancel-modal/cancel-modal";

export default function LeasingNavigation({
    nextFormId,
    onNext,
    onCancel,
    onPrevious,
    hide,
    onSubmit,
    onReject,
}) {
    const { requisitionId, viewMode ,submitButtonDisabled, requisitionName} = useSelector((state) => state.leasingReducer);
    const dispatch = useDispatch();
    const { handleCancel } = useLeasingNavigation();
    const { currentUser } = useCurrentUser();
    const zonalAcquisitionCoordinator = currentUser?.roles?.some((role) => role?.id === '1504');
    const cancelModal = useModal();

    const { leasingRequisitionSubmitLabel,leasingRequisitionSubmitMethod:{loading} } = useSubmitLeasingRequisition();

    hide = hide || [];
    let nextLabel = 'Next';
    
    if (viewMode === RequisitionViewMode.REVIEW_EDIT) {
        hide.push('cancel', 'previous');
        nextLabel = 'Save and continue';
    }
    if(zonalAcquisitionCoordinator){
        hide.push('cancel');
    }
    if (!onCancel) {
        onCancel = handleCancel;
    }
    const onCancelAndResetSubmitDisabled = (comment) => {
        dispatch(setSubmitButtonDisabled(false));
        onCancel(comment)
    }

    const onPreviousAndResetSubmitDisabled = (e) => {
        dispatch(setSubmitButtonDisabled(false));
        onPrevious(e)
    }

    const onRejectAndResetSubmitDisabled = (e) => {
        dispatch(setSubmitButtonDisabled(false));
        onReject(e)
    }
    return (
        <>
            <div>
                {!hide.includes('previous') && (
                    <Button
                        variant='outline'
                        label='Previous'
                        leftIcon={{ name: 'arrow_back' }}
                        data-testid='previous-leasing-button'
                        onClick={onPreviousAndResetSubmitDisabled}
                    />
                )}
                {!hide.includes('cancel') && (
                    <Button
                        variant={requisitionId ? 'secondary' : 'outline'}
                        label={requisitionId ? 'Cancel requisition' : 'Cancel'}
                        data-testid='cancel-leasing-button'
                        onClick={() => cancelModal.openModal()}
                    />
                )}
                {!hide.includes('next') && (
                    <Button
                        label={nextLabel}
                        rightIcon={{ name: 'arrow_forward' }}
                        data-testid='next-leasing-button'
                        onClick={onNext}
                        type={nextFormId ? 'submit' : 'button'}
                        form={nextFormId}
                    />
                )}
                
                {!hide.includes('reject') && (
                    <Button
                        label='Return to submitter'
                        variant='secondary'
                        leftIcon={{ name: 'undo' }}
                        data-testid='reject-leasing-button'
                        onClick={onRejectAndResetSubmitDisabled}
                    />
                )}
                
                {!hide.includes('submit') && leasingRequisitionSubmitLabel && (
                    <Button
                        label={leasingRequisitionSubmitLabel}
                        leftIcon={{ name: 'check' }}
                        data-testid='submit-leasing-button'
                        onClick={onSubmit}
                        disabled ={submitButtonDisabled||loading}
                    />
                )}
            </div>


            <CancelLeasingRequisitionModal
                isOpen={cancelModal.isOpen}
                handleCancel={(comment) => {
                    onCancelAndResetSubmitDisabled(comment);
                }}
                handleClose={cancelModal.closeModal}
                requisitionNumber={requisitionName}
            />
        </>
    )
}