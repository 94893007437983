import React, {
  useEffect,
  useReducer,
  useState,
  useContext,
  useRef,
} from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import { Spinner } from '@gsa/afp-component-library';
import * as PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import vehicleRequisitionContextReducer from '../../context/VehicleRequisitionContext/VehicleRequisitionContextReducer';
import { vehicleRequisitionInitialState } from '../../context/VehicleRequisitionContext/VehicleRequisitionInitialState';
import {
  GET_BOAC_SIGNAL_CODE_FMC,
  GET_CALCULATED_PRICE,
  GET_PURCHASE_COLLISION_FOR_ALL_VENDOR_ON_YES,
  VALIDATE_ADDRESS,
  VALIDATE_BOAC,
  GET_CONTRACT_LINE_MODEL_COLORS,
  GET_REQ_ATTACHMENT_TYPES,
  VALIDATE_REQUISITION_NUMBER,
  GET_CONTRACT_BY_CONTRACT_NUMBER,
  UPDATE_REQUISITION_CLIENT_STATE,
  VALIDATE_REQUISITION,
} from '../../services/data-layer';
import { UPDATE_REQUISITION_DRAFT_NEW } from '../../services/create-requisition';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import {
  addressesMatch,
  adaptKeys,
} from '../../utilities/deliveryAddressUtils';
import ErrorActions from '../../context/ErrorContext/ErrorActions';
import ErrorContext from '../../context/ErrorContext/ErrorContext';
import { RequisitionStep } from '../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';
import { StoreSubjects } from '../../constants/constants';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';
import { useSystemAlert } from '../../services/system-alert';
import { STEPS } from '../../pages/non-standard-purchase/constants';
import { noDuplicateOptions } from '../../constants/utils';

let additionalMasReq = [];
/* eslint no-underscore-dangle: 0 */
let _urgentReqCurrentStep = {};
let _urgentReqJustification = {};
let _urgentReqStepIndicator = {};
let _nonSopData = {};
const VehicleRequisitionContextProvider = ({
  children,
  stateOverride,
  initialState,
}) => {
  const { setSystemAlert } = useSystemAlert();
  const [state, dispatch] = useReducer(
    vehicleRequisitionContextReducer,
    initialState || { ...vehicleRequisitionInitialState, ...stateOverride },
  );

  const navigate = useNavigate();
  const {
    allActiveContracts,
    currentStep,
    draftRequisition,
    selectedContract = {},
    draftSelectedColors,
    vehicleColors,
    additionalRequirements,
    currentStandardItem,
    nonLowBidJustification,
    requiredOptionSessionState,
    selectedContractAgencyInformation,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    dealershipDeliveryContext,
    submitComment,
    paintAndGraphicsOptions,
    totalUploadedFiles,
    taggedOptions,
    engineerTaggedOptions,
    uspsModalOptionsSelectedState,
    isDomesticShipment,
    selectedContractCostBreakdown,
    urgentRequirementJustification,
    nonSopData,
    urgentRequirementCurrentStep,
    urgentReqStepsProcessIndicator,
    urgentReqJustification,
    masRequirementCurrentStep,
    masReqStepsProcessIndicator,
    specialDeliveryInstructions,
    deliveryDate,
    agencyReferenceNumber,
    vehicleQuantity,
    requisitionType,
    selectedOptionsForPrice,
    isAreq,
    reqValidatedAddress,
    mailingValidatedAddress,
    deliveryValidatedAddress,
    selectedEngine,
  } = state;

  additionalMasReq = additionalRequirements;
  _urgentReqCurrentStep = urgentRequirementCurrentStep;
  _urgentReqJustification = urgentReqJustification;
  _urgentReqStepIndicator = urgentReqStepsProcessIndicator;
  _nonSopData = nonSopData;

  const [selectedVendorQuote, setSelectedVendorQuote] = useState(null);

  const requisitionDraftId = useQueryParam()?.get('requisitionId');
  const isReqCanceled = useRef();

  useEffect(() => {
    if (draftRequisition?.requisitionStatus === RequisitionStatus.CANCELLED) {
      isReqCanceled.current = true;
    } else {
      isReqCanceled.current = false;
    }
  }, [draftRequisition?.requisitionStatus]);

  const [getPurchaseCollisionDataOnYes, { data: getPurchaseCollisionInfo }] =
    useLazyQuery(GET_PURCHASE_COLLISION_FOR_ALL_VENDOR_ON_YES, {
      fetchPolicy: 'network-only',
    });
  const { dispatch: errorDispatch } = useContext(ErrorContext);
  const abortController = React.useRef();

  const [validateRequisition] = useLazyQuery(VALIDATE_REQUISITION, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,

    onCompleted: (data) => {
      const requisitionCartValidations = data?.validateRequisition;
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: false,
      });
      if (
        requisitionCartValidations &&
        requisitionCartValidations.requisitionCartValidations
          .validationErrors &&
        requisitionCartValidations.requisitionCartValidations.validationErrors
          .length > 0
      ) {
        dispatch({
          type: VehicleRequisitionContextActions.VALIDATIONS_ERROR_STATE,
          payload: requisitionCartValidations.requisitionCartValidations,
        });
        dispatch({
          type: VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN,
          payload: true,
        });
      }
      return navigate(
        `/vehicle-requisition?requisitionId=${draftRequisition.requisitionId}&allowToCancel=1&allowToEdit=1`,
      );
    },
    onError: (err) => {
      setSystemAlert({
        errorHint: err.message,
      });
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: false,
      });
    },
  });

  const [updateDraftReq] = useMutation(UPDATE_REQUISITION_DRAFT_NEW, {
    onCompleted: ({ updateRequisitionDraft }) => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DRAFT_REQUISITION,
        payload: updateRequisitionDraft,
      });
      if (!!currentStep && currentStep.key === STEPS.REVIEW_SUBMIT) {
        validateRequisition({
          variables: {
            requisitionId: draftRequisition?.requisitionId,
          },
        });
      }
    },
    onError: () => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
        payload: false,
      });
    },
  });

  const [updateRequisitionClientState] = useMutation(
    UPDATE_REQUISITION_CLIENT_STATE,
  );

  const [getattachmentTypes] = useLazyQuery(GET_REQ_ATTACHMENT_TYPES, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch({
        type: VehicleRequisitionContextActions.SET_REQUISITION_ATTACHMENT_TYPES,
        payload: data.getRequisitionAttachmentTypes,
      });
    },
  });

  const debouncedUpdate = useRef(
    _.debounce((input) => {
      const controller = new window.AbortController();
      abortController.current = controller;
      // DEBUGGING - do not remove
      // console.log(JSON.stringify({ debounce: input }));
      if (!isReqCanceled.current) {
        updateDraftReq(input);
      }
    }, 500),
  );
  const updateDraft = (input) => {
    // abortLatest();
    debouncedUpdate.current(input);
  };

  const [
    getCalculatedPriceData,
    { data: calculatedPriceData, loading: calculatePriceLoading },
  ] = useLazyQuery(GET_CALCULATED_PRICE, {
    fetchPolicy: 'network-only',
  });

  const [getModelStandardItemColors, { data: modelStandardItemColors }] =
    useLazyQuery(GET_CONTRACT_LINE_MODEL_COLORS, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (calculatedPriceData) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_CALCULATED_PRICE_INFO,
        calculatedPriceData,
      });
      if (selectedContract) {
        const costBreakdown =
          calculatedPriceData?.calculatePurchaseReqPrice?.modelCostBreakDown?.find(
            (x) => x.contractLineId === selectedContract.contractLineId,
          );
        if (costBreakdown) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
            selectedContract,
            selectedEngine,
            selectedContractCostBreakdown: costBreakdown,
            currentStep,
          });
        }
      }
    }
  }, [calculatedPriceData]);

  useEffect(() => {
    if (getPurchaseCollisionInfo) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_PURCHASE_COLLISIONS_INFO,
        payload: getPurchaseCollisionInfo,
      });
    }
  }, [getPurchaseCollisionInfo]);

  useEffect(() => {
    if (
      modelStandardItemColors &&
      modelStandardItemColors.storeGetContractLineColors
    ) {
      const availableModelStandardItemColors =
        modelStandardItemColors.storeGetContractLineColors;
      const modelStandardItemOptions = availableModelStandardItemColors.map(
        (color) => {
          return {
            label: color.description,
            value: color.colorCode,
          };
        },
      );
      if (modelStandardItemOptions && modelStandardItemOptions.length > 0) {
        errorDispatch({
          type: ErrorActions.CLEAR_ERRORS,
        });
      } else {
        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: RequisitionStep.COLOR_SELECTION,
            form: 'NO_COLORS',
            error: (
              <span>
                Please select another vehicle or contact our Vehicle Buying Team
                at 866-472-1200 or{' '}
                <a href="https://vehicle.buying@gsa.gov">
                  vehicle.buying@gsa.gov
                </a>{' '}
                for assistance.
              </span>
            ),
          },
        });
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_AVAILABLE_COLORS,
        payload: modelStandardItemOptions,
      });

      if (!modelStandardItemColors.storeGetContractLineColors.length) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
          payload: [],
        });
      }

      if (draftSelectedColors && draftSelectedColors.length) {
        const colorsSelectedInDraft = [];
        draftSelectedColors.forEach((dColor) => {
          const foundDraftSelectedColor = modelStandardItemOptions.find(
            (aColor) => aColor.value === dColor.makeColorCode,
          );
          if (foundDraftSelectedColor) {
            colorsSelectedInDraft.push({
              color: {
                label: foundDraftSelectedColor.label,
                value: foundDraftSelectedColor.value,
                price: foundDraftSelectedColor.price,
                vendorPrice: foundDraftSelectedColor.vendorPrice,
              },
              quantity: dColor.quantity || 1,
            });
          }
        });
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
          payload: colorsSelectedInDraft,
        });
      }
    }
  }, [modelStandardItemColors]);

  const getRequisitionAddress = () => {
    const {
      firstName,
      lastName,
      countryCode,
      entityName,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      zipcodePlusFour,
      phoneCountryCode,
      email,
      phoneNumber,
      phoneExtension,
      faxCountryCode,
      faxNumber,
      faxExtension,
      isValidated,
      isMilitary,
      militaryOrDiplomaticAddress,
      postOffice,
    } = requisitionStateContext;

    const address = {
      countryCode,
      entityName,
      militaryRankFullName: entityName,
      militaryPostOffice: postOffice,
      militaryAddress: militaryOrDiplomaticAddress,
      stateCode,
      addressLine1: isMilitary ? militaryOrDiplomaticAddress : addressLine1,
      addressLine2: isMilitary ? postOffice : addressLine2,
      zipcode,
      zipcodePlusFour,
      city,
      isUspsVerified: isValidated,
      isMilitary,
    };
    const contact = {
      firstName,
      lastName,
      email,
      faxCountryCode,
      faxNumber,
      faxExtension,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
    };

    return { address, contact };
  };

  const getMailingAddress = () => {
    const {
      firstName,
      lastName,
      countryCode,
      entityName,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      zipcodePlusFour,
      phoneCountryCode,
      email,
      phoneNumber,
      phoneExtension,
      faxCountryCode,
      faxNumber,
      faxExtension,
      isValidated,
      isMilitary,
      militaryOrDiplomaticAddress,
      postOffice,
    } = mailingStateContext;

    const address = {
      countryCode,
      stateCode,
      entityName,
      militaryRankFullName: entityName,
      militaryPostOffice: postOffice,
      militaryAddress: militaryOrDiplomaticAddress,
      addressLine1: isMilitary ? militaryOrDiplomaticAddress : addressLine1,
      addressLine2: isMilitary ? postOffice : addressLine2,
      zipcode,
      zipcodePlusFour,
      city,
      isUspsVerified: isValidated,
      isMilitary,
    };
    const contact = {
      firstName,
      lastName,
      email,
      faxCountryCode,
      faxNumber,
      faxExtension,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
    };
    return { address, contact };
  };

  const getDeliveryAddress = () => {
    const {
      firstName,
      lastName,
      countryCode,
      entityName,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      zipcodePlusFour,
      phoneCountryCode,
      email,
      phoneNumber,
      phoneExtension,
      faxCountryCode,
      faxNumber,
      faxExtension,
      isValidated,
      isMilitary,
      rankAndFullName,
      militaryOrDiplomaticAddress,
      postOffice,
      dealerId,
    } = deliveryStateContext;

    let dealershipObject = dealershipDeliveryContext;
    if (
      !dealershipObject &&
      draftRequisition?.deliveryAddress?.deliveryDealership
    ) {
      const { id, ...modifiedDealership } =
        draftRequisition?.deliveryAddress?.deliveryDealership;
      dealershipObject = {
        ...modifiedDealership,
        stateId: modifiedDealership?.stateId
          ? parseInt(modifiedDealership?.stateId, 10)
          : null,
        countryId: modifiedDealership?.countryId
          ? parseInt(modifiedDealership?.countryId, 10)
          : null,
      };
    }
    const address = {
      countryCode,
      stateCode,
      entityName: isMilitary ? rankAndFullName : entityName,
      addressLine1: isMilitary ? militaryOrDiplomaticAddress : addressLine1,
      addressLine2: isMilitary ? postOffice : addressLine2,
      zipcode,
      zipcodePlusFour,
      city,
      isUspsVerified: isValidated,
      dealerId: dealerId || draftRequisition?.deliveryAddress?.dealerId,
      deliveryDealership: dealershipObject,
      isMilitary,
    };
    const contact = {
      firstName,
      lastName,
      email,
      faxCountryCode,
      faxNumber,
      faxExtension,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
    };

    return { address, contact };
  };

  const updateDraftRequisition = (
    drftId,
    updateObject = { selectedContract: {} },
    givenCurrentStep,
  ) => {
    const currentContract = updateObject.selectedContract || selectedContract;

    if (draftRequisition) {
      let selectedColorsForDraft = vehicleColors?.map(
        ({ color, quantity: colorQuantity }) => {
          return {
            makeColorCode: color?.value,
            makeColorName: color?.label,
            makeColorPriceToCustomer: color?.price,
            makeColorPriceToGsa: color?.vendorPrice,
            quantity: Number(colorQuantity),
          };
        },
      );

      if (!selectedColorsForDraft?.length && draftSelectedColors?.length) {
        const colorsForDraft = [];
        draftSelectedColors.forEach((color) => {
          const newColor = {
            makeColorCode: color.makeColorCode,
            makeColorName: color.makeColorName,
            makeColorPriceToCustomer: color.makeColorPriceToCustomer,
            makeColorPriceToGsa: color.makeColorPriceToGsa,
            quantity: color.quantity,
          };
          colorsForDraft.push(newColor);
        });
        selectedColorsForDraft = [...colorsForDraft];
      }

      const selectedState = sessionStorage.getItem('selectedState');
      const isDomesticShipmentValue =
        isDomesticShipment ||
        (selectedState && selectedState !== 'Select State');

      const clientData = {
        selectedOptions: {
          selectedOptions: noDuplicateOptions(selectedOptionsForPrice),
          supportingDocuments: totalUploadedFiles,
          customerInputs: {
            paintAndGraphicsOptions,
            taggedOptions,
            engineerTaggedOptions,
          },
        },
        // vehicle: selectedColorsForDraft[0],
        //  requisitionId: drftId || draftRequisition?.requisitionId,
        // subCategoryCode: currentStandardItem?.vehicleTypeCode.code,
        clientState: {
          submitComment,
          currentStep: givenCurrentStep?.sop || currentStep,
          urgentRequirement: {
            urgentReqStepsProcessIndicator: _urgentReqStepIndicator,
            urgentRequirementCurrentStep:
              givenCurrentStep?.urgent || _urgentReqCurrentStep,
            urgentReqJustification: _urgentReqJustification,
          },
          masRequirement: {
            masReqStepsProcessIndicator,
            masRequirementCurrentStep:
              givenCurrentStep?.mas || masRequirementCurrentStep,
          },
          lowestBidContract: {
            contract: allActiveContracts && allActiveContracts[0].contract,
            contractNumber:
              allActiveContracts && allActiveContracts[0].contractNumber,
            scheduleLine:
              allActiveContracts && allActiveContracts[0].scheduleLine,
            contractLineId:
              allActiveContracts && allActiveContracts[0].contractLineId,
          },
          isDomesticShipment: isDomesticShipmentValue,
          selectedState,
          shipmentLocation: sessionStorage.getItem('shipmentLocation'),
          validatedTas:
            selectedContractAgencyInformation?.treasuryAccountSymbol,
        },
      };

      const contractLineIdToUse =
        selectedContract?.contractLineId ||
        (!isAreq && draftRequisition?.contractLineId);
      const receivingAgency = sessionStorage.getItem('receivingAgency');
      const receivingBureau = sessionStorage.getItem('receivingBureau');
      const receivingOffice = sessionStorage.getItem('receivingOffice');

      let finAdditionalFundsPerUnitNum = 0;
      if (
        !Number.isNaN(
          selectedContractAgencyInformation?.finAdditionalFundsPerUnit,
        )
      ) {
        finAdditionalFundsPerUnitNum = parseInt(
          selectedContractAgencyInformation?.finAdditionalFundsPerUnit,
          10,
        );
      }

      let requisitionExtendedProps = {
        customerAssignedNumber:
          selectedContractAgencyInformation.agencyOrderNumber,
        officeCode: sessionStorage.getItem('officeCode'),
        purchasingForAgencyCode:
          receivingAgency?.length > 0
            ? receivingAgency.substring(0, receivingAgency.indexOf('-'))
            : '',
        purchasingForBureauCode:
          receivingBureau?.length > 0
            ? receivingBureau.substring(0, receivingBureau.indexOf('-'))
            : '',
        purchasingForOfficeCode:
          receivingOffice?.length > 0
            ? receivingOffice.substring(0, receivingOffice.indexOf('-'))
            : '',
        contractLineId: parseInt(contractLineIdToUse, 10),
        contractLineVersionNumber: currentContract
          ? currentContract.contractLineVersionNumber
          : null,
        makeCode:
          currentContract?.makeCode || draftRequisition?.makeCode || null,
        modelCode:
          currentContract?.modelCode || draftRequisition?.modelCode || null,
        justification: nonLowBidJustification || '',
        quantity: vehicleQuantity
          ? parseInt(vehicleQuantity, 10)
          : draftRequisition?.quantity,
        julianDate: selectedContractAgencyInformation.requisitionJulian,
        treasuryAccountSymbol:
          selectedContractAgencyInformation.treasuryAccountSymbol,
        // selectedColors: selectedColorsForDraft,
        // subCategoryCode: currentStandardItem?.vehicleTypeCode.code,
        //  agencyInformation: getAgencyInformationForDraft(),
        requisitionerAddress: getRequisitionAddress().address,
        requisitionerContact: getRequisitionAddress().contact,
        mailingAddress: getMailingAddress().address,
        mailingContact: getMailingAddress().contact,
        deliveryAddress: getDeliveryAddress().address,
        deliveryContact: getDeliveryAddress().contact,
        deliveryDealershipCode:
          dealershipDeliveryContext?.dealershipDeliveryCode,
        isOverseasDeliveryOptionSelected: selectedOptionsForPrice.some(
          (opt) => opt.optionCode === '1611',
        ),
        isLowPrice: false,
        purchaseRate: selectedContract?.purchaseRate,
        // modelYear: JSON.stringify(selectedContract?.modelYear),
        additionalShipmentDays:
          selectedContractCostBreakdown?.additionalShippingDays
            ?.additionalShippingDays || 0,
        baseShipmentDays: selectedContractCostBreakdown?.baseShippingDays || 0,
        serialNumber: selectedContractAgencyInformation.requisitionSerialNumber,
        finAdditionalInfo:
          selectedContractAgencyInformation.agencyFinancialData,
        finFundCode: selectedContractAgencyInformation.fundCode,
        finServiceCode: selectedContractAgencyInformation.serviceCode,
        finSignalCode: selectedContractAgencyInformation.signalCode,
        finAdditionalFundsPerUnit: finAdditionalFundsPerUnitNum,
        boac: selectedContractAgencyInformation?.requisitionBOAC,
        agencyReferenceNumber,
        nationalStockNumber:
          selectedContractAgencyInformation.nationalStockNumber,
        markForInformation:
          selectedContractAgencyInformation.markForInformation,
        transportationControlNumber:
          selectedContractAgencyInformation.transportationControlNumber,
        registrationNumber:
          selectedContractAgencyInformation.registrationNumber,
        accountingClassificationReferenceNumber:
          selectedContractAgencyInformation.accountingClassificationReferenceNumber,

        agencyContact: {
          firstName: selectedContractAgencyInformation?.firstName,
          lastName: selectedContractAgencyInformation?.lastName,
          email: selectedContractAgencyInformation?.email,
          phoneCountryCode:
            selectedContractAgencyInformation?.phoneCountryCallingCode,
          phoneNumber: selectedContractAgencyInformation?.phoneNumber,
          phoneExtension: selectedContractAgencyInformation?.phoneExtension,
          faxCountryCode:
            selectedContractAgencyInformation?.faxCountryCallingCode,
          faxNumber: selectedContractAgencyInformation?.faxNumber,
          faxExtension: selectedContractAgencyInformation?.faxExtension,
        },
        finSupplementaryAddress:
          selectedContractAgencyInformation.signalSupplementaryAddress,
        // requisitionSerialNumber: getAgencyInformationForDraft().se
        specialDeliveryInstructions,
        deliveryDate,
        // urgentRequirementJustification: urgReqJustification,
        //  nonSopData: urgReqSpecs,
        requisitionType,
      };

      if (currentStandardItem?.id) {
        requisitionExtendedProps = {
          ...requisitionExtendedProps,
          standardItemId: parseInt(currentStandardItem.id, 10),
        };
      }

      if (
        allActiveContracts &&
        selectedContract?.contractLineId ===
          allActiveContracts[0]?.contractLineId
      ) {
        requisitionExtendedProps = {
          ...requisitionExtendedProps,
          isLowPrice: true,
        };
      }
      const { additionalRequirements: exclude, ...nonSopDataVals } =
        _nonSopData ?? _nonSopData ?? {};
      nonSopDataVals.justification = urgentRequirementJustification;

      const updateInput = {
        extendedProps: requisitionExtendedProps,
        vehicle: {
          ...(selectedColorsForDraft && selectedColorsForDraft[0]),
          ...(selectedEngine?.fuelType && {
            fuelCode: selectedEngine?.fuelType,
          }),
          ...(selectedEngine?.range && {
            totalRangeMiles: selectedEngine?.range,
          }),
          ...(selectedEngine?.convMpgCity && {
            convMpgCity: selectedEngine?.convMpgCity,
          }),
          ...(selectedEngine?.convMpgHighway && {
            convMpgHighway: selectedEngine?.convMpgHighway,
          }),
          ...(selectedEngine?.convMpgCombined && {
            convMpgCombined: selectedEngine?.convMpgCombined,
          }),
          ...(selectedEngine?.convGpm && {
            convGpm: selectedEngine?.convGpm,
          }),
          ...(selectedEngine?.fuelType && {
            altFuelCode: selectedEngine?.fuelType,
          }),
          ...(selectedEngine?.altMpgCity && {
            altMpgCity: selectedEngine?.altMpgCity,
          }),
          ...(selectedEngine?.altMpgHighway && {
            altMpgHighway: selectedEngine?.altMpgHighway,
          }),
          ...(selectedEngine?.altMpgCombined && {
            altMpgCombined: selectedEngine?.altMpgCombined,
          }),
          ...(selectedEngine?.altGpm && {
            altGpm: selectedEngine?.altGpm,
          }),
          ...(selectedEngine?.rangeElectric && {
            elecRangeMiles: selectedEngine?.rangeElectric,
          }),
          ...(selectedEngine?.elecMpgCity && {
            elecMpgCity: selectedEngine?.elecMpgCity,
          }),
          ...(selectedEngine?.elecMpgHighway && {
            elecMpgHighway: selectedEngine?.elecMpgHighway,
          }),
          ...(selectedEngine?.elecMpgCombined && {
            elecMpgCombined: selectedEngine?.elecMpgCombined,
          }),
        },
        additionalRequirements: additionalMasReq,
        clientData,
        nonSopData: nonSopDataVals,
      };

      updateDraft({
        variables: {
          updateInput,
          requisitionId: drftId || draftRequisition?.requisitionId,
        },
      });
    }
  };

  const REQUISITION_ADDRESS = 'REQUISITION_ADDRESS';
  const MAILING_ADDRESS = 'MAILING_ADDRESS';
  const DELIVERY_ADDRESS = 'DELIVERY_ADDRESS';

  const [
    validateRequisitionAddress,
    {
      loading: loadingValidatedRequisitionAddress,
      data: uspsRequisitionAddress,
    },
  ] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_VALIDATED_ADDRESS_NOT_FOUND,
        payload: StoreSubjects.Requisition,
      });
    },
    onCompleted: (data) => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_REQUISITION_VALIDATED_ADDRESS,
        payload: { ...(data?.validateAddress || {}), isChecked: true },
      });
    },
  });

  const [
    validateMailingAddress,
    { loading: loadingValidatedMailingAddress, data: uspsMailingAddress },
  ] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_VALIDATED_ADDRESS_NOT_FOUND,
        payload: 'Mailing',
      });
    },
    onCompleted: (data) => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MAILING_VALIDATED_ADDRESS,
        payload: { ...(data?.validateAddress || {}), isChecked: true },
      });
    },
  });

  const [
    validateDeliveryAddress,
    { loading: loadingValidatedDeliveryAddress, data: uspsDeliveryAddress },
  ] = useLazyQuery(VALIDATE_ADDRESS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_VALIDATED_ADDRESS_NOT_FOUND,
        payload: 'Delivery',
      });
    },
    onCompleted: (data) => {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DELIVERY_VALIDATED_ADDRESS,
        payload: { ...(data?.validateAddress || {}), isChecked: true },
      });
    },
  });

  const isReqValidationPending = () => {
    const checkCondition = !addressesMatch(
      adaptKeys(uspsRequisitionAddress?.validateAddress),
      requisitionStateContext,
    );

    if (checkCondition) {
      if (!uspsModalOptionsSelectedState.length) {
        if (requisitionStateContext.isMilitary) {
          dispatch({
            type: VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE,
            payload: {
              id: 'Requisition Address',
              isChecked: true,
              selected: true,
            },
          });
        } else {
          dispatch({
            type: VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE,
            payload: {
              id: 'Requisition Address',
              isChecked: false,
              selected: false,
            },
          });
        }
      }
    }

    if (requisitionStateContext.isMilitary) {
      return false;
    }

    return checkCondition;
  };

  const isMailingValidationPending = () => {
    const checkCondition = !addressesMatch(
      adaptKeys(uspsMailingAddress?.validateAddress),
      mailingStateContext,
    );
    if (checkCondition && !mailingStateContext.isMailingSameAsRequisition) {
      if (!uspsModalOptionsSelectedState.length) {
        if (mailingStateContext.isMilitary) {
          dispatch({
            type: VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE,
            payload: {
              id: 'Mailing Address',
              isChecked: true,
              selected: true,
            },
          });
        } else {
          dispatch({
            type: VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE,
            payload: {
              id: 'Mailing Address',
              isChecked: false,
              selected: false,
            },
          });
        }
      }
    }

    if (mailingStateContext.isMilitary) {
      return false;
    }
    return checkCondition;
  };

  const isDeliveryValidationPending = () => {
    if (
      !addressesMatch(
        adaptKeys(uspsDeliveryAddress?.validateAddress),
        deliveryStateContext,
      ) &&
      !deliveryStateContext.isDeliverySameAsRequisition
    ) {
      if (!uspsModalOptionsSelectedState.length) {
        dispatch({
          type: VehicleRequisitionContextActions.INIT_USPS_MODAL_OPTIONS_SELECTED_STATE,
          payload: {
            id: 'Delivery address',
            isChecked: false,
            selected: false,
          },
        });
      }
    }
    return !addressesMatch(
      adaptKeys(uspsDeliveryAddress?.validateAddress),
      deliveryStateContext,
    );
  };

  const [addressValidationCompleted, setAddressValidationCompleted] =
    useState();

  const hasSuggestedAddress = () => {
    const pendingReq = isReqValidationPending();
    const pedingMailing = isMailingValidationPending();
    const pendingDelivery = isDeliveryValidationPending();
    return pendingReq || pendingDelivery || pedingMailing;
  };
  const [showUspsModal, setShowUspsModal] = useState();

  const getAddressValidation = (section, address) => {
    switch (section) {
      case REQUISITION_ADDRESS:
        validateRequisitionAddress({ variables: address });
        break;
      case MAILING_ADDRESS:
        validateMailingAddress({ variables: address });
        break;
      case DELIVERY_ADDRESS:
        validateDeliveryAddress({ variables: address });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const isReqAddressValid =
      !requisitionStateContext.isMilitary &&
      requisitionStateContext?.countryCode === 'US';
    const isMailingAddressValid =
      !mailingStateContext.isMilitary &&
      mailingStateContext?.countryCode === 'US';
    if (
      (requisitionStateContext.isMilitary || mailingStateContext.isMilitary) &&
      !_.isEmpty(uspsDeliveryAddress)
    ) {
      // eslint-disable-next-line no-unused-expressions
      hasSuggestedAddress()
        ? setShowUspsModal(true)
        : setAddressValidationCompleted(true);
    } else if (
      !_.isEmpty(uspsRequisitionAddress) &&
      !_.isEmpty(uspsMailingAddress) &&
      !_.isEmpty(uspsDeliveryAddress)
    ) {
      // eslint-disable-next-line no-unused-expressions
      hasSuggestedAddress()
        ? setShowUspsModal(true)
        : setAddressValidationCompleted(true);
    } else if (isReqAddressValid || isMailingAddressValid) {
      // Condition to check requisition and mailing address are only US
      // eslint-disable-next-line no-unused-expressions
      hasSuggestedAddress()
        ? setShowUspsModal(true)
        : setAddressValidationCompleted(true);
    }
  }, [
    reqValidatedAddress,
    mailingValidatedAddress,
    deliveryValidatedAddress,
    uspsRequisitionAddress,
    uspsMailingAddress,
    uspsDeliveryAddress,
  ]);

  const setNonLowBidJustification = (value) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_NON_LOW_BID_JUSTIFICATION,
      payload: value,
    });
    if (requisitionDraftId) {
      updateDraftRequisition(requisitionDraftId);
    }
  };

  const handleRequisitionHasError = (value) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_REQUISITION_HAS_ERROR,
      payload: value,
    });
  };

  const [
    validateBoacInWallet,
    { loading: loadingValidatedBoac, data: validatedBoac },
  ] = useLazyQuery(VALIDATE_BOAC, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.log({ BOAC_IN_WALLET_ERROR: error });
      // dispatch({
      //   type: VehicleRequisitionContextActions.UPDATE_VALIDATED_ADDRESS_NOT_FOUND,
      //   payload: StoreSubjects.Requisition,
      // });
    },
    onCompleted: (data) => {
      // console.log({ BOAC_IN_WALLET_DATA: data });
      // dispatch({
      //   type: VehicleRequisitionContextActions.UPDATE_REQUISITION_VALIDATED_ADDRESS,
      //   payload: { ...(data?.validateAddress || {}), isChecked: true },
      // });
    },
  });

  const [
    validateBoacInWalletForAddressCode,
    {
      loading: loadingValidatedBoacAddressCode,
      data: validatedBoacAddressCode,
    },
  ] = useLazyQuery(VALIDATE_BOAC, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      console.log({ BOAC_IN_WALLET_ERROR: error });
      // dispatch({
      //   type: VehicleRequisitionContextActions.UPDATE_VALIDATED_ADDRESS_NOT_FOUND,
      //   payload: StoreSubjects.Requisition,
      // });
    },
    onCompleted: (data) => {
      console.log({ BOAC_IN_WALLET_DATA: data });
      // dispatch({
      //   type: VehicleRequisitionContextActions.UPDATE_REQUISITION_VALIDATED_ADDRESS,
      //   payload: { ...(data?.validateAddress || {}), isChecked: true },
      // });
    },
  });

  const [
    validateRequisitionNumber,
    { loading: loadingValidatedRequisitionNumber },
  ] = useLazyQuery(VALIDATE_REQUISITION_NUMBER, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  const [
    getBoacForFundAgencyCode,
    { loading: loadingBoacForSignalCodeCOrL, data: boacForSignalCodeCOrL },
  ] = useLazyQuery(GET_BOAC_SIGNAL_CODE_FMC, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      dispatch({
        type: VehicleRequisitionContextActions.GET_BOAC_FOR_CORL_ERRORS,
        payload: StoreSubjects.Requisition,
      });
    },
    onCompleted: (data) => {
      dispatch({
        type: VehicleRequisitionContextActions.GET_BOAC_FOR_CORL,
        payload: { ...data },
      });
    },
  });

  const [
    getContractLineData,
    {
      loading: getContractLineLoading,
      data: contractLineData,
      error: contractLineError,
    },
  ] = useLazyQuery(GET_CONTRACT_BY_CONTRACT_NUMBER, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getContractDetailsByContractNumber }) => {
      const checkForInvalidData =
        !getContractDetailsByContractNumber?.quantity ||
        !getContractDetailsByContractNumber?.sellingTotalPrice ||
        !getContractDetailsByContractNumber?.sellingUnitPrice ||
        !getContractDetailsByContractNumber?.units;
      if (checkForInvalidData) {
        errorDispatch({
          type: ErrorActions.ADD_ERROR,
          payload: {
            page: 'ContractingFinalReviewInfo',
            form: 'contractData',
            error: 'Invalid data',
          },
        });
      } else {
        errorDispatch({ type: ErrorActions.CLEAR_ERRORS });
      }
    },
  });

  const setIsAreq = (payload) => {
    dispatch({
      type: VehicleRequisitionContextActions.SET_IS_AREQ,
      payload,
    });
  };

  return (
    <VehicleRequisitionContext.Provider
      value={{
        state,
        dispatch,
        updateDraftRequisition,
        getPurchaseCollisionDataOnYes,
        getCalculatedPriceData,
        getModelStandardItemColors,
        getAddressValidation,
        loadingValidatedRequisitionAddress,
        loadingValidatedMailingAddress,
        loadingValidatedDeliveryAddress,
        showUspsModal,
        setShowUspsModal,
        addressValidationCompleted,
        setAddressValidationCompleted,
        setNonLowBidJustification,
        handleRequisitionHasError,
        requisitionDraftId,
        validateBoacInWallet,
        validatedBoac,
        loadingValidatedBoac,
        validateBoacInWalletForAddressCode,
        loadingValidatedBoacAddressCode,
        validateRequisitionNumber,
        loadingValidatedRequisitionNumber,
        validatedBoacAddressCode,
        getBoacForSignalCodeCOrL: getBoacForFundAgencyCode,
        loadingBoacForSignalCodeCOrL,
        boacForSignalCodeCOrL,
        setIsAreq,
        selectedVendorQuote,
        setSelectedVendorQuote,
        getattachmentTypes,
        getContractLineData,
        getContractLineLoading,
        contractLineData,
        contractLineError,
        updateRequisitionClientState,
        validateRequisition,
      }}
    >
      {calculatePriceLoading && (
        <div className="afp-modal-overlay calculate-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      )}

      {children}
    </VehicleRequisitionContext.Provider>
  );
};

VehicleRequisitionContextProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element]).isRequired,
  stateOverride: PropTypes.objectOf(Object),
  initialState: PropTypes.objectOf(Object),
};

VehicleRequisitionContextProvider.defaultProps = {
  stateOverride: null,
  initialState: null,
};

export default VehicleRequisitionContextProvider;
