import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  TabSet,
  PageTitle,
  Spinner,
} from '@gsa/afp-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import {
  GET_DELIVERY_DEALERSHIPS_BY_DEALERSHIP_CODE,
  GET_ORDER_MOD_HISTORY,
} from '../../services/data-layer';
import OrderStatusTag from '../my-orders/OrderStatusTag';
import './OrderDetailsContainer.scss';
import OrderVehicles from './components/OrderVehicles';
import OrderDetails from './components/OrderDetails';
import OptionsAndPricing from './components/OptionsAndPricing';
import OrderActivity from './components/OrderActivity';
import OrderAttachments from './components/OrderAttachments';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import { UserRoles, UserTypes } from '../../constants/index.jsx';
import { getSelectedOrderDetails } from '../../requests/order';

const OrderDetailsContainer = () => {
  const { orderId } = useParams();
  const { currentUser } = useCurrentUser();
  const dispatch = useDispatch();
  const orderId1 = orderId;

  useEffect(() => {
    if (orderId?.length > 0) {
      dispatch(getSelectedOrderDetails(orderId));
    }
  }, []);

  const isVehicleSupplier = useMemo(
    () =>
      currentUser?.roles?.some(
        (role) => role.name === UserRoles.VEHICLE_SUPPLIER,
      ),
    [currentUser],
  );
  const canViewLeasing = useMemo(
    () =>
      currentUser?.userType?.id === UserTypes.GSA_EMPLOYEE ||
      currentUser?.roles?.some((role) => role?.id === '100') ||
      // this is needed for finance officers who have the fleet base role and are not gsa employee
      currentUser?.roles?.some((role) => role?.id === '104'),
    [currentUser],
  );

  const {
    orderDetails,
    orderInProgress,
    orderDetailsError,
    orderContractLineIdData,
    orderTab,
  } = useSelector((state) => state.orderReducer);

  const [deliveryDealershipAddress, setDeliveryDealershipAddress] =
    useState(null);
  const [orderModHistory, setOrderModHistory] = useState({});

  const [getDealershipByDealershipCode] = useLazyQuery(
    GET_DELIVERY_DEALERSHIPS_BY_DEALERSHIP_CODE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getOrderModDetails] = useLazyQuery(GET_ORDER_MOD_HISTORY, {
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (orderDetails?.caseNumber && orderDetails?.version) {
      getOrderModDetails({
        variables: {
          caseNumber: orderDetails?.caseNumber,
          version: orderDetails?.version,
        },
        onCompleted: (orderModHistoryData) => {
          if (orderModHistoryData?.getOrderModsByCaseNumber) {
            setOrderModHistory(orderModHistoryData.getOrderModsByCaseNumber);
          }
        },
      });
    }

    if (orderDetails?.deliveryDealershipCode?.trim()) {
      getDealershipByDealershipCode({
        variables: {
          dealershipCode: orderDetails?.deliveryDealershipCode,
        },
        onCompleted: (deliveryDealershipData) => {
          if (deliveryDealershipData?.getDeliveryDealershipByDealershipCode) {
            setDeliveryDealershipAddress(
              deliveryDealershipData?.getDeliveryDealershipByDealershipCode,
            );
          }
        },
      });
    }
  }, [orderDetails]);

  if (orderInProgress) {
    return <Spinner className="margin-y-9" />;
  }
  if (orderDetailsError) {
    return <div>Error!</div>;
  }

  return (
    <div className="order-details-container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs
          trail={[
            <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
              Home
            </a>,
            <Link to="/orders">Requisitions and Orders</Link>,
          ]}
          current={orderDetails.caseNumber}
        />
        <ContactBuyingPopover />
      </div>
      <div className="order-details-container__pagetitle-container">
        <PageTitle
          data-testid="order-details-header"
          title={orderDetails.rpnNumber}
        />
        <span
          data-testid="order-info-status"
          className="order-details-container__status-tag text-style margin-left-2"
        >
          <OrderStatusTag value={orderDetails.orderStatus} />
        </span>
      </div>
      <h2 className="font-sans-lg text-normal margin-top-0">
        {(orderDetails.requisitionNumber)?.toUpperCase()}
      </h2>
      {!!orderTab && (
        <TabSet
          tabs={
            !isVehicleSupplier
              ? [
                  {
                    heading: 'Order details',
                    tabSelectedWhenOpen: orderTab === 'orderDetails',
                    content: (
                      <OrderDetails
                        orderData={orderDetails}
                        orderContractLineIdData={orderContractLineIdData}
                        currentUser={currentUser}
                        deliveryDealershipAddress={deliveryDealershipAddress}
                        canViewLeasing={canViewLeasing}
                        orderModHistory={orderModHistory}
                      />
                    ),
                  },
                  {
                    heading: 'Options and pricing',
                    tabSelectedWhenOpen: orderTab === 'optionsAndPricing',
                    content: (
                      <OptionsAndPricing
                        orderData={orderDetails}
                        //setTab={setTab}
                        canViewLeasing={canViewLeasing}
                      />
                    ),
                  },
                  {
                    heading: 'Vehicles',
                    tabSelectedWhenOpen: orderTab === 'orderVehicles',
                    content: (
                      <OrderVehicles
                        orderData={orderDetails}
                        orderId={orderId}
                      />
                    ),
                  },
                  {
                    heading: 'Activity',
                    tabSelectedWhenOpen: orderTab === 'orderActivity',
                    content: <OrderActivity />,
                  },
                  {
                    heading: 'Attachments',
                    tabSelectedWhenOpen: orderTab === 'orderAttachments',
                    content: <OrderAttachments />,
                  },
                ]
              : [
                  {
                    heading: 'Order details',
                    tabSelectedWhenOpen: orderTab === 'orderDetails',
                    content: (
                      <OrderDetails
                        orderData={orderDetails}
                        orderContractLineIdData={orderContractLineIdData}
                        deliveryDealershipAddress={deliveryDealershipAddress}
                        canViewLeasing={canViewLeasing}
                        orderModHistory={orderModHistory}
                      />
                    ),
                  },
                  {
                    heading: 'Options and pricing',
                    tabSelectedWhenOpen: orderTab === 'optionsAndPricing',
                    content: (
                      <OptionsAndPricing
                        orderData={orderDetails}
                        canViewLeasing={canViewLeasing}
                      />
                    ),
                  },
                  {
                    heading: 'Vehicles',
                    tabSelectedWhenOpen: orderTab === 'orderVehicles',
                    content: (
                      <OrderVehicles
                        orderData={orderDetails}
                        orderId={orderId}
                      />
                    ),
                  },
                ]
          }
        />
      )}
    </div>
  );
};

export default OrderDetailsContainer;
