import React from 'react';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';
import './funding-modal.scss';

export function FundingModal({
  handleClose,
  isInsufficientAFV,
  remainingAFVFunding,
}) {
  return (
      <Modal
        className=""
        onClose={handleClose}
        actions={
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button
              type="button"
              variant="unstyled"
              onClick={handleClose}
              label="Cancel"
            />
          </div>
        }
        variant='large'
        title={
          <h2 style={{ 
              fontSize: '24px',
              lineHeight: 3,
              fontWeight: 700,
          }}>
            {isInsufficientAFV ? 'Insufficient funding' : 'Funding not available'}
          </h2>
        }
      >
        {isInsufficientAFV ? (
          <>
            The required AFV funding amount is not available. The selected
            customer has ${remainingAFVFunding} of AFV funding remaining.
            Please modify your vehicle selection, or contact your Zonal
            Acquisition Coordinator for additional information.
          </>
        ) : (
          <>
            The required funding amount is not available. Please coordinate
            with your Zonal Acquisition Coordinator and the Ordering
            Management Branch to obtain additional information.
          </>
        )}
      </Modal>
  );
}

export default connectModal(FundingModal);
