/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  connectModal,
  Menu,
  StatusBadge,
  useModal,
} from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import { OrderModStatus } from './Filters/OrderModFilterHelpers';
import CancelOrderModModal from '../requisitions-and-orders/CancelOrderModModal';
import useOrderModsState from './useOrderModsState';
import { formattedESTDate } from '../../utilities/commonUtils';

export const OrderModStatusType = {
  DRAFT_MOD: {
    color: 'Warning-Gray',
    label: 'Draft mod',
  },
  ATTACH_TO_MOD: {
    color: 'Ready-Gray',
    label: 'Attach to mod',
  },
  CANCELLED_MOD: {
    color: 'Inactive-Gray',
    label: 'Cancelled mod',
  },
};

const rowActions = [
  {
    icon: 'cancel',
    label: 'Cancel modification',
    action: 'cancel',
  },
];
export const ActionColumn = (props) => {
  const { getOrderMods, searchFilters, paginationState } = useOrderModsState();
  const { row } = props;
  const cancelModal = useModal();
  const DisplayCancelModal = connectModal(CancelOrderModModal);
  const cancelOrderMod = () => {
    cancelModal.openModal();
  };
  const closeCancelModal = () => {
    cancelModal.closeModal();

    getOrderMods({
      variables: {
        query: {
          ...searchFilters,
          offset: paginationState.offset,
          limit: paginationState.limit,
        },
      },
    });
  };
  return (
    <>
      <Menu
        actionIconSize="usa-icon--size-3"
        menuDialogState="hover"
        menuItems={rowActions.filter(
          (action) => row.values.actions.indexOf(action.label) > -1,
        )}
        onActionClick={() => {
          cancelOrderMod();
          console.log('cancelMod', row);
        }}
        {...props}
      />
      <DisplayCancelModal
        isOpen={cancelModal.isOpen}
        orderModId={row?.values?.id}
        orderModName={row?.values?.orderModName}
        onClose={() => closeCancelModal()}
      />
    </>
  );
};

export const ActionButton = ({ order }) => {
  const navigate = useNavigate();

  const isDraft = order?.orderModStatus === OrderModStatus.DRAFT_MOD;

  return (
    <Button
      variant="unstyled"
      onClick={() => {
        if (isDraft) {
          navigate(`/create-order-modifications?modificationId=${order.id}`);
        } else {
          navigate(`/order-modification-details?modificationId=${order.id}`);
        }
      }}
      label={order?.orderModName}
    />
  );
};
export const ORDER_MOD_TABLE_COLUMNS = [
  {
    Header: 'id',
    accessor: 'id',
  },
  {
    Header: 'Modification',
    accessor: 'orderModName',
    Cell: ({ row: { original } }) => {
      return <ActionButton order={original} />;
    },
  },
  {
    Header: 'Number of orders',
    accessor: 'changeSetOrderCount',
  },
  {
    Header: 'Date placed',
    accessor: 'createdAt',
  },
  {
    Header: 'Status',
    accessor: 'orderModStatus',
    Cell: ({ value }) => {
      const { color, label } = OrderModStatusType[value];
      return <StatusBadge variant={color}>{label}</StatusBadge>;
    },
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    sortable: false,
    Cell: ActionColumn,
  },
];

export const getTableColumns = (row) => {
  return {
    id: row.orderModificationId,
    changeSetOrderCount: row.changeSetOrderCount,
    orderModName: row.orderModName,
    createdAt: formattedESTDate(row.createdAt),
    orderModStatus: row.orderModStatus,
    actions: row.allowedActions,
  };
};

ActionColumn.propTypes = {
  value: PropTypes.instanceOf(Array).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
};
