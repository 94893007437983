import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Spinner } from '@gsa/afp-component-library';
import {
  useCurrentUser,
  useAppAbility,
  useTitle,
} from '@gsa/afp-shared-ui-utils';
import PropTypes from 'prop-types';
import SystemAlert from '../SystemAlert/SystemAlert';

export const UserStatus = {
  PendingProfile: 1,
  PendingRole: 2,
  Active: 3,
  Deactivated: 4,
  Denied: 5,
  Deleted: 6,
  PendingReactivation: 7,
  Inactive: 8,
};

export const getHomeAppURL = () =>
  window.AFP_CONFIG && window.AFP_CONFIG.appURLs
    ? window.AFP_CONFIG.appURLs.home
    : undefined;

export const PrivateRoute = ({
  loggedOutUserPath = '/',
  operation,
  subject,
  title,
  //  featureName,
  //  offline = false,
}) => {
  const { isLoaded, isLoggedIn, isLoading, authError, currentUser } =
    useCurrentUser();

  const location = useLocation();
  const ability = useAppAbility();

  useTitle(title);

  const isPage = (pageType) =>
    location.pathname.toLowerCase().indexOf(pageType.toLowerCase()) > 0;

  const spinnerResponse = <Spinner className="padding-y-9" />;

  const signInURL = getHomeAppURL();

  if (!getHomeAppURL) {
    throw new Error('Home App URL needs to be provided');
  }

  // TODO : Need to decide if there is app level logout or go to home-app logout
  const navigatetoLogout = () => window.location.replace(loggedOutUserPath);

  /**
   * Private checks for the following in this order
   *  1) Authentication
   *  2) User Status checks
   *  3) Feature Toggle
   *  4) Authorization
   *
   * */

  // Authentication checks
  // User is logged out. No active cognito session
  if (authError?.code === 'NOSESSION') {
    // Redirect to public home page
    if (signInURL) {
      window.location.replace(signInURL);
      return spinnerResponse;
    }
    navigatetoLogout();
    return spinnerResponse;
  }

  // Redirects to unauthorized page if there is a GraphQL 'UNAUTHENTICATED' error.
  if (authError?.code === 'UNAUTHENTICATED') {
    // Redirect to unauthorized page
    return <Navigate to={{ pathname: '/unauthorized' }} />;
  }

  if (!isLoaded) {
    return spinnerResponse;
  }

  // TODO: not sure why we need to condition
  if (isLoaded && !isLoggedIn) {
    navigatetoLogout();
    return spinnerResponse;
  }

  if (isLoading || !ability) return spinnerResponse;

  if (!currentUser || !currentUser.status || !currentUser.status.id) {
    return <Navigate to={{ pathname: '/unauthorized' }} />;
  }

  // Status Checks
  const userStatus = parseInt(currentUser?.status?.id, 10);
  switch (userStatus) {
    case UserStatus.Active:
      break;
    case UserStatus.PendingReactivation:
      if (!isPage('request-submitted'))
        return <Navigate to={{ pathname: '/request-submitted' }} />;
      break;
    case UserStatus.Inactive:
      if (!isPage('inactive'))
        return <Navigate to={{ pathname: '/inactive' }} />;
      break;
    case UserStatus.PendingProfile:
    case UserStatus.PendingRole:
      if (!isPage('profile')) return <Navigate to={{ pathname: '/profile' }} />;
      break;
    case UserStatus.Deleted:
    case UserStatus.Denied:
    case UserStatus.Deactivated:
    default:
      return <Navigate to={{ pathname: '/unauthorized' }} />;
  }

  // Authorization
  if (operation && subject) {
    if (!ability.can(operation, subject)) {
      return <Navigate to={{ pathname: '/unauthorized' }} />;
    }
  }

  return (
    <>
      <SystemAlert />
      <Outlet />
    </>
  );
};

export default PrivateRoute;
