import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { Spinner, Link, SideNav } from '@gsa/afp-component-library';
import {
  GET_OPTIONAL_REQ,
  GET_REQUISITION_ADDITIONAL_REQUIREMENTS,
  GET_REQUISITION_ATTACHMENTS,
} from '../../../services/data-layer';
import {formatCurrency, OPTION_QUANTITY_REQUIRED_CODE, TRANSACTION_TYPES} from '../../../utilities/CompareVehicleUtils';
import './OptionsAndPricing.scss';
import {
  ENGINEER_TAG,
  IN_REQ_TAG,
  PAINT_OPT,
} from '../../../constants/constants';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import { REQUISITION_TYPE } from '../../non-standard-purchase/constants';
import { colorDisplay, getBasePrice } from './optionsAndPricingUtil';
import { setOrdersTab } from '../../../reducers/order.jsx';
import { useDispatch } from 'react-redux';

export default function OptionsAndPricing({ orderData, canViewLeasing }) {
  const dispatch = useDispatch();
  const requisitionId = orderData?.requisitionId;
  const firstVehicle = orderData?.orderVehicles.filter(
    (vehicle) => vehicle?.accountingLineNumber === 1,
  )[0];

  const { data: options, loading: loadingOptions } = useQuery(
    GET_OPTIONAL_REQ,
    {
      variables: {
        standardItemId: orderData.standardItemId,
      },
    },
  );

  const { data: attachments, loading: loadingAttachments } = useQuery(
    GET_REQUISITION_ATTACHMENTS,
    {
      fetchPolicy: 'c',
      variables: { requisitionId },
      skip: !requisitionId,
    },
  );
  const { data: areqDetails, loading: loadingAreqs } = useQuery(
    GET_REQUISITION_ADDITIONAL_REQUIREMENTS,
    {
      fetchPolicy: 'c',
      variables: { requisitionId },
      skip: !requisitionId,
    },
  );
  useEffect(() => {
    dispatch(setOrdersTab('optionsAndPricing'));
  }, []);
  const mappedOptions = useMemo(() => {
    if (
      !options?.getOptionalOptions ||
      options.getOptionalOptions.length === 0
    ) {
      return [];
    }

    // where key = category code title and
    // and value = array of options that belong to that category
    const temp = {};
    firstVehicle?.orderVehicleEquipments.forEach((equipment) => {
      const option = options.getOptionalOptions.find(
        (o) => o.equipmentCode?.code === equipment.equipmentCode,
      );

      const ignoreTags = [IN_REQ_TAG, PAINT_OPT, ENGINEER_TAG];
      if (
        !option ||
        !option?.equipmentCode?.tags?.value ||
        ignoreTags.some((tag) =>
          option.equipmentCode.tags.value.includes(tag),
        ) ||
        equipment.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER
      ) {
        return;
      }

      if (!temp[option.equipmentCode.categoryCode.title]) {
        temp[option.equipmentCode.categoryCode.title] = [];
      }

      temp[option.equipmentCode.categoryCode.title].push({
        ...option,
        equipment,
        unitPriceToCustomer: equipment.unitPriceToCustomer,
        unitPriceToGsa: equipment.unitPriceToGsa,
      });
    });

    return temp;
  }, [options]);

  // looking up the paint and graphics options
  // based on the attachments/customer inputs
  const mappedPaintAndGraphicsOptions = useMemo(() => {
    if (!options?.getOptionalOptions) {
      return [];
    }

    const temp = [];

    orderData?.requisition?.clientData?.selectedOptions?.customerInputs?.paintAndGraphicsOptions?.forEach(
      (option) => {
        temp.push({
          ...option,
          equipment:
            firstVehicle?.orderVehicleEquipments.find(
              (equipment) => equipment.equipmentCode === option.optionCode,
            ) ||
            options.getOptionalOptions.find(
              (opt) => opt.equipmentCode?.code === option.optionCode,
            ),
          attachments:
            attachments?.getAllRequisitionAttachments?.filter(
              (attachment) => attachment.equipmentCode === option.optionCode,
            ) || [],
        });
      },
    );

    if (temp.length === 0) {
      // 1. Find options that are PAINT-OPT
      const possiblePaintOpts = new Set(
        options.getOptionalOptions
          .filter((option) =>
            option.equipmentCode?.tags.value.includes('PAINT_OPT'),
          )
          .map((option) => option.equipmentCode?.code),
      );

      // 2. Check actual options for paint options
      firstVehicle?.orderVehicleEquipments
        ?.filter((equipment) => possiblePaintOpts.has(equipment.equipmentCode))
        .forEach((equipment) => {
          const option = options.getOptionalOptions.find(
            (o) => o.equipmentCode?.code === equipment.equipmentCode,
          );

          temp.push({
            ...option,
            optionCode: option.equipmentCode.code,
            optionDescription: option.equipmentCode.title,
            equipment,
            attachments:
              attachments?.getAllRequisitionAttachments?.filter(
                (attachment) => attachment.equipmentCode === option.optionCode,
              ) || [],
          });
        });
    }

    return temp;
  }, [options, attachments]);

  const mapAreqs = useMemo(() => {
    if (!areqDetails || !attachments) {
      return [];
    }
    const temp = [];
    if (
      areqDetails?.getRequisitionAdditionalRequirements?.length > 0 &&
      attachments?.getAllRequisitionAttachments?.length > 0
    ) {
      areqDetails?.getRequisitionAdditionalRequirements.forEach((areq) => {
        temp.push({
          ...areq,
          attachments: attachments.getAllRequisitionAttachments.filter(
            (attachment) =>
              attachment.additionalRequirementId ===
              areq?.requisitionAdditionalRequirementId,
          ),
        });
      });
    }
    return temp;
  }, [areqDetails, attachments]);
  const showPaintAndGraphics =
    mappedPaintAndGraphicsOptions?.length > 0 || firstVehicle?.makeColorName;

  // options that have IN_REQ and REVIEWREQ tags will be tagged and review options respectively
  const [mappedTaggedOptions, mappedReviewOptions] = useMemo(() => {
    if (
      !options?.getOptionalOptions ||
      options.getOptionalOptions.length === 0
    ) {
      return [[], []];
    }

    const tempTagged = [];
    const tempReview = [];
    firstVehicle?.orderVehicleEquipments.forEach((equipment) => {
      const option = options.getOptionalOptions.find(
        (o) => o.equipmentCode?.code === equipment.equipmentCode,
      );

      if (
        !attachments?.getAllRequisitionAttachments ||
        !option ||
        !option?.equipmentCode?.tags?.value
      ) {
        return;
      }

      const tempOption = {
        ...option,
        equipment,
        unitPriceToCustomer: equipment.unitPriceToCustomer || null,
        unitPriceToGsa: equipment.unitPriceToGsa || null,
        attachments: attachments.getAllRequisitionAttachments.filter(
          (attachment) =>
            attachment.equipmentCode === option?.equipmentCode?.code,
        ),
      };

      // Do not include PAINT options twice
      if (option.equipmentCode.tags.value.includes(PAINT_OPT)) {
        return;
      }

      if (option.equipmentCode.tags.value.includes(IN_REQ_TAG)) {
        tempTagged.push(tempOption);
      } else if (option.equipmentCode.tags.value.includes(ENGINEER_TAG)) {
        tempReview.push(tempOption);
      }
    });

    return [tempTagged, tempReview];
  }, [options, attachments]);

  const additionalMaterials = useMemo(() => {
    if (
      !options?.getOptionalOptions ||
      options.getOptionalOptions.length === 0
    ) {
      return [];
    }

    const temp = [];
    firstVehicle?.orderVehicleEquipments.forEach((equipment) => {
      const option = options.getOptionalOptions.find(
        (o) => o.equipmentCode?.code === equipment.equipmentCode,
      );

      const ignoreTags = [IN_REQ_TAG, PAINT_OPT, ENGINEER_TAG];
      if (
        !option ||
        !option?.equipmentCode?.tags?.value ||
        ignoreTags.some((tag) =>
          option.equipmentCode?.tags.value.includes(tag),
        ) ||
        equipment.quantityType !== OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER
      ) {
        return;
      }

      temp.push({
        ...option,
        equipment,
        unitPriceToCustomer: equipment.unitPriceToCustomer,
        unitPriceToGsa: equipment.unitPriceToGsa,
      });
    });

    return temp;
  }, [options]);

  /** begin price calculations */
  const totalOptionPrice = useMemo(() => {
    let toGsa = 0;
    let toCustomer = 0;
    if (firstVehicle?.orderVehicleEquipments.length > 0) {
      firstVehicle?.orderVehicleEquipments.forEach((equipment) => {
        if (equipment.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER) return;
        toGsa += equipment.unitPriceToGsa * equipment.quantity;
        toCustomer += equipment.unitPriceToCustomer * equipment.quantity;
      });
      toGsa += firstVehicle?.makeColorPriceToGsa || 0;
      toCustomer += firstVehicle?.makeColorPriceToCustomer || 0;
    }
    return {
      toGsa,
      toCustomer,
    };
  }, []);

  const { basePrice, basePriceWithSurcharge } = getBasePrice(orderData);

  const totalSubtotalUnitPrice = useMemo(() => {
    const toGsa = basePrice + totalOptionPrice.toGsa;
    const toCustomer = basePriceWithSurcharge + totalOptionPrice.toCustomer;

    return {
      toGsa,
      toCustomer,
    };
  }, [totalOptionPrice]);

  const totalSubtotalPrice = useMemo(() => {
    let toGsa = basePrice;
    let toCustomer = basePriceWithSurcharge;

    firstVehicle?.orderVehicleEquipments.forEach((equipment) => {
      if (equipment.quantityType === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER) return;
      toGsa += equipment.unitPriceToGsa * equipment.quantity;
      toCustomer += equipment.unitPriceToCustomer * equipment.quantity;
    });
    toGsa += firstVehicle?.makeColorPriceToGsa || 0;
    toCustomer += firstVehicle?.makeColorPriceToCustomer || 0;

    return {
      toGsa: toGsa * orderData.orderVehicles.length,
      toCustomer: toCustomer * orderData.orderVehicles.length,
    };
  }, [totalOptionPrice]);

  const additionalMaterialsPrice = useMemo(() => {
    let toGsa = 0;
    let toCustomer = 0;

    additionalMaterials.forEach((equipment) => {
      toGsa += equipment.unitPriceToGsa * equipment.equipment.quantity;
      toCustomer +=
        equipment.unitPriceToCustomer * equipment.equipment.quantity;
    });

    return {
      toGsa,
      toCustomer,
    };
  }, [additionalMaterials]);

  const totalPrice = useMemo(() => {
    const toGsa = totalSubtotalPrice.toGsa + additionalMaterialsPrice.toGsa;
    const toCustomer =
      totalSubtotalPrice.toCustomer + additionalMaterialsPrice.toCustomer;

    return {
      toGsa,
      toCustomer,
    };
  }, [totalSubtotalPrice, additionalMaterialsPrice]);
  /** end price calculations */

  const sideNavItems = [
    Object.keys(mappedOptions).length > 0
      ? {
          body: <Link href="#selected-options">Selected options</Link>,
          id: 'selected-options',
          active:
            window.location.hash === '#selected-options' ||
            window.location.hash === '',
        }
      : null,
    showPaintAndGraphics
      ? {
          body: <Link href="#paint-and-graphics">Paint and graphics</Link>,
          id: 'paint-and-graphics',
          active: window.location.hash === '#paint-and-graphics',
        }
      : null,
    mappedTaggedOptions.length > 0
      ? {
          body: <Link href="#option-details">Option details</Link>,
          id: 'option-details',
          active: window.location.hash === '#option-details',
        }
      : null,
    {
      body: <Link href="#order-summary">Order summary</Link>,
      id: 'order-summary',
      active: window.location.hash === '#order-summary',
    },
  ].filter(Boolean);

  if (loadingOptions || loadingAttachments || loadingAreqs) {
    return <Spinner />;
  }

  return (
    <div className="grid-row grid-gap-6">
      <div className="tablet:grid-col-3">
        <SideNav items={sideNavItems} />
      </div>
      <div className="tablet:grid-col-9">
        <table className="options-and-pricing">
          <thead>
            <th>Order items</th>
            <th>Contract price</th>
            <th>Price + surcharge</th>
          </thead>

          <tbody className="base-price">
            <tr>
              <td>Base price per vehicle</td>
              <td>{formatCurrency(basePrice)}</td>
              <td>{formatCurrency(basePriceWithSurcharge)}</td>
            </tr>
          </tbody>

          {Object.keys(mappedOptions).length > 0 && (
            <tbody>
              <tr className="section-heading">
                <td>
                  <div className="heading" id="selected-options">
                    Selected options
                  </div>
                </td>
                <td />
                <td />
              </tr>
              {Object.keys(mappedOptions).map((key) => (
                <tr>
                  <td>
                    <div>
                      <strong>{key}</strong>
                    </div>
                    {mappedOptions[key].map((option) => (
                      <div>
                        {option.equipmentCode.code} -{' '}
                        {option.equipmentCode.title}
                        {option.equipmentCode.quantityRequired === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE && (
                          <> &#215;{option.equipment.quantity}</>
                        )}
                      </div>
                    ))}
                  </td>
                  <td>
                    <div>&nbsp;</div>
                    {mappedOptions[key].map((option) => (
                      <div>
                        {formatCurrency(
                          option.unitPriceToGsa * option.equipment.quantity,
                        )}
                      </div>
                    ))}
                  </td>
                  <td>
                    <div>&nbsp;</div>
                    {mappedOptions[key].map((option) => (
                      <div>
                        {formatCurrency(
                          option.unitPriceToCustomer *
                            option.equipment.quantity,
                        )}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {showPaintAndGraphics && (
            <tbody>
              <tr className="section-heading">
                <td>
                  <div className="heading" id="paint-and-graphics">
                    Paint and graphics
                  </div>
                </td>
                <td />
                <td />
              </tr>
              {firstVehicle?.makeColorName && (
                <tr>
                  <td>
                    <div>
                      <strong>Selected color</strong>
                    </div>
                    <div>{colorDisplay(firstVehicle)}</div>
                  </td>
                  <td>
                    <div>
                      &nbsp;
                    </div>
                    <div>
                      {firstVehicle?.makeColorPriceToGsa ? formatCurrency(firstVehicle?.makeColorPriceToGsa || 0) : ''}
                    </div>
                  </td>
                  <td>
                    <div>
                      &nbsp;
                    </div>
                    <div>
                      {firstVehicle?.makeColorPriceToGsa ? formatCurrency(firstVehicle?.makeColorPriceToCustomer || 0) : ''}
                    </div>
                  </td>
                </tr>
              )}

              {mappedPaintAndGraphicsOptions?.length > 0 && (
                <tr>
                  <td>
                    <div>
                      <strong>Selected paint and graphic options</strong>
                    </div>
                  </td>
                  <td />
                  <td />
                </tr>
              )}
              {mappedPaintAndGraphicsOptions.map((option) => (
                <tr>
                  <td>
                    <strong>
                      {option.optionCode} - {option.optionDescription}
                    </strong>
                    {option.customerCPLInput && (
                      <p>
                        <strong>Exact custom lettering to be displayed:</strong>{' '}
                        {option.customerCPLInput}
                      </p>
                    )}
                    <p>{option.customerInput}</p>
                    {option?.attachments?.length > 0 && (
                      <ul className="attachments">
                        {option.attachments.map((attachment) => {
                          if (attachment.equipmentCode !== option.optionCode) {
                            return null;
                          }

                          return (
                            <li>
                              <AttachmentDownload
                                name={attachment?.name}
                                metadataId={attachment?.metadataId}
                              />
                              <div className="attachment-description">
                                {attachment.description}
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </td>
                  <td>{formatCurrency(option.equipment.unitPriceToGsa)}</td>
                  <td>
                    {formatCurrency(option.equipment.unitPriceToCustomer)}
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {mappedTaggedOptions.length > 0 && (
            <tbody>
              <tr className="section-heading">
                <td>
                  <div className="heading" id="option-details">
                    Option details
                  </div>
                </td>
                <td />
                <td />
              </tr>
              {mappedTaggedOptions.map((option) => (
                <tr>
                  <td>
                    <div>
                      <strong>
                        {option.equipmentCode.code} -{' '}
                        {option.equipmentCode.title}
                        {option.equipmentCode.quantityRequired === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE && (
                          <>&#215; {option.quantity}</>
                        )}
                      </strong>

                      {option.attachments.length > 0 && (
                        <ul className="attachments">
                          {option.attachments.map((attachment) => {
                            if (
                              attachment.equipmentCode !== option.optionCode
                            ) {
                              return null;
                            }

                            return (
                              <li>
                                <AttachmentDownload
                                  name={attachment?.name}
                                  metadataId={attachment?.metadataId}
                                />
                                <div className="attachment-description">
                                  {attachment.description}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </td>
                  <td>
                    <div>{formatCurrency(option.unitPriceToGsa)}</div>
                  </td>
                  <td>
                    <div>{formatCurrency(option.unitPriceToCustomer)}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          )}

          {
            // eslint-disable-next-line react/prop-types
            mapAreqs?.length > 0 &&
              orderData?.requisitionType === REQUISITION_TYPE.AREQ && (
                <tbody>
                  <tr className="section-heading">
                    <td>
                      <div className="heading" id="additional-requirements">
                        Additional requirements
                      </div>
                    </td>
                    <td />
                    <td />
                  </tr>
                  {mappedReviewOptions?.length > 0 &&
                    mappedReviewOptions.map((option) => (
                      <tr>
                        <td>
                          <div>
                            <strong>
                              {option.equipmentCode.code} -{' '}
                              {option.equipmentCode.title}
                              {option.equipmentCode.quantityRequired ===
                                OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE && <>&#215; {option.quantity}</>}
                            </strong>

                            {option.attachments.length > 0 && (
                              <ul className="attachments">
                                {option.attachments.map((attachment) => {
                                  if (
                                    attachment.equipmentCode !==
                                    option.equipmentCode.code
                                  ) {
                                    return null;
                                  }

                                  return (
                                    <li>
                                      <AttachmentDownload
                                        name={attachment?.name}
                                        metadataId={attachment?.metadataId}
                                      />
                                      <div className="attachment-description">
                                        {attachment.description}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </td>
                        <td>
                          <div>{formatCurrency(option.unitPriceToGsa)}</div>
                        </td>
                        <td>
                          <div>
                            {formatCurrency(option.unitPriceToCustomer)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  {mapAreqs.length > 0 &&
                    mapAreqs.map((areq) => (
                      <tr>
                        <td>
                          <div>
                            <strong>{areq.description}</strong>

                            {areq.attachments.length > 0 && (
                              <ul className="attachments">
                                {areq.attachments.map((attachment) => {
                                  return (
                                    <li>
                                      <AttachmentDownload
                                        name={attachment?.name}
                                        metadataId={attachment?.metadataId}
                                      />
                                      <div className="attachment-description">
                                        {attachment.description}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            )}
                          </div>
                        </td>
                        <td />
                        <td />
                      </tr>
                    ))}
                </tbody>
              )
          }

          <tbody className="order-summary" id="order-summary">
            <tr>
              <td>Order Summary</td>
              <td />
              <td />
            </tr>

            <tr>
              <td>
                <strong>Base price per vehicle</strong>
              </td>
              <td>{formatCurrency(basePrice)}</td>
              <td>{formatCurrency(basePriceWithSurcharge)}</td>
            </tr>

            <tr>
              <td>
                <strong>Total vehicle options</strong>{' '}
                <span className="text-italic">
                  (Includes Paint & graphics, Options details and AREQ)
                </span>
              </td>
              <td>{formatCurrency(totalOptionPrice.toGsa)}</td>
              <td>{formatCurrency(totalOptionPrice.toCustomer)}</td>
            </tr>

            <tr>
              <td>
                <strong>Subtotal unit price</strong>{' '}
                <span className="text-italic">(Base + Options)</span>
              </td>
              <td>{formatCurrency(totalSubtotalUnitPrice.toGsa)}</td>
              <td>{formatCurrency(totalSubtotalUnitPrice.toCustomer)}</td>
            </tr>

            <tr className="section-heading">
              <td>
                <div className="heading">Quantity of vehicles</div>
              </td>
              <td>{orderData.orderVehicles.length}</td>
              <td>{orderData.orderVehicles.length}</td>
            </tr>

            <tr>
              <td>
                <strong>Subtotal price</strong>{' '}
                <span className="text-italic">
                  (Base + Options x Quantity + Options)
                </span>
              </td>
              <td>{formatCurrency(totalSubtotalPrice.toGsa)}</td>
              <td>{formatCurrency(totalSubtotalPrice.toCustomer)}</td>
            </tr>

            {additionalMaterials.length > 0 && (
              <>
                <tr className="section-heading">
                  <td>
                    <div className="heading">
                      Additional materials (per order)
                    </div>
                  </td>
                  <td />
                  <td />
                </tr>

                <tr className="additional-materials">
                  <td>
                    {additionalMaterials.map((equipment) => (
                      <div>
                        {equipment.equipmentCode.code} -{' '}
                        {equipment.equipmentCode.title} &#215;
                        {equipment.equipment.quantity}
                      </div>
                    ))}
                  </td>
                  <td>
                    {additionalMaterials.map((equipment) => (
                      <div>{formatCurrency(equipment.unitPriceToGsa)}</div>
                    ))}
                  </td>
                  <td>
                    {additionalMaterials.map((equipment) => (
                      <div>{formatCurrency(equipment.unitPriceToCustomer)}</div>
                    ))}
                  </td>
                </tr>

                <tr className="total-additional-materials-price">
                  <td>
                    <strong>Total additional materials price</strong>
                  </td>
                  <td>{formatCurrency(additionalMaterialsPrice.toGsa)}</td>
                  <td>{formatCurrency(additionalMaterialsPrice.toCustomer)}</td>
                </tr>
              </>
            )}

            <tr className="total-price">
              <td>Total price</td>
              <td>{formatCurrency(totalPrice.toGsa)}</td>
              <td>{formatCurrency(totalPrice.toCustomer)}</td>
            </tr>
            {orderData?.transactionType === TRANSACTION_TYPES.LEASING && canViewLeasing &&<>
            <tr className="section-heading">
              <td>
                <div className="heading">
                  Lease rates{' '}
                  <span className="font-sans-3xs text-italic text-normal">
                    (Rates estimated at time of contract award; actual rates may
                    vary)
                  </span>
                </div>
              </td>
              <td />
              <td />
            </tr>
            <tr>
              <td>
                <span>Base monthly lease rate</span>
              </td>
              <td></td>
              <td>
                {orderData?.monthlyRate
                  ? formatCurrency(Number(orderData?.monthlyRate))
                  : '--'}
              </td>
            </tr>
            <tr>
              <td>
                <span>Base mileage rate</span>
              </td>
              <td></td>
              <td>
                {orderData?.mileageRate
                  ? formatCurrency(Number(orderData?.mileageRate))
                  : '--'}
              </td>
            </tr>
            <tr>
              <td>
                <span>Optional equipment rate</span>
              </td>
              <td></td>
              <td>
                {orderData?.optionalEquipMarkup
                  ? formatCurrency(Number(orderData?.optionalEquipMarkup))
                  : '--'}
              </td>
            </tr>
            <tr>
              <td>
                <span>AFV incremental cost</span>
              </td>
              <td></td>
              <td>
                {orderData?.afvTotal
                  ? formatCurrency(Number(orderData?.afvTotal))
                  : '--'}
              </td>
            </tr>
            </>}
          </tbody>
        </table>
      </div>
    </div>
  );
}

OptionsAndPricing.propTypes = {
  orderData: PropTypes.objectOf(Object).isRequired,
  canViewLeasing: PropTypes.bool.isRequired,
};
