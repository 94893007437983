/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */

import React, { useMemo, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  AFPTable,
  Spinner,
  Link,
  Pagination,
  EmptyState,
} from '@gsa/afp-component-library';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import useMyOrdersState from './useMyOrdersState';
import { getTableColumns, ActionColumn } from './tableHelpers';
import { formatCurrency } from '../../utilities/CompareVehicleUtils';
import { resetFocusToFirstElement } from '../../utilities/commons';
import { useOrderFilter } from './providers/OrderFilterProvider';
import OrderStatusTag from './OrderStatusTag';
import './OrdersTable.scss';
import { UserRoles } from '../../constants/user-constants';
import MVDOAttachmentReprint from '../requisitions-and-orders/widgets/AttachmentDownload/MVDOAttachmentReprint';
import { requisitionTypeDescriptor } from '../non-standard-purchase/constants';
import { formatRequisitionTypeLabel } from './Filters/OrderFilterHelpers';
import { getOrdersForUserRequest } from '../../requests/order';
import { isInternalUser } from '../../utilities/authorization';
import { getOrderRequisitionLink } from '../order-detail/components/orderDetailsUtil';

const OrdersTabelColumn = ({ className, children }) => {
  return (
    <div
      className={classNames('tablet:grid-col', 'margin-bottom-3', className)}
    >
      {children}
    </div>
  );
};

OrdersTabelColumn.propTypes = {
  className: PropTypes.string,
  children: PropTypes.elementType,
};

OrdersTabelColumn.defaultProps = {
  className: '',
  children: '',
};

const OrdersTableSubRow = ({ label, value }) => {
  const testId = `order-table-row-${label
    .replace(/[^a-zA-Z0-9]/g, '-')
    .toLowerCase()}`;
  return (
    <div className="sub-row" data-testId={testId}>
      <span className="text-bold">{label}</span>
      <span>{value}</span>
    </div>
  );
};

OrdersTableSubRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const OrderLink = ({ value, row }) => {
  return <Link href={`/order/${row.original.orderId}`}>{value}</Link>;
};

OrderLink.propTypes = {
  value: PropTypes.string.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

const SubComponent = ({ row: { original } }) => {
  const { currentUser } = useCurrentUser();

  const { allVehicleTypes } = useMyOrdersState();

  const isVehicleSupplier = currentUser?.roles?.some(
    (role) => role.name === UserRoles.VEHICLE_SUPPLIER,
  );

  const vehicleType = allVehicleTypes.find(
    ({ code }) => code === original?.vehicleTypeCode,
  )?.title;

  const programType = formatRequisitionTypeLabel(
    requisitionTypeDescriptor[original.requisitionType],
  );

  const quantity = (
    <Link to={`/order?orderId=${original.orderId}`}>{original.quantity}</Link>
  );

  const totalPrice = formatCurrency(original.totalSellingPrice || 0.0);

  const createdBy = (
    <Link href={`mailto:${original.createdByUserEmail}`}>
      {original.createdByUser}
    </Link>
  );

  const approvedBy = (
    <Link href={`mailto:${original.approvedByUserEmail}`}>
      {original.approvedByUser}
    </Link>
  );

  const mvdoAttachmentReprint = (
    <Link href="#mvdo" external>
      <MVDOAttachmentReprint
        displayName="View PDF"
        orderId={original?.orderId}
      />
    </Link>
  );
  const orderRequisitionLink = original?.requisitionId ? (
    <RouterLink
      className="order-requisition-link"
      to={getOrderRequisitionLink(original)}
    >
      {(original?.requisitionNumber).toUpperCase()}
    </RouterLink>
  ) : (
    <span>{(original?.requisitionNumber).toUpperCase()}</span>
  );

  console.log({ original });

  return (
    <div className="sub-component">
      <OrdersTabelColumn className="margin-right-4">
        <OrdersTableSubRow
          label="Requisition number"
          value={orderRequisitionLink}
        />
        <OrdersTableSubRow label="Program type" value={programType} />
        <OrdersTableSubRow
          label="Standard Item Number"
          value={original.standardItemCode}
        />
        <OrdersTableSubRow label="Vehicle type" value={vehicleType} />
        <OrdersTableSubRow label="Model" value={original.modelCode} />
        <OrdersTableSubRow label="Quantity" value={quantity} />
        <OrdersTableSubRow label="DO UPIID" value={original.upiidNumber} />
        <OrdersTableSubRow label="Total price" value={totalPrice} />
      </OrdersTabelColumn>
      <OrdersTabelColumn>
        <OrdersTableSubRow label="Agency" value={original.agencyInfoName} />
        <OrdersTableSubRow label="Bureau" value={original.bureau} />
        <OrdersTableSubRow label="Office/Group" value={original.office} />
        <OrdersTableSubRow
          label="Agency order number"
          value={original.agencyOrderNumber}
        />
        {!isVehicleSupplier && (
          <>
            <OrdersTableSubRow label="Created by" value={createdBy} />
            <OrdersTableSubRow label="Approved by" value={approvedBy} />
          </>
        )}
        <OrdersTableSubRow
          label="Motor Vehicle Delivery Order"
          value={mvdoAttachmentReprint}
        />
      </OrdersTabelColumn>
    </div>
  );
};

const MyOrdersTable = () => {
  const {
    // onOrdersLoading,
    // orderRows,
    // orderCount,
    orderBy,
    orderDirection,
    setOrderDirection,
    setOrderBy,
    // getOrders,
    paginationState,
    setPaginationState,
  } = useMyOrdersState();
  const dispatch = useDispatch();
  const {
    allOrders: { rows: orderRows, count: orderCount },
    orderListLoading,
  } = useSelector((state) => state.orderReducer);
  const { filters } = useOrderFilter();

  // eslint-disable-next-line no-shadow
  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
    resetFocusToFirstElement();
  };

  const data = useMemo(() => {
    if (!orderRows || !orderRows.length) {
      return [];
    }
    return orderRows.map((row) => getTableColumns(row));
  }, [orderRows]);

  const loadOrders = useCallback(async () => {
    dispatch(
      getOrdersForUserRequest({
        offset: paginationState.offset,
        limit: paginationState.limit,
        orderBy,
        orderDirection,
        filters,
      }),
    );
  }, [orderBy, orderDirection, paginationState, filters]);

  useEffect(() => {
    if (Array.isArray(filters)) {
      return;
    }
    loadOrders().catch(console.error);
  }, [orderBy, orderDirection, paginationState, filters]);

  const columns = useMemo(
    () => [
      {
        Header: 'Order number',
        accessor: 'case_number',
        Cell: OrderLink,
      },
      {
        Header: 'Order status',
        accessor: 'order_status',
        Cell: (cell) => {
          const { value } = cell;
          return <OrderStatusTag value={value} />;
        },
      },
      {
        Header: 'Date placed',
        accessor: 'submitted_to_vendor_at',
      },
      {
        Header: 'Contract shipment date',
        accessor: 'contractShipmentDate',
        sortable: false,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        sortable: false,
        Cell: ({ value, row }) => <ActionColumn value={value} row={row} />,
      },
    ],
    [],
  );

  return (
    <>
      <div className="table-main-container">
        {columns.length > 0 && (
          <AFPTable
            testId="my-orders-table"
            columns={columns}
            data={orderListLoading ? [] : data}
            expandable
            renderRowSubComponent={SubComponent}
            onSort={(sortOrder) => {
              const [newOrderBy, newOrderDirection] = sortOrder.split(' ');

              setOrderBy(newOrderBy.replace(/`/g, ''));
              setOrderDirection(newOrderDirection);
            }}
          />
        )}

        {orderListLoading ? (
          <Spinner className="margin-y-9" />
        ) : (
          <>
            <Pagination
              buttonVariant="primary"
              boundaryCount={1}
              variant="advanced"
              itemsCount={orderCount}
              currentPage={paginationState.currentPage}
              itemsPerPage={paginationState.limit}
              siblingCount={1}
              isReset={paginationState.isReset}
              onPageChange={handlePaginationChange}
              defaultSort={`${orderBy}, ${orderDirection}`}
            />
            {!orderRows?.length && (
              <EmptyState
                data-testid="table-no-items"
                containerStyles="table-no-items"
                topText="No Orders Available"
                topTextStyles="title"
                bottomText="There are no matches for the filtered values at left."
                bottomTextStyles="description"
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

SubComponent.propTypes = {
  row: PropTypes.instanceOf(Object).isRequired,
};

export default MyOrdersTable;
