import React, { useEffect, useState } from 'react';
import { Textbox, Button, Label, PageTitle } from '@gsa/afp-component-library';
import { useNavigate } from 'react-router-dom';
import useBulkOrderModsState from './useBulkOrderModsState';

export const OrderModSteps = {
  CREATE_ORDER_MOD: 1,
  SELECT_ORDER_MOD: 2,
  UPDATE_ORDER_MOD: 3,
};

const CreateOrderMod = () => {
  const {
    orderModName,
    setOrderModName,
    createModHasError,
    createModErrorMessage,
    setCreateModHasError,
    setCreateModErrorMessage,
    currentStep,
    setSelectedContract,
    selectedContract,
    contractErrorMessage,
  } = useBulkOrderModsState();

  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Please fill out preferred name of your draft',
  );
  const [hasContractError, setHasContractError] = useState(false);
  const [errorContractMessage, setErrorContractMessage] = useState(
    'This is a required field',
  );
  const [createModName, setCreateModName] = useState('');
  const [prevModName, setPrevModName] = useState('');

  const [contractSearch, setContractSearch] = useState(selectedContract);

  function updateValue(e) {
    if (!e.target.value) {
      setErrorMessage('Please fill out preferred name of your draft');
      setHasError(!e.target.value);
    } else if (prevModName !== e.target.value) {
      setHasError(false);
      setCreateModErrorMessage(null);
      setCreateModHasError(false);
    } else {
      setHasError(true);
      setCreateModErrorMessage(
        'The order modification name you entered already exists. Please try another.',
      );
      setCreateModHasError(true);
    }
    setCreateModName(e.target.value);
  }
  function updateValueOnBlur(e) {
    if (!e.target.value) {
      setErrorMessage('Please fill out preferred name of your draft');
      setHasError(!e.target.value);
    } else if (prevModName !== e.target.value) {
      setHasError(false);
      setCreateModErrorMessage(null);
      setCreateModHasError(false);
    }
    setCreateModName(e.target.value);
  }
  useEffect(() => {
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    const defaultFriendlyName = `MODIFICATION_${month}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;
    setCreateModName(defaultFriendlyName);
  }, []);

  function updateContractValue(e) {
    if (!e.target.value) {
      setHasContractError(true);
      setErrorContractMessage('This is a required field');
    } else {
      setHasContractError(false);
    }
    setContractSearch(e.target.value);
  }
  useEffect(() => {
    setHasError(createModHasError);
    setErrorMessage(createModErrorMessage);
    setHasContractError(!!contractErrorMessage);
    setPrevModName(orderModName);
    setErrorContractMessage(contractErrorMessage || errorContractMessage);
  }, [
    createModHasError,
    hasError,
    createModErrorMessage,
    contractErrorMessage,
  ]);

  useEffect(() => {
    setContractSearch(selectedContract);
  }, [selectedContract]);

  const createDraftOrderMod = async () => {
    setHasError(!createModName);
    // setHasContractError(!contractSearch);
    if (createModName && contractSearch) {
      setOrderModName(createModName);
      setSelectedContract(contractSearch.trim());
    }
  };

  return (
    currentStep === OrderModSteps.CREATE_ORDER_MOD && (
      <>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        />
        <div role="tab" tabIndex={0}>
          <PageTitle
            data-testid="requisitions-and-orders-header"
            title="Create a modification"
          />
          <h2>Start a modification draft</h2>
          <p className="requisitions-and-orders-header-desc">
            Before proceeding with your modifications, please first save a
            draft. Each draft name must be unique.
          </p>
          <div className="clone-requisition-input">
            <Label>Enter the preferred name of your draft</Label>
            <div className={`usa-form-group${hasError ? '--error' : ''}`}>
              {hasError && (
                <span className="usa-error-message">{errorMessage}</span>
              )}
              <Textbox
                id="draft-order-mod"
                required
                aria-label="Enter the preferred name of your draft"
                className={`usa-input${hasError ? '--error' : ''}`}
                value={createModName}
                key="draft-order-mod"
                data-testid="draft-order-mod"
                name="draft-order-mod"
                onChange={(e) => updateValue(e)}
                onBlur={(e) => updateValueOnBlur(e)}
              />
            </div>
          </div>
          <div className="clone-requisition-input margin-top-6">
            <div
              className={`usa-form-group${hasContractError ? '--error' : ''}`}
            >
              <Label required>
                Enter the contract containing the orders you wish to modify
              </Label>
              <p className="requisitions-and-orders-header-desc">
                [Please note] All modifications in this ticket will only be
                applied orders <strong>within this contract.</strong>
              </p>
              {hasContractError && (
                <span className="usa-error-message">
                  {errorContractMessage}
                </span>
              )}
              <Textbox
                id="contract-search-name"
                required
                aria-label="Enter the contract containing the orders you wish to modify"
                className={`usa-input${hasContractError ? '--error' : ''}`}
                value={contractSearch}
                key="contract-search-name"
                data-testid="contract-search-name"
                name="contract-search-name"
                onChange={(e) => updateContractValue(e)}
                onBlur={(e) => updateContractValue(e)}
              />
            </div>
          </div>
          <div className="clone-req-action-btn">
            <Button
              variant="unstyled"
              label="Cancel"
              onClick={() => navigate('/order-modifications')}
            />
            <Button
              label="Select orders to modify"
              type="button"
              variant="primary"
              data-testid="select-order-to-modify"
              rightIcon={{ name: 'arrow_forward' }}
              disabled={hasError}
              onClick={createDraftOrderMod}
            />
          </div>
        </div>
      </>
    )
  );
};

export default CreateOrderMod;
