import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  RadioButton,
  RequiredFieldIndicator,
  TextInput,
  Alert,
} from '@gsa/afp-component-library';
import './TechnicalEvaluationForm.scss';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext.jsx';
import VehicleRequisitionContextActions
  from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions.jsx';

const TechnicalEvaluationForm = ({ index }) => {
  const {
    trigger,
    formState: { errors },
  } = useFormContext();
  const { dispatch } = useContext(VehicleRequisitionContext);

  const vendorQuotes = useWatch({name: `vendorQuotes` });


  useEffect(() => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_AREQ_ENGINEER_QUOTES,
      payload: [...vendorQuotes],
    });
  }, [vendorQuotes]);

  const accepted = useWatch({ name: `vendorQuotes.${index}.acceptable` });

  // If you choose accepted, the comment is optional
  // When switching from NOT accepted to accepted,
  // if the "field required" error is showing,
  // will need to clear
  useEffect(() => {
    if (accepted !== 'yes') {
      return () => {};
    }

    trigger(`vendorQuotes.${index}.comment`);
    return () => {};
  }, [accepted]);

  return (
    <div className="technical-evaluation">
      <div className="title">
        Engineer&apos;s technical evaluation <RequiredFieldIndicator />
      </div>

      <div className="usa-error-message">
        {errors?.vendorQuotes?.[index]?.acceptable?.message}
      </div>

      <Controller
        rules={{ required: 'This is a required field' }}
        name={`vendorQuotes.${index}.acceptable`}
        render={({ field: { onChange, ref } }) => (
          <RadioButton
            name={`vendorQuotes.${index}.acceptable`}
            label="Acceptable"
            value="yes"
            checked={vendorQuotes?.[index]?.acceptable === 'yes'}
            onChange={onChange}
            inputRef={ref}
          />
        )}
      />
      <Controller
        rules={{ required: 'This is a required field' }}
        name={`vendorQuotes.${index}.acceptable`}
        render={({ field: { onChange, ref } }) => (
          <RadioButton
            name={`vendorQuotes.${index}.acceptable`}
            label="Unacceptable"
            value="no"
            checked={vendorQuotes?.[index]?.acceptable === 'no'}
            onChange={onChange}
            inputRef={ref}
          />
        )}
      />

      {accepted === 'no' && (
        <Alert type="warning" slim width="700">
          <span className="attention-text">
            You’ve marked this vendor’s quote as unacceptable.
          </span>{' '}
          Please provide your reasons below for designating this Vendor&apos;s
          quote as technically unacceptable.{' '}
        </Alert>
      )}
      <Controller
        rules={{
          validate: (value, formValues) => {
            const { vendorQuotes } = formValues;
            const { acceptable } = vendorQuotes[index];
            return acceptable === 'no' && !value
              ? 'This is a required field'
              : undefined;
          },
        }}
        name={`vendorQuotes.${index}.comment`}
        render={({ field: { onChange, ref, value } }) => (
          <TextInput
            type="textarea"
            label={
              accepted === 'no' ? (
                <>
                  Provide reason
                  <RequiredFieldIndicator />
                </>
              ) : (
                <>Additional Comments</>
              )
            }
            onChange={onChange}
            value={value}
            inputRef={ref}
            name={`vendorQuotes.${index}.comment`}
            errorMessage={errors?.vendorQuotes?.[index]?.comment?.message}
            characterLimit={1000}
            inputClass="comment-textarea"
          />
        )}
      />
    </div>
  );
};

TechnicalEvaluationForm.propTypes = {
  index: PropTypes.number.isRequired,
};

export default TechnicalEvaluationForm;
