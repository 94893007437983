import { formatCurrency } from '../../../utilities/CompareVehicleUtils';
import { RequisitionStatus } from '../../ReviewDetails/RequisitionDetailsUtils';

export const getFormattedValue = (inputType, optionValue) => {
  switch (inputType) {
    case 'STANDARD':
      return 'STANDARD';
    case 'COST':
      return formatCurrency(optionValue || 0);
    case 'INCLUDED':
      return 'Included';
    default:
      return 'N/A';
  }
};

export const getAreqOptionValue = (perVehicleOptions, optionCode) => {
  if (!Array.isArray(perVehicleOptions)) {
    return '';
  }

  const perVehicleOption =
    perVehicleOptions.find(
      ({ optionCode: foundOptionCode }) => foundOptionCode === optionCode,
    ) ?? {};

  return getFormattedValue(perVehicleOption?.optionType, perVehicleOption?.unitPrice);
};

export const getAreqTotal = (
  totalVendorQuote,
  quantity,
  totalSellingPriceBeforeQuote,
) => totalVendorQuote / quantity - totalSellingPriceBeforeQuote;

export const getAdditionalMaterialsFromSelectedContractCostBreakdown = (
  selectedContractCostBreakdown,
) => {
  if (!selectedContractCostBreakdown?.perOrderOptions?.filter) {
    return [];
  }

  return selectedContractCostBreakdown.perOrderOptions.filter(
    (priceOrderList) => priceOrderList?.optionType !== 'EXCLUDED',
  );
};

export const getPriceDataFromRequisition = (requisition) => {
  if (
    requisition &&
    requisition.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA
  ) {
    const { quantity, purchaseRate, totalSellingPrice, vehicles } = requisition;
    let totalOptionPrice = 0;
    let unitPriceToCustomer = 0;
    vehicles.forEach((vehicle) => {
      unitPriceToCustomer = vehicle.unitPriceToCustomer;
      vehicle.vehicleEquipments.forEach((equip) => {
        totalOptionPrice += equip.totalOptionPrice;
      });
    });

    // const totalOptionPrice = vehicleEquipments.reduce((a, b) => a.totalOptionPrice + b.totalOptionPrice);
    const purchaseFee = purchaseRate * 100 - 100;
    return {
      quantity,
      totalOptionPrice,
      unitPriceToCustomer,
      purchaseFee,
      totalSellingPrice,
    };
  }
  return null;
};
