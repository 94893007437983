import React from "react";
import { Modal, Button, connectModal } from "@gsa/afp-component-library";

export function CalculatePriceModal({handleClose, handleCalculatePrice}) {
    return (
        <Modal
            title={
                <h2 style={{ 
                    fontSize: '24px',
                    lineHeight: 3,
                    fontWeight: 700,
                }}>
                    Updated prices available
                </h2>}
            onClose={() => {
                handleClose()
            }}
            variant="large"
            actions={
                <>
                    <Button
                        type="button"
                        className="modal-cancel-button"
                        variant="outline"
                        onClick={() => {
                            handleClose();
                        }}
                        label="Cancel"
                    />
                    <Button
                        type="button"
                        className="modal-close-button"
                        onClick={() => {
                            handleClose();
                            handleCalculatePrice();
                        }}
                        label="Calculate Price"
                    />
                </>
            }
        >
            <p>
                Please select <strong>Calculate Price</strong> below to see
                updated prices prior to selecting a model
            </p>
        </Modal>
    );
}

export default connectModal(CalculatePriceModal);