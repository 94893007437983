import React from 'react';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';
import './option-unavailable-modal.scss';

export function OptionsUnavailableModal({ handleClose, unavailableOptions , handleContinue }) {
  return (
      <Modal
        onClose={handleClose}
        actions={
          <div
            style={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button
              type="button"
              variant="unstyled"
              onClick={handleClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="primary"
              onClick={handleContinue}
              label="Confirm"
            />
          </div>
        }
        variant='large'
        title={
          <h2 style={{ 
              fontSize: '24px',
              lineHeight: 3,
              fontWeight: 700,
          }}>
            Verify your selection
          </h2>}
      >
        <div className="unavailable-modal-body">
          <div>The following options are not available on the vehicle model that you have selected. </div>
          <div className="options-list">
            <ul>
              {unavailableOptions?.map(option => {
                return <li>{option?.optionDescription}</li>
              })}
            </ul>
          </div>
          <div>Please confirm that you would like to proceed, or go back to modify your selection.</div>
        </div>
      </Modal>
  );
}

export default connectModal(OptionsUnavailableModal);