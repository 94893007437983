import { createSlice } from '@reduxjs/toolkit';
import { REPLACE_VEHICLE_STEPS } from '../pages/vehicle-referral/replace-vehicle/replace-vehicle';

const vehicleReferralInitialState = {
  selectedOptions: [],
  currentReferralAction: '',
  currentStep: REPLACE_VEHICLE_STEPS.GARAGE,
  previousStandardItem: {
    id: null,
    standardItemNumber: null,
    title: null,
  },
  selectedStandardItem: null,
  useSameSin: true,
  lastSin: null,
  status: '',
  referralFiscalYear: null,

  /**
   * address1: '77 Forsyth Street',
   * address2: 'Apt 3NE',
   * city: 'Atlanta',
   * country: 'USA',
   * zip: '30303',
   * state: 'GA',
   */
  garageAddress: null,
  usedForTowing: false,
  projectedTowingWeight: {
    value: null,
    unit: 'lbs',
  },
  vehicleReferralFilters: [],
  vehicleReferralList: { rows: [], count: 0, hasMore: false },
  agencyGuidanceDocument: {},
  turnInSelection: {
    selectedOption: null,
    comment: null,
  },
  doNotReplaceSelection: {
    selectedOption: null,
    comment: null,
  },
  vehicleReferralActivities: [],
  vehicleDetails: {},
  referralRequestId: '',
  referralReplacementId: '',
  replacementAttachments: [],
  supplementaryInformation: {
    missionMakeModel: null,
    specialInstructions: '',
    acknowledge: false,
  },
  approvalLevel: null,
  paginationState: {
    limit: 25000,
    offset: 0,
    currentPage: 1,
    itemsPerPage: 25,
    isReset: false,
    order: null,
  },
  previousOptions: null,
  requisitionId: null,
  requisitionNumber:null,
  canReturnRequest: false,
  sinNotAvailable: false,
};

const vehicleReferralSlice = createSlice({
  name: 'vehicleReferral',
  initialState: vehicleReferralInitialState,
  reducers: {
    resetVehicleReferralState: (state) => {
      return {
        ...vehicleReferralInitialState,
        paginationState: state.paginationState,
        vehicleReferralFilters: state.vehicleReferralFilters,
      };
    },
    setSelectedReplacementOptions: (state, action) => {
      return { ...state, selectedOptions: action.payload };
    },
    addSelectedOption: (state, action) => {
      const selectedOption = state.selectedOptions.find(
        ({ option }) =>
          option.equipmentCode.code ===
          action.payload.option.equipmentCode.code,
      );

      if (selectedOption) {
        return state;
      }

      return {
        ...state,
        selectedOptions: [...state.selectedOptions, action.payload],
      };
    },
    updateSelectedOption: (state, action) => {
      const selectedOptions = state.selectedOptions.filter(Boolean).map((option) => {
        if (option.equipmentCode === action.payload.option?.equipmentCode?.code) {
          return action.payload;
        } else {
          return option;
        }
      });
      return { ...state, selectedOptions };
    },
    removeSelectedOption: (state, action) => {
      return {
        ...state,
        selectedOptions: [
          ...state.selectedOptions.filter(
            ({ option, equipmentCode }) => {
              if (equipmentCode && action.payload.equipmentCode) {
                return equipmentCode !== action.payload.equipmentCode
              } else if (action.payload.option && option.equipmentCodeId && action.payload.option.equipmentCodeId) {
                return option.equipmentCodeId !== action.payload.option.equipmentCodeId
              }

              return true;
            },
          ),
        ],
      };
    },
    changeSelectedOptionForCategory: (state, action) => {
      return {
        ...state,
        selectedOptions: [
          ...state.selectedOptions.filter(
            ({ option, equipmentCode }) => {
                if (equipmentCode) return false;
                return option.equipmentCode.category !== action.payload.option.equipmentCode.category
            }
          ),
          action.payload,
        ],
      };
    },
    updateCommentForSelectedOption: (state, action) => {
      const selectedOption = state.selectedOptions.find(
        ({ option }) =>
          option.equipmentCodeId === action.payload.option.equipmentCodeId,
      );
      if (!selectedOption) return state;

      selectedOption.comment = action.payload.comment;
      return state;
    },
    removeCommentFromSelectedOption: (state, action) => {
      const selectedOption = state.selectedOptions.find(
        ({ option }) =>
          option.equipmentCodeId === action.payload.option.equipmentCodeId,
      );
      if (!selectedOption) return state;

      selectedOption.comment = null;
      return state;
    },
    changeReplacementStep: (state, action) => {
      return { ...state, currentStep: action.payload };
    },
    changeGarageAddress: (state, action) => {
      return { ...state, garageAddress: action.payload };
    },
    changeProjectedTowingWeight: (state, action) => {
      return { ...state, projectedTowingWeight: action.payload };
    },
    setSelectedStandardItem: (state, action) => {
      return { ...state, selectedStandardItem: action.payload };
    },
    setUseSameSin: (state, action) => {
      return { ...state, useSameSin: action.payload };
    },
    setUsedForTowing: (state, action) => {
      return { ...state, usedForTowing: action.payload };
    },
    setTurnInSelection: (state, action) => {
      return { ...state, turnInSelection: action.payload };
    },
    setDoNotReplaceSelection: (state, action) => {
      return { ...state, doNotReplaceSelection: action.payload };
    },
    setVehicleReferralActivities: (state, action) => {
      return { ...state, vehicleReferralActivities: action.payload };
    },
    setAgencyGuidanceDocument: (state, action) => {
      return { ...state, agencyGuidanceDocument: action.payload };
    },
    setVehicleReferralList: (state, action) => {
      return { ...state, vehicleReferralList: action.payload };
    },
    setVehicleReferralFilters: (state, action) => {
      return {
        ...state,
        vehicleReferralFilters: action?.payload,
      };
    },
    setCurrentReferralAction: (state, action) => {
      return { ...state, currentReferralAction: action.payload };
    },
    setVehicleDetails: (state, action) => {
      return { ...state, vehicleDetails: action.payload };
    },
    setPreviousStandardItem: (state, action) => {
      return { ...state, previousStandardItem: action.payload };
    },
    setReferralRequestId: (state, action) => {
      return { ...state, referralRequestId: action.payload };
    },
    setCurrentStatusForVehicle: (state, action) => {
      return { ...state, status: action.payload };
    },
    setReferralReplacementId: (state, action) => {
      return { ...state, referralReplacementId: action.payload };
    },
    setReplacementAttachments: (state, action) => {
      return { ...state, replacementAttachments: action.payload };
    },
    addReplacementAttachment: (state, action) => {
      return {
        ...state,
        replacementAttachments: [
          ...state.replacementAttachments,
          action.payload,
        ],
      };
    },
    updateReplacementAttachment: (state, action) => {
      return {
        ...state,
        replacementAttachments: state.replacementAttachments.map(
          (attachment) => {
            if (
              attachment.referralReplacementAttachmentId ===
              action.payload.referralReplacementAttachmentId
            ) {
              return action.payload;
            }
            return attachment;
          },
        ),
      };
    },
    removeReplacementAttachment: (state, action) => {
      return {
        ...state,
        replacementAttachments: [
          ...state.replacementAttachments.filter(
            ({ referralReplacementAttachmentId }) =>
              referralReplacementAttachmentId !==
              action.payload.referralReplacementAttachmentId,
          ),
        ],
      };
    },
    setSupplementaryInformation: (state, action) => {
      return { ...state, supplementaryInformation: action.payload };
    },
    setApprovalLevel: (state, action) => {
      return { ...state, approvalLevel: action.payload };
    },
    setPaginationState: (state, action) => {
      return { ...state, paginationState: action.payload };
    },
    setPreviousOptions: (state, action) => {
      return { ...state, previousOptions: action.payload };
    },
    setRequisitionId: (state, action) => {
      return { ...state, requisitionId: action.payload };
    },
    setRequisitionNumber: (state, action) => {
      return { ...state, requisitionNumber: action.payload };
    },
    setCanReturnRequest: (state, action) => {
      return { ...state, canReturnRequest: action.payload };
    },
    setLastSin: (state, action) => {
      return { ...state, lastSin: action.payload };
    },
    setSinNotAvailable: (state, action) => {
      return { ...state, sinNotAvailable: action.payload };
    },
    setReferralFiscalYear: (state, action) => {
      return { ...state, referralFiscalYear: action.payload };
    }
  },
});

export const {
  resetVehicleReferralState,
  addSelectedOption,
  removeSelectedOption,
  changeSelectedOptionForCategory,
  updateCommentForSelectedOption,
  removeCommentFromSelectedOption,
  setTurnInSelection,
  setAgencyGuidanceDocument,
  setDoNotReplaceSelection,
  setVehicleReferralActivities,
  setVehicleReferralFilters,
  changeReplacementStep,
  changeGarageAddress,
  changeProjectedTowingWeight,
  changeSelectedSin,
  setSelectedStandardItem,
  setUseSameSin,
  setUsedForTowing,
  setVehicleReferralList,
  setCurrentReferralAction,
  setVehicleDetails,
  setPreviousStandardItem,
  setSelectedReplacementOptions,
  updateSelectedOption,
  setReferralRequestId,
  setCurrentStatusForVehicle,
  setReferralReplacementId,
  setReplacementAttachments,
  addReplacementAttachment,
  updateReplacementAttachment,
  removeReplacementAttachment,
  setSupplementaryInformation,
  setApprovalLevel,
  setPaginationState,
  setPreviousOptions,
  setRequisitionId,
  setRequisitionNumber,
  setCanReturnRequest,
  setLastSin,
  setSinNotAvailable,
  setReferralFiscalYear,
} = vehicleReferralSlice.actions;

export default vehicleReferralSlice.reducer;
