import React, { useEffect, useMemo, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { Accordion, Icon, Button, Spinner, useModal } from '@gsa/afp-component-library';
import CheckBoxesGroup from '../../../../components/AddOptions/components/CheckBoxesGroup/CheckBoxesGroup';
import RadioButtonGroup from '../../../../components/AddOptions/components/RadioButtonGroup/RadioButtonGroup';
import {
  setAddOptionErrors,
  setAffectedOption,
  setCalculatePriceDisabled,
  setCollisionState,
  setConflictDataOnYes,
  setDisplayCalculatePriceModal,
  setResolvedOptions,
  setSelectedOptions,
  setActiveEngineInfo,
} from '../../../../reducers/leasing';
import { getDisplayableOptionalData } from '../../../../utilities/CompareVehicleUtils';
import { useDispatch, useSelector } from 'react-redux';
import { useGetCalculatedPrice, useGetCollisionsForSelection } from '../../../../requests/leasing';
import './add-options.scss';
import {
  OPTION_QUANTITY_REQUIRED_CODE,
  OPTION_GROUP_SELECTION_TYPE,
  TRANSACTION_TYPES,
} from '../../../../utilities/CompareVehicleUtils';
import ConflictsModal from "./conflicts/conflicts-modal.jsx";
import CalculatePriceModal from "./total-selling-price-leasing/calculate-price-modal.jsx";


const getAddOptionsContent = ({
  addOptionErrors,
  addOptionsState,
  selectedOptions,
  selectedDeliveryOption,
  handleCalculatePrice,
  handleRowCheckBoxClick,
  onNumInputChange,
  onNumInputFocusChanged,
  handleRadioSelection,
  lowestBidData,
  colSpanCount,
  isAreqEnabled=false,
  isCalculatePriceDisabled
}) => {
  const errorLabel = addOptionErrors?.length > 1 ? 'errors' : 'error';
  return [
    {
      title: (
        <div
          className="add-options-accordion-title"
          style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          Add Options
          {addOptionErrors ? (
            <div className="add-options-accordion-title-error">
              <Icon className="usa-icon--size-3" iconName="error" />
              This section contains {`${addOptionErrors} ${errorLabel}`}
            </div>
          ) : null}
        </div>
      ),
      content: (
        <>
          <div className="option-table">
            <table
              className="add-option-main-container delivery-option-accordion"
              role="presentation"
            >
              <tbody>
                {addOptionsState?.length > 0 &&
                  addOptionsState.map((optionData, optionDataIndex) => {
                    const fragmentKey = `${optionData.standardItemId}-${optionData.optionCategoryCode}`;

                    return (
                      <React.Fragment key={fragmentKey}>
                        <tr
                          aria-label={optionData.optionCategoryName}
                          role="region"
                          tabIndex="0"
                          data-testid={`add_option_title_${optionDataIndex}`}
                        >
                          {optionData.optionCategoryCode !== 'AREQ' && (
                            <td
                              className="table-title"
                              colSpan={
                                optionData?.options[0]?.vendorValues?.length +
                                1
                              }
                            >
                              {optionData.optionCategoryName}
                            </td>
                          )}
                        </tr>
                        {optionData.selectionType === 'M' && (
                          <CheckBoxesGroup
                            optionData={optionData}
                            handleRowCheckBoxClick={handleRowCheckBoxClick}
                            onNumInputChange={onNumInputChange}
                            onNumInputFocusChanged={onNumInputFocusChanged}
                            optionDataIndex={optionDataIndex}
                            lowestBidData={lowestBidData}
                          />
                        )}

                        {optionData.selectionType === 'S' && (
                          <RadioButtonGroup
                            optionData={optionData}
                            optionDataIndex={optionDataIndex}
                            handleRadioSelection={handleRadioSelection}
                            lowestBidData={lowestBidData}
                          />
                        )}
                      </React.Fragment>
                    ); // eo return
                  })}
                {isAreqEnabled && (
                <tr className="additional-options">
                  <td className="option-cell" colSpan={colSpanCount}>
                    <div className="option-description-wrapper">
                      <span className="option-description-column">
                        <input
                          className="input-checkbox"
                          id="additional-options-checkbox"
                          type="checkbox"
                          onChange={(e) => {}}
                        />
                        <label htmlFor="additional-options-checkbox">
                          <strong>Additional Requirements</strong>
                        </label>
                      </span>
                    </div>

                    <div className="description">
                      The Additional Requirements (AREQ) option code allows
                      customers to request specific open market equipment not
                      listed in the Compare & Select page. You will be
                      required to provide information detailing your
                      additional requirements before sending the requisition
                      to GSA for their review.
                      <br />
                      <br />
                      <Button
                        variant="unstyled"
                        label="Read More"
                        onClick={() => {}}
                      />
                      <br />
                    </div>
                  </td>
                </tr>)}
              </tbody>
            </table>

            <div className="price-block">
              <div className="button-desc">{`Add options and click "Calculate Price" to update the total selling price for each vehicle below.`}</div>
              <div>
                <Button
                  label="Calculate Price"
                  disabled={isCalculatePriceDisabled || addOptionErrors > 0}
                  onClick={handleCalculatePrice}
                />
              </div>
            </div>
          </div>
        </>
      ),
      expanded: true,
    }, // eo Add Option section
  ];
};

export const AddOptionsSection = ({
  allAvailableOptionalReqData1,
  lowestBidData,
}) => {
  const { account, compareSelect, vehicleQuantity, selectedStandardItem, resolvedOptions, isCalculatePriceDisabled, displayCalculatePriceModal } = useSelector(
    (state) => state.leasingReducer,
  );
  const conflictsModal = useModal();
  const contractData = compareSelect.simplifiedContractsData;

  const {
    selectedOptions,
    selectedDeliveryOption,
    addOptionErrors,
    pagination,
    conflictDataOnYes,
    affectedOption,
  } = compareSelect;
  const dispatch = useDispatch();
  const initialAddOptions = getDisplayableOptionalData(
    pagination.page,
    pagination.offset,
    allAvailableOptionalReqData1,
  );
  const [getCalculatedPrice, { loading: isCalculatePriceLoading }] =
    useGetCalculatedPrice();
  const calculatePriceModal = useModal();

  const [getCollisions, { loading: isCollisionsLoading}] = useGetCollisionsForSelection();

  const [getCollisionsForYes, { loading: isCollisionsLoadingForYes}] = useGetCollisionsForSelection();

  // addOptionsState updated every option selected here
  const addOptionsState = useMemo(() => {
    initialAddOptions?.forEach((addOption) => {
      addOption.options.forEach((option) => {
        const foundObj = selectedOptions?.find(
          (v) => v.optionCode === option.optionCode,
        );
        if (foundObj) {
          option.isChecked = true;
          option.focused = foundObj.focused;
          option.optionValue = foundObj.optionValue;
          option.hasError = foundObj.hasError;
        }
      });
    });

    if(conflictDataOnYes) {
      if(
          conflictDataOnYes.vendorCollisionsPerOption) {
        conflictDataOnYes.vendorCollisionsPerOption.forEach((vendor) => {
          if(vendor.includedOptions.length ||
              vendor.excludedOptions.length ||
              vendor.requiredOptions.length ||
              vendor.unResolvedOptions.length) {
            initialAddOptions.forEach((optionList) => {
              optionList.options.forEach((option) => {
                option.vendorValues.forEach((vendorValue) => {
                  const vendorValueOption = vendorValue;
                  if (vendorValueOption.contractLineId === parseInt(vendor.contractLineId)) {
                    if (vendor.includedOptions?.length) {
                      vendor.includedOptions.forEach((includedItem) => {
                        if (
                            vendorValueOption.equipmentCode === includedItem.optionCode
                        ) {
                          vendorValueOption.isIncludeExist = true;
                        }
                      });
                    }
                    if (vendor.excludedOptions?.length) {
                      vendor.excludedOptions.forEach((excludedItem) => {
                        if (
                            vendorValueOption.equipmentCode === excludedItem.optionCode
                        ) {
                          vendorValueOption.isExcludeExist = true;
                        }
                      });
                    }
                    if (vendor.requiredOptions?.length) {
                      vendor.requiredOptions.forEach((requiredItem) => {
                        if (
                            vendorValueOption.equipmentCode === requiredItem.optionCode
                        ) {
                          vendorValueOption.isRequiredExist = true;
                        }
                      });
                    }
                    if (vendor.unResolvedOptions?.length) {
                      vendor.unResolvedOptions.forEach((unResolvedOption) => {
                        if (
                            vendorValueOption.equipmentCode ===
                            unResolvedOption.optionCode
                        ) {
                          vendorValueOption.unResolvedExist = true;
                        }
                      });
                    }
                  }
                  return vendorValueOption;
                });
              });
            });
          } else {
            initialAddOptions.forEach((optionList) => {
              optionList.options.forEach((option) => {
                option.vendorValues.forEach((vendorValue) => {
                  const vendorValueOption = vendorValue;
                  if (
                      parseInt(vendorValueOption.contractLineId, 10) ===
                      parseInt(vendor.contractLineId, 10)
                  ) {
                    if (vendorValueOption.isIncludeExist) {
                      vendorValueOption.isIncludeExist = false;
                    }
                    if (vendorValueOption.isExcludeExist) {
                      vendorValueOption.isExcludeExist = false;
                    }
                    if (vendorValueOption.isRequiredExist) {
                      vendorValueOption.isRequiredExist = false;
                    }
                    if (vendorValueOption.unResolvedExist) {
                      vendorValueOption.unResolvedExist = false;
                    }
                  }
                  return vendorValueOption;
                });
              });
            });
          }
        });
      }
    }
    return initialAddOptions;
  }, [selectedOptions, initialAddOptions, conflictDataOnYes]);

  const colSpanCount =
    addOptionsState?.length > 0 &&
    (addOptionsState[0]?.options[0]?.vendorValues?.length ?? 0) + 1;

  const handleAutoSelections = (collisionsData) => {
    if (collisionsData.unselectOptions?.length) {
      updateAddOptionsAutoSelection(
          false,
          collisionsData.unselectOptions,
      );
    }

    // automatically select options that are required by all vendors
    if (collisionsData.selectOptions?.required.length > 0) {
      updateAddOptionsAutoSelection(
          true,
          [].concat(collisionsData.selectOptions.required),
      );
    }
  }

  const handleRowCheckBoxClick = (optionCode) => {
    const selectedList = cloneDeep(selectedOptions);
    const currentAddOptionsState = cloneDeep(addOptionsState);
    currentAddOptionsState.forEach((addOption) => {
      addOption.options.forEach((option) => {
        const checkCategory =
          option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE ||
          option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER;

        if (option.optionCode === optionCode) {
          option.isChecked = !option.isChecked;
          if(!option.isChecked && checkCategory){
            onNumInputChange(1, option.optionCode)
          }
          option.selectionType = addOption.selectionType;
          option.optionCategoryCode = addOption.optionCategoryCode;
          const foundIndex = selectedList.findIndex(
            (v) => v.optionCode === optionCode,
          );
          const isNotExist = option.isChecked && foundIndex;
          if (isNotExist) {
            const optionHasEcRelation = option.vendorValues?.find(opt => opt.relatedEquipment && opt.relatedEquipment.length > 0);

            if(optionHasEcRelation) {
              dispatch(setAffectedOption(option));
              getCollisions({
                variables: {
                  standardItemId: parseFloat(selectedStandardItem?.id, 10),
                  affectedOption: {
                    optionCode: option.optionCode,
                    optionQuantity: 1
                  },
                  selectedOptions: selectedList.map((option) => ({
                    optionCode: option.optionCode,
                    optionQuantity: 1,
                    vendorRequiredOptionSelection: option.vendorRequiredOptionSelection
                  })),
                  transactionType: TRANSACTION_TYPES.LEASING
                }
              }).then(collisionResult => {
                setCollisionInfo(collisionResult.data);
                handleAutoSelections(collisionResult.data);
                conflictsModal.openModal();
              });
            } else {
              // Per vehicle and per order condition check
              if (checkCategory) {
                selectedList.push({
                  ...option,
                  selectionType: addOption.selectionType,
                  optionCategoryCode: addOption.optionCategoryCode,
                  optionValue: '',
                  focused: true,
                });
              } else {
                selectedList.push({
                  ...option,
                  selectionType: addOption.selectionType,
                  optionCategoryCode: addOption.optionCategoryCode,
                });
              }
            }
          } else {
            selectedList.splice(foundIndex, 1);
            getCollisionsForYes({
              variables: {
                standardItemId: parseFloat(selectedStandardItem?.id, 10),
                affectedOption: null,
                selectedOptions: selectedList.map((option) => ({
                  optionCode: option.optionCode,
                  optionQuantity: 1
                })),
                transactionType: TRANSACTION_TYPES.LEASING
              }
            }).then(collisionResult => {
              dispatch(setConflictDataOnYes(collisionResult.data));
              handleAutoSelections(collisionResult.data);
            });
          }
        }
      });
    });

    dispatch(setSelectedOptions(selectedList));
    dispatch(setCalculatePriceDisabled(false));
    updateEngineInfo(selectedList);
  };

  const handleRadioSelection = (selectedOptCode) => {
    const selectedList = cloneDeep(selectedOptions);
    const currentAddOptionsState = cloneDeep(addOptionsState);
    currentAddOptionsState.forEach((addOption) => {
      if (addOption.selectionType === OPTION_GROUP_SELECTION_TYPE.SINGLE) {
        addOption.options.forEach((option) => {
          if (option.optionCode === selectedOptCode) {
            option.isChecked = !option.isChecked;
            option.selectionType = addOption.selectionType;
            option.optionCategoryCode = addOption.optionCategoryCode;
            const foundRadioOptions = selectedList.filter(
              (v) =>
                v.selectionType === OPTION_GROUP_SELECTION_TYPE.SINGLE &&
                v.optionCategoryCode === addOption.optionCategoryCode,
            );
            const isNotExist = option.isChecked;
            const isSelectionTypeExist = selectedList.findIndex(
                (v) => v.optionCode === foundRadioOptions[0]?.optionCode,
            );
            if (isNotExist) {
              if (isSelectionTypeExist !== -1) {
                selectedList.splice(isSelectionTypeExist, 1);
              }
              const optionHasEcRelation = option.vendorValues?.find(opt => opt.relatedEquipment && opt.relatedEquipment.length > 0);
              if(optionHasEcRelation) {
                dispatch(setAffectedOption(option));
                getCollisions({
                  variables: {
                    standardItemId: parseFloat(selectedStandardItem?.id, 10),
                    affectedOption: {
                      optionCode: option.optionCode,
                      optionQuantity: 1
                    },
                    selectedOptions: selectedList.map((option) => ({
                      optionCode: option.optionCode,
                      optionQuantity: 1,
                      vendorRequiredOptionSelection: option.vendorRequiredOptionSelection
                    })),
                    transactionType: TRANSACTION_TYPES.LEASING
                  }
                }).then(collisionResult => {
                  setCollisionInfo(collisionResult.data);
                  handleAutoSelections(collisionResult.data);
                  conflictsModal.openModal();
                });
              } else {
                selectedList.push({
                  ...option,
                  selectionType: addOption.selectionType,
                  optionCategoryCode: addOption.optionCategoryCode,
                });
              }
            } else {
              selectedList.splice(isSelectionTypeExist, 1);
              getCollisionsForYes({
                variables: {
                  standardItemId: parseFloat(selectedStandardItem?.id, 10),
                  affectedOption: null,
                  selectedOptions: selectedList.map((option) => ({
                    optionCode: option.optionCode,
                    optionQuantity: 1
                  })),
                  transactionType: TRANSACTION_TYPES.LEASING
                }
              }).then(collisionResult => {
                dispatch(setConflictDataOnYes(collisionResult.data));
                handleAutoSelections(collisionResult.data);
              });
            }
          }
        });
      }
    });

    dispatch(setSelectedOptions(selectedList));
    dispatch(setCalculatePriceDisabled(false));
    updateEngineInfo(selectedList);
  };

  const getSimplifiedPayload = (data) => {
    const selectedList = cloneDeep(data);
    const payload = [];
    selectedList.forEach((list) => {
      list.optionValue = list?.optionValue || 1;
      payload.push({
        optionCode: list.optionCode,
        optionQuantity: list?.optionValue || 1,
        isChecked: list.isChecked,
      });
    });
    dispatch(setSelectedOptions(selectedList));
    updateEngineInfo(selectedList);
    return payload;
  };

  const handleCalculatePrice = async () => {
    await getCalculatedPrice({
      variables: {
        standardItemId: parseFloat(selectedStandardItem?.id, 10),
        quantity: vehicleQuantity,
        selectedOptions: getSimplifiedPayload([
            ...selectedOptions?.filter(
              (opt) => !opt?.isDeliveryOption,
          ),
            ...selectedDeliveryOption
        ]),
        transactionType: TRANSACTION_TYPES.LEASING,
        fmcId: parseInt(account?.fmcId),
        standardItemNumber: selectedStandardItem?.standardItemNumber
      },
    });
     dispatch(setCalculatePriceDisabled(true));
     dispatch(setDisplayCalculatePriceModal(false));
  };

  // perVehicle or perOrder value update logic
  const addOptionErrorRef = useRef({});
  const onNumInputChange = (value, optionCode, justValidate = false) => {
    const selectedList = cloneDeep(selectedOptions);
    const numValue = parseInt(value, 10);
    const isNumNan = Number.isNaN(numValue);
    const inputError = numValue > 999 || isNumNan || numValue <= 0;
    let hasError = false;

    const updatedState = selectedList.map((optionItem) => {
      const hasNumInput =
        optionItem.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE ||
        optionItem.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER;
      const errorKey = optionItem.optionCode;
      if (optionItem.optionCode === optionCode) {
        const valstr = value ? value.toString() : '';
        if (!justValidate) optionItem.optionValue = value;
        if (valstr.length > 4)
          optionItem.optionValue = parseInt(valstr.slice(0, 4), 10);
        if (!justValidate) optionItem.hasError = inputError;
        if (hasNumInput && optionItem.hasError && !hasError) {
          hasError = true;
          addOptionErrorRef.current[errorKey] = true;
        }
        if (hasNumInput && (!optionItem.hasError || !hasError)) {
          addOptionErrorRef.current[errorKey] = false;
        }
      } else if (
        hasNumInput &&
        optionItem.isChecked &&
        !optionItem.optionValue
      ) {
        addOptionErrorRef.current[errorKey] = true;
      }

      return optionItem;
    });
    dispatch(setSelectedOptions(updatedState));
    updateEngineInfo(updatedState);
    const errorCount = Object.values(addOptionErrorRef.current).filter(
      (entry) => {
        return entry;
      },
    ).length;
    dispatch(setAddOptionErrors(errorCount));
  };

  const updateAddOptionsAutoSelection = (checked, allOptions) => {
    let selectedList = cloneDeep(selectedOptions);
    initialAddOptions.forEach((optionCat) => {
      optionCat.options.forEach((li) => {
        const option = li;
        const checkOptionExist = allOptions.indexOf(option.optionCode);
        if (checkOptionExist > -1 && checked) {
          option.isChecked = checked
          selectedList.push(option);
        } else {
          selectedList = selectedList.filter((opt) => !allOptions.includes(opt.optionCode));
        }
      });
    });
    dispatch(setSelectedOptions(selectedList));
    updateEngineInfo(selectedList);
  }

  const onNumInputFocusChanged = (focused, optionCode) => {
    let hasError = false;
    const selectedList = cloneDeep(selectedOptions);
    const updatedState = selectedList.map((option) => {
      const optionItem = option;
      const errorKey = optionItem.optionCode;

      if (optionItem.optionCode === optionCode) {
        if (optionItem.hasError && !hasError) {
          hasError = true;
        }
        optionItem.focused = focused;
        // if user selected the option with numInput
        // without enter the valid number, then
        // pop up the error message
        if (optionItem.optionValue === '' && !focused) {
          optionItem.optionValue = '';
          optionItem.hasError = true;
          addOptionErrorRef.current[errorKey] = true;
        }
      }

      return optionItem;
    });

    dispatch(setSelectedOptions(updatedState));
    updateEngineInfo(updatedState);
    const errorCount = Object.values(addOptionErrorRef.current).filter(
      (entry) => {
        return entry;
      },
    ).length;
    dispatch(setAddOptionErrors(errorCount));
  };

  const setCollisionInfo = (networkCollisionData) => {
    const modifiedNetworkCollisionData = cloneDeep(networkCollisionData);
    modifiedNetworkCollisionData?.vendorCollisionsPerOption?.forEach((vendor) => {
      vendor?.affectedRequiredOptions?.forEach(opt => {
        opt.isChecked = false;
      });
    });
    dispatch(setCollisionState(modifiedNetworkCollisionData));
  }

  const noSelectionHandler = () => {
    const selectedList = cloneDeep(selectedOptions);
    conflictsModal.closeModal();
    dispatch(dispatch(setCollisionState([])));
    dispatch(setResolvedOptions([]));
    getCollisionsForYes({
      variables: {
        standardItemId: parseFloat(selectedStandardItem?.id, 10),
        affectedOption: null,
        selectedOptions: selectedList.map((option) => ({
          optionCode: option.optionCode,
          optionQuantity: 1
        })),
        transactionType: TRANSACTION_TYPES.LEASING
      }
    }).then(collisionResult => {
      dispatch(setConflictDataOnYes(collisionResult.data));
    });
  };

  const optionSelectionClick = () => {
    const selectedList = cloneDeep(selectedOptions);
    conflictsModal.closeModal();
    dispatch(dispatch(setCollisionState([])));

    const modAffectedOption = cloneDeep(affectedOption);

    modAffectedOption.vendorRequiredOptionSelection = resolvedOptions.map((option) => ({
      contractLineId: parseFloat(option.contractLineId),
      requiredOptionSelectionIndex: option.selectedIndex,
    }));

    selectedList.push({
      ...modAffectedOption
    });
    dispatch(setSelectedOptions(selectedList));
    updateEngineInfo(selectedList);
    getCollisionsForYes({
      variables: {
        standardItemId: parseFloat(selectedStandardItem?.id, 10),
        affectedOption: null,
        selectedOptions: selectedList.map((option) => ({
          optionCode: option.optionCode,
          optionQuantity: 1,
          vendorRequiredOptionSelection: option.vendorRequiredOptionSelection
        })),
        transactionType: TRANSACTION_TYPES.LEASING
      }
    }).then(collisionResult => {
      dispatch(setResolvedOptions([]));
      dispatch(setConflictDataOnYes(collisionResult.data));

    });
  }

  const updateEngineInfo = (selectedList) => {
    const options = selectedList.map((opt) => opt.optionCode);
    let needsUpdate = false;
    const activeEngineInfo = [];
    contractData.forEach((contract) => {
      const activeEngine = options
        .filter(x => contract.engines.map(y => y.engineCode)
        .includes(x))?.[0] || 'BASEENGINE';
      if (activeEngineInfo.find(x => x.contractLineId === contract.contractLineId)?.engineCode !== activeEngine) 
        needsUpdate = true;
      activeEngineInfo.push({
        contractLineId: contract.contractLineId,
        ...contract.engines.find(x => x.engineCode === activeEngine) || {},
      });
    });
    if (needsUpdate) {
      dispatch(setActiveEngineInfo(activeEngineInfo));
    }
  }

  useEffect(() => {
    if (displayCalculatePriceModal) {
      calculatePriceModal.openModal();
    }
  }, [displayCalculatePriceModal])

  const closeCalculatePriceModal = () => {
    dispatch(setCalculatePriceDisabled(false));
    dispatch(setDisplayCalculatePriceModal(false));
    calculatePriceModal.closeModal();
  }

  return (
    <div id="add-options">
      <Accordion
        bordered={false}
        id="add-options"
        className={`add-options-accordion ${
          addOptionErrors ? 'accordion-error' : ''
        }`}
        items={getAddOptionsContent({
          addOptionErrors,
          addOptionsState,
          selectedOptions,
          selectedDeliveryOption,
          handleCalculatePrice,
          handleRowCheckBoxClick,
          onNumInputChange,
          onNumInputFocusChanged,
          handleRadioSelection,
          lowestBidData,
          colSpanCount,
          isCalculatePriceDisabled
        })}
      />
      {
        (!!isCollisionsLoading || !!isCollisionsLoadingForYes) && (
              <div className="afp-modal-overlay modalContainer conflict-modal">
                <Spinner size="large" className="margin-y-8"/>
              </div>
          )
      }

      {isCalculatePriceLoading && (
          <div className="afp-modal-overlay modalContainer conflict-modal">
            <Spinner size="large" className="margin-y-8" />
        </div>
      )}
      <ConflictsModal
          noSelectionHandler={noSelectionHandler}
          optionSelectionClick={optionSelectionClick}
          isOpen={conflictsModal.isOpen}
          selectedOptionItem={affectedOption}
          handleClose={noSelectionHandler}
      />
      <CalculatePriceModal
        isOpen={calculatePriceModal.isOpen}
        handleClose={ () => {closeCalculatePriceModal()}}
        handleCalculatePrice={()=> {handleCalculatePrice()}}
      />
    </div>
  );
};

export default AddOptionsSection;
