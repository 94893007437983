import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './total-selling-price.scss';
import cn from 'classnames';
import {
  Button,
  useModal
} from '@gsa/afp-component-library';
import { GiCheckMark } from 'react-icons/gi';
import ArrowRightSvg from '@/assets/images/arrow-right.svg';
import { formatCurrency } from '@/utilities/CompareVehicleUtils.jsx';
import { getAfvIncremental } from '../../../../../utilities/TotalSellingPriceUtils.jsx';
import {
  setSelectedContract,
  setDealershipDeliveryAddress,
  setSelectedEngine,
  setAfvIncrementalCost,
} from '@/reducers/leasing.jsx';
import useLeasingNavigation from '@/hooks/useLeasingNavigation.jsx';
import useUpdateDraft from '../../../components/update-draft/update-leasing-draft.js';
import { useGetFundingAvailability } from '../../../../../requests/leasing.jsx';
import { LeasingSteps } from '../../../leasing-consts.js';
import FundingModal from './funding-modal.jsx';
import { InitialLeasingSteps } from '../../../leasing-consts.js';
import OptionsUnavailableModal from './option-unavailable-modal.jsx';
import {
  setAddOptionErrors,
  setDisplayCalculatePriceModal,
  setLeasingSteps,
  setNonLowBidJustification,
} from '../../../../../reducers/leasing.jsx';
import {
  OPTION_OPTION_TYPE,
  OPTION_QUANTITY_REQUIRED_CODE,
} from '../../../../../utilities/CompareVehicleUtils.jsx';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { cloneDeep } from 'lodash';
import DeliveryUnavailableModal from './delivery-unavailable-modal.jsx';
import NonLowPriceSelectedModal from './non-low-price-selected-modal.jsx';

const TotalSellingPriceSection = ({
  contracts,
  lowestBidData,
  networkCostBreakDownData,
  deliveryOptions,
}) => {
  const dispatch = useDispatch();
  const displayFundingModal = useModal();
  const displayUnavailableModal = useModal();
  const deliveryUnavailableModal = useModal();
  const nonLowPriceModal = useModal();
  const [insufficientAFVFunds, setInsufficientAFVFunds] = useState(false);
  const [currentAvailableAFVFunds, setCurrentAvailableAFVFunds] = useState(0.0);
  const [unavailableOptions, setUnavailableOptions] = useState([]);
  const { handleLeasingNavigation } = useLeasingNavigation();
  const [updateDraft] = useUpdateDraft();
  const [getLeasingAvailability, { loading }] = useGetFundingAvailability();
  const {
    account,
    isCalculatePriceDisabled,
    compareSelect: { addOptionErrors },
  } = useSelector((state) => state.leasingReducer);
  const {
    compareSelect: {
      selectedContract,
      addOptions,
      selectedOptions,
      simplifiedContractsData,
    },
    shipmentCountry,
  } = useSelector((state) => state.leasingReducer);
  const activateLowestBid = (contract) => {
    return contract?.contractLineId === lowestBidData?.contractLineId
      ? 'lowest-bid'
      : '';
  };

  const [nonLowPriceSelect, setNonLowPriceSelect] = useState(false);
  const [selectedContractToBuild, setSelectedContractToBuild] = useState(null);
  const [isLowBidContractSelected, setIsLowBidContractSelected] =
    useState(true);

  useEffect(() => {
    const input = {
      extendedProps: { totalSellingPrice: null },
    };
    updateDraft(input, LeasingSteps.COMPARE_AND_SELECT);
  }, []);

  const getSelectedNotAvailableOptions = (selectedContractCostBreakdown) => {
    const contractsToReturn = [];
    if (selectedContractCostBreakdown) {
      selectedContractCostBreakdown.perOrderOptions.forEach((option) => {
        if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
          contractsToReturn.push(option);
        }
      });
      selectedContractCostBreakdown.perVehicleOptions.forEach((option) => {
        if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
          contractsToReturn.push(option);
        }
      });
    }
    if (addOptions && addOptions.length > 0) {
      addOptions.forEach((option) => {
        if (option.optionType === OPTION_OPTION_TYPE.NOT_AVAILABLE) {
          contractsToReturn.push(option.options[0]);
        }
      });
    }
    return contractsToReturn;
  };

  const handleDynamicStep = (isLowBidContract) => {
    let validSteps = [...InitialLeasingSteps];
    if (!isLowBidContract) {
      const nonLowBidJustification = {
        stepNumber: 4,
        key: LeasingSteps.NON_LOW_PRICE_JUSTIFICATION,
        label: 'Non-low price justification',
        status: 'not completed',
      };

      validSteps = [
        ...validSteps.slice(0, 3),
        nonLowBidJustification,
        ...validSteps.slice(3).map((step) => ({
          ...step,
          stepNumber: step.stepNumber + 1,
        })),
      ];
    } else {
      dispatch(setNonLowBidJustification(''));
      validSteps = validSteps
        .filter((step) => step.key !== LeasingSteps.NON_LOW_PRICE_JUSTIFICATION)
        .map((step, index) => ({
          ...step,
          stepNumber: index + 1,
        }));
    }
    dispatch(setLeasingSteps(validSteps));
    return {
      nextStep: isLowBidContract
        ? LeasingSteps.PAINT_AND_GRAPHICS
        : LeasingSteps.NON_LOW_PRICE_JUSTIFICATION,
      dynamicSteps: validSteps,
    };
  };

  const selectModelToBuild = () => {
    if (!isCalculatePriceDisabled) {
      dispatch(setDisplayCalculatePriceModal(true));
      return;
    }
    const unBuildableOption =
      networkCostBreakDownData &&
      networkCostBreakDownData.find((item) => {
        return item.contractLineId === selectedContractToBuild?.contractLineId;
      });
    handleSelectButton(unBuildableOption, selectedContractToBuild, true);
  };

  const handleSelectButton = async (
    unBuildableOption,
    contract,
    isContinued,
  ) => {
    setSelectedContractToBuild(contract);
    let hasErrors = false;
    const optionsErrors = {};
    selectedOptions.every((option) => {
      const hasNumInput =
        option.quantityRequiredCode ===
          OPTION_QUANTITY_REQUIRED_CODE.PER_VEHICLE ||
        option.quantityRequiredCode === OPTION_QUANTITY_REQUIRED_CODE.PER_ORDER;

      if (
        option.isChecked &&
        (!option.optionValue || option.optionValue === '' || option.hasError) &&
        hasNumInput
      ) {
        optionsErrors[option.optionCode] = true;
        hasErrors = true;
      }
    });

    const isLowBidContract = contract?.contractLineId === lowestBidData?.contractLineId;
    setIsLowBidContractSelected(isLowBidContract);
    const foundCostBreakDown = networkCostBreakDownData.find((cb) => cb.contractLineId === contract.contractLineId);

    const sortedWithBasePrice = cloneDeep(networkCostBreakDownData);
    sortedWithBasePrice.sort(
      (firstEl, secondEl) => firstEl.basePrice - secondEl.basePrice,
    );

    const afvIncremental = getAfvIncremental({
      afvTotal: sortedWithBasePrice[0]?.afvTotal,
      quantity: foundCostBreakDown?.quantity,
    });
    dispatch(setAfvIncrementalCost(sortedWithBasePrice[0]?.afvTotal));

    const contractInfo = simplifiedContractsData.find(
      (contract) => contract.contractLineId === foundCostBreakDown.contractLineId,
    );

    const engines = contractInfo?.engines || [];
    const clarifications = contractInfo?.clarifications || [];
    const optionCodes = selectedOptions.map((opt) => opt.optionCode);
    const activeEngineCode = optionCodes.filter((x) => engines.map((y) => y.engineCode).includes(x))?.[0] || 'BASEENGINE';

    const selectedEngine = engines.find((x) => x.engineCode === activeEngineCode) || {};

    const input = {
      extendedProps: {
        totalSellingPrice: foundCostBreakDown.totalSellingPrice,
        afvIncremental,
        contractLineId: foundCostBreakDown.contractLineId,
        contractLineVersionNumber: contract?.contractLineVersionNumber,
      },
      clientData: {
        clientState: {
          selectedContract: {
            ...foundCostBreakDown,
            isLowBidContract: isLowBidContract,
          },
        },
      },
      vehicle: {
        ...(selectedEngine?.fuelType && { fuelCode: selectedEngine?.fuelType }),
        ...(selectedEngine?.range && {
          totalRangeMiles: selectedEngine?.range,
        }),
        ...(selectedEngine?.convMpgCity && {
          convMpgCity: selectedEngine?.convMpgCity,
        }),
        ...(selectedEngine?.convMpgHighway && {
          convMpgHighway: selectedEngine?.convMpgHighway,
        }),
        ...(selectedEngine?.convMpgCombined && {
          convMpgCombined: selectedEngine?.convMpgCombined,
        }),
        ...(selectedEngine?.convGpm && { convGpm: selectedEngine?.convGpm }),
        ...(selectedEngine?.fuelType && {
          altFuelCode: selectedEngine?.fuelType,
        }),
        ...(selectedEngine?.altMpgCity && {
          altMpgCity: selectedEngine?.altMpgCity,
        }),
        ...(selectedEngine?.altMpgHighway && {
          altMpgHighway: selectedEngine?.altMpgHighway,
        }),
        ...(selectedEngine?.altMpgCombined && {
          altMpgCombined: selectedEngine?.altMpgCombined,
        }),
        ...(selectedEngine?.altGpm && { altGpm: selectedEngine?.altGpm }),
        ...(selectedEngine?.rangeElectric && {
          elecRangeMiles: selectedEngine?.rangeElectric,
        }),
        ...(selectedEngine?.elecMpgCity && {
          elecMpgCity: selectedEngine?.elecMpgCity,
        }),
        ...(selectedEngine?.elecMpgHighway && {
          elecMpgHighway: selectedEngine?.elecMpgHighway,
        }),
        ...(selectedEngine?.elecMpgCombined && {
          elecMpgCombined: selectedEngine?.elecMpgCombined,
        }),
      },
    };

    const unavailableOpt = getSelectedNotAvailableOptions(foundCostBreakDown);

    // Deal with the delivery option not being available
    const selectedDeliveryOption = selectedOptions.filter((opt) => opt?.isDeliveryOption)[0];
    const deliveryOptionUnavailable = unavailableOpt.find((opt) => opt.optionCode === selectedDeliveryOption?.optionCode);
    if (deliveryOptionUnavailable) {
      return deliveryUnavailableModal.openModal();
    }

    setUnavailableOptions(unavailableOpt);
    if (unavailableOpt?.length && !isContinued) {
      return displayUnavailableModal.openModal();
    }

    if (isLowBidContract || isContinued) {
      const errorCount = Object.values(optionsErrors).filter((entry) => {
        return entry;
      }).length;

      if (hasErrors) {
        dispatch(setAddOptionErrors(errorCount));
        return;
      }
      const agencyIndicator =
        account?.customer?.customerBureau?.agencyIndicatorCode === null ||
        account.customer?.customerBureau?.agencyIndicatorCode === ''
          ? 'N/A'
          : account?.customer?.customerBureau?.agencyIndicatorCode;

      const res = await getLeasingAvailability({
        variables: {
          agencyCode: account?.customer?.customerAgency?.id,
          agencyIndicator,
        },
      });
      const hasSufficientAFVFunds =
        foundCostBreakDown.afvTotal * foundCostBreakDown.quantity === 0 ||
        res?.data?.currentAvailableAFVFunds >=
          foundCostBreakDown.afvTotal * foundCostBreakDown.quantity;
      setInsufficientAFVFunds(!hasSufficientAFVFunds);
      setCurrentAvailableAFVFunds(res?.data?.currentAvailableAFVFunds);
      if (
        res?.data?.currentAvailableFunds >
          foundCostBreakDown?.totalSellingPrice &&
        hasSufficientAFVFunds
      ) {
        if (
          selectedContract?.contractLineId !==
          foundCostBreakDown?.contractLineId
        ) {
          setDealershipDeliveryAddress({});
        }
        dispatch(
          setSelectedContract({
            ...foundCostBreakDown,
            afvIncremental,
            clarifications,
            makeName: contract?.makeName,
            modelName: contract?.modelName,
            vendorBusinessClassName: contract?.vendorBusinessClassName,
            orderEndDate: contract?.orderEndDate,
            vendorName: contract?.vendorName,
            shipmentDays: contract?.shipmentDays,
            contractLineId: contract?.contractLineId,
            contractLineVersionNumber: contract?.contractLineVersionNumber,
            isLowBidContract: isLowBidContract,
          }),
        );

        dispatch(setSelectedEngine(selectedEngine));

        const { nextStep, dynamicSteps } = handleDynamicStep(isLowBidContract);
        updateDraft(input, nextStep).then((data) => {
          if (data)
            handleLeasingNavigation(
              nextStep,
              undefined,
              undefined,
              dynamicSteps,
            );
        });
      } else {
        nonLowPriceModal.openModal();
      }
    } else {
      nonLowPriceModal.openModal();
    }
  };

  const handleContinue = () => {
    displayUnavailableModal.closeModal();
    if (!isLowBidContractSelected) {
      nonLowPriceModal.openModal();
    } else {
      selectModelToBuild();
    }
  };
  return (
    <>
      <div className="afp-store-total-selling-price">
        <table className="usa-table full-width-table" role="presentation">
          <tbody>
            <tr>
              <td
                data-testid="total-sale-price-cell"
                className="total-sale-price-title-cell"
              >
                Total selling price:
              </td>
              {networkCostBreakDownData?.map((contract) => {
                const contractFragmentKey = `${contract?.contractLineId}`;
                const unBuildableInfo = contract?.isUnBuildable;
                return (
                  <React.Fragment key={contractFragmentKey}>
                    <td
                      data-testid={`${contract?.contractLineId}-total-sale-price`}
                      key={`${contract?.contractLineId}-total-sale-price`}
                      className={cn(
                        'contract-cell',
                        activateLowestBid(contract),
                      )}
                    >
                      {formatCurrency(contract?.totalSellingPrice)}{' '}
                      {unBuildableInfo ? '*' : ''}
                    </td>
                  </React.Fragment>
                );
              })}
            </tr>
            <>
              <tr>
                <td
                  data-testid="start-requisition"
                  className="total-sale-price-title-cell requisition"
                >
                  Start your Requisition
                  <img
                    className="requisition-arrow"
                    src={ArrowRightSvg}
                    alt="requisition direction icon"
                  />
                </td>
                {contracts?.map((contract) => {
                  const unBuildableOption =
                    networkCostBreakDownData &&
                    networkCostBreakDownData.find((item) => {
                      return item.contractLineId === contract?.contractLineId;
                    });

                  return (
                    <td
                      data-testid={`${contract?.contractLineId}-select-vehicle`}
                      key={`select-vehicle-${contract?.contractLineId}`}
                      className={cn(
                        'contract-cell',
                        activateLowestBid(contract),
                        'requisition-cell',
                      )}
                    >
                      <Button
                        data-testid={`${contract?.contractLineId}-select-model`}
                        className="req-select-button tooltip margin-0"
                        type="button"
                        variant="outline"
                        onClick={() => {
                          if (addOptionErrors && addOptionErrors > 0) {
                            return;
                          }
                          if (!isCalculatePriceDisabled) {
                            dispatch(setDisplayCalculatePriceModal(true));
                            return;
                          }
                          handleSelectButton(unBuildableOption, contract);
                        }}
                        label="Select This Model"
                      />
                    </td>
                  );
                })}
              </tr>
              {selectedContract && Object.keys(selectedContract).length ? (
                <tr>
                  <td
                    aria-label="td"
                    data-testid="start-requisition"
                    className="total-sale-price-title-cell requisition"
                  />
                  {contracts.map((contract) => {
                    return (
                      <td
                        data-testid={`${contract.contractLineId}-pre-selected-vendors`}
                        className="pre-selected-vendors"
                      >
                        {selectedContract?.contractLineId ===
                          contract.contractLineId && (
                          <>
                            {contract.contractLineId ===
                              lowestBidData.contractLineId && (
                              <span className="selected-vendor-container lowest-bid-container">
                                <GiCheckMark />
                                <span className="selected-vendor-title">
                                  LOWEST BID
                                </span>
                              </span>
                            )}
                            <span className="selected-vendor-container">
                              <AiOutlineArrowUp />
                              <span className="selected-vendor-title">
                                SELECTED VENDOR
                              </span>
                            </span>
                          </>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ) : null}
            </>
          </tbody>
        </table>
      </div>

      <FundingModal
        isOpen={displayFundingModal.isOpen}
        handleClose={() => displayFundingModal.closeModal()}
        isInsufficientAFV={insufficientAFVFunds}
        remainingAFVFunding={currentAvailableAFVFunds}
      />
      <OptionsUnavailableModal
        isOpen={displayUnavailableModal.isOpen}
        handleClose={() => displayUnavailableModal.closeModal()}
        handleContinue={handleContinue}
        unavailableOptions={unavailableOptions}
      />
      <DeliveryUnavailableModal
        isOpen={deliveryUnavailableModal.isOpen}
        handleClose={() => deliveryUnavailableModal.closeModal()}
      />
      <NonLowPriceSelectedModal
        isOpen={nonLowPriceModal.isOpen}
        handleClose={() => nonLowPriceModal.closeModal()}
        selectModelToBuild={selectModelToBuild}
      />

      {nonLowPriceSelect && (
        <div className="afp-modal-overlay modalContainer low-bid-justification">
          
        </div>
      )}
    </>
  );
};

export default TotalSellingPriceSection;
