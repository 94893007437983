import _get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextInput } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import LeasingStatusChange from './LeasingStatusChange';
import { navigateToContractDetails, setUpdatedContractLineECs, setShowSuccessMessage } from '../../reducers/ordering-guide';
import { OrderingGuideSteps } from './constants';
import { useState } from 'react';
import { UserRoles } from '../../constants';
const numberFormatterUS = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'});

export function USCurrencyFormat(v) {
  const curr = (typeof v === 'boolean' || typeof v === 'object' || isNaN(v)) ? 0 : v;
  return numberFormatterUS.format(curr);
}

const GoToECs = ({ contractLine }) => {
  const dispatch = useDispatch();
  const goToECDetails = () => {
    dispatch(setShowSuccessMessage(false))
    dispatch(
      navigateToContractDetails({
        currentStep: OrderingGuideSteps.CONTRACT_ECS,
        selectedContractLine: contractLine,
      }),
    );
  };

  return (
    <Button
      label={contractLine?.modelName}
      data-testid="reset-filter"
      type="button"
      variant="unstyled"
      className="width-auto"
      onClick={goToECDetails}
    />
  );
};

const AFV = ({ contractLineECS }) => {
  const { currentUser } = useCurrentUser();
  const {
    updatedContractECs
  } = useSelector((state) => state.orderingGuideReducer);

  const dispatch = useDispatch();
  const [ value, valueTo ] = useState(contractLineECS?.afvByEquipment)

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      updateAFV(value);
      return;
    }
    const isValid = /^\d*\.?\d*$/.test(value) && Number(value) >= 0;
    
    if (isValid) {
      updateAFV(value);
    }
  };
  const handleBlur = (e) => {
    const value = Number(e.target.value);
    if ((value < 0 || isNaN(value))) {
      updateAFV(0);
    }
  };

  const updateAFV = (afv) => {
    let updatedECList = [...updatedContractECs];
    const updatedECIndex = updatedECList?.findIndex(ec => ec?.contractLineECId === contractLineECS?.contractLineECId)
    if(updatedECIndex !== -1){
      updatedECList[updatedECIndex] = {
        ...updatedECList[updatedECIndex],
        afvByEquipment: afv
      };
    } else {
      updatedECList.push({...contractLineECS, afvByEquipment: afv});
    }
    dispatch(setUpdatedContractLineECs({
      updatedContractECs: updatedECList
    }));
    valueTo(afv)
  };
  const canUpdate = currentUser?.roles?.some((role) => ['1503', '100'].includes(role?.id));

  return (
    <TextInput
      type="number"
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={!canUpdate}
      value={value}
      prefix="$"
      name={`afv-name`}
      data-testid={`afv-data-test`}
      errorMessage={''}
      aria-label="AFV by Equipment"
    />
  );
};

export const ORDER_GUIDE_TABLE_COLUMN_HEADERS = [
  {
    Header: 'SIN',
    accessor: 'standardItemNumber',
  },
  {
    Header: 'Supplier',
    sortable: false,
    accessor: 'vendorName',
  },
  {
    Header: 'Model',
    accessor: 'modelName',
    Cell: ({ row }) => {
      return <GoToECs contractLine={row.original} />;
    },
  },
  {
    Header: 'Price',
    accessor: 'unitPrice',
    Cell: ({ row }) => {
      return USCurrencyFormat(row.original?.unitPrice);
    },
  },
  {
    Header: 'Leasing availability',
    sortable: false,
    accessor: 'contractLineId',
    Cell: ({ row }) => {
      return <LeasingStatusChange orderGuide={row.original} />;
    },
  },
];

export const CONTRACT_ECS_TABLE_COLUMN_HEADERS = [
  {
    Header: 'Equipment code',
    accessor: 'ecCode',
  },
  {
    Header: 'Description',
    sortable: false,
    accessor: 'ecTitle',
  },
  {
    Header: 'Equipment price',
    accessor: 'unitPrice',
    Cell: ({ row }) => {
      return USCurrencyFormat(row.original?.unitPrice);
    },
  },
  {
    Header: 'Leasing availability',
    accessor: 'leavingavailability',
    sortable: false,
    Cell: ({ row }) => {
      return <LeasingStatusChange orderGuide={row.original} />;
    },
  },
  {
    Header: 'AFV by equipment',
    sortable: false,
    accessor: 'contractLineId',
    Cell: ({ row }) => {
      return <AFV contractLineECS={row.original} />;
    },
  },
];
export const getTableColumns = (row) => ({
  contractLineId: _get(row, 'contractLineId'),
  contractModId: _get(row, 'contractModId'),
  modelCode: _get(row, 'modelCode'),
  modelYear: _get(row, 'modelYear'),
  scheduleLine: _get(row, 'scheduleLine'),
  standardItemNumber: _get(row, 'standardItemNumber'),
  unitPrice: _get(row, 'unitPrice'),
  vendorId: _get(row, 'vendorId'),
  vendorName: _get(row, 'vendorName'),
  afvBase: _get(row, 'afvBase'),
  isLeaseAvailable: _get(row, 'isLeaseAvailable'),
  modelName: _get(row, 'modelName'),
});

export const getECTableColumns = (row) => ({
  contractLineECId: _get(row, 'contractLineECId'),
  ecCode: _get(row, 'ecCode'),
  ecTitle: _get(row, 'ecTitle'),
  unitPrice: _get(row, 'unitPrice'),
  isLeaseAvailable: _get(row, 'isLeaseAvailable'),
  afvByEquipment: _get(row, 'afvByEquipment'),
});
