import React from 'react';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';

export function DeliveryUnavailableModal({
    handleClose,
}) {
  return (
      <Modal
        onClose={handleClose}
        actions={
          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              gap: '10px',
            }}
          >
            <Button
              type="button"
              onClick={handleClose}
              label="Close"
            />
          </div>
        }
        variant='large'
        title={
            <h2 style={{ 
                fontSize: '24px',
                lineHeight: 3,
                fontWeight: 700,
            }}>
                Delivery option not available
            </h2>
        }
      >
        Delivery option not available for selected vehicle. Please revise your delivery option or select a different vehicle model.
      </Modal>
  );
}

export default connectModal(DeliveryUnavailableModal);
