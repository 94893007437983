import React from 'react';
import { Button, Modal, connectModal } from '@gsa/afp-component-library';
import './funding-modal.scss';

export function NonLowPriceSelectedModal({
  handleClose,
  selectModelToBuild,
}) {
    return (
        <Modal
            title={
                <h2 style={{ 
                    fontSize: '24px',
                    lineHeight: 3,
                    fontWeight: 700,
                }}>
                    Non-low price vehicle selected
                </h2>
            }
            onClose={handleClose}
            variant="large"
            actions={
              <>
                <Button
                  type="button"
                  className="modal-cancel-button"
                  onClick={handleClose}
                  variant="outline"
                  label="No, stay on compare and select"
                />
                <Button
                  type="button"
                  className="modal-close-button"
                  onClick={() => {
                    handleClose();
                    selectModelToBuild();
                  }}
                  label="Yes, proceed with requisition"
                />
              </>
            }
          >
            <p>
              In accordance with the Federal Acquisition Regulation Part 16.505,
              you must provide a written justification when the lowest priced
              vehicle as equipped is not selected. Your best value justification
              will be included in the order file and is subject to Inspector
              General review.
            </p>
            <p>
              You will be required to provide a justification. Are you sure you
              want to continue?
            </p>
          </Modal>
    )
}

export default connectModal(NonLowPriceSelectedModal);