import { createSlice } from '@reduxjs/toolkit';
import {
  InitialLeasingSteps,
  RequisitionViewMode,
} from '../pages/leasing/leasing-consts';
import { RequisitionStatus } from '../pages/ReviewDetails/RequisitionDetailsUtils';

const leasingInitialState = {
  account: null,
  zones: null,
  replacementVehiclesTableState: {
    order: [['createdAt', 'DESC']],
    limit: 10,
    currentPage: 1,
    isReset: false,
    total: 0,
  },
  submitButtonDisabled: false,
  viewMode: RequisitionViewMode.EDIT,
  requisitionName: '',
  requisitionStatus: RequisitionStatus.DRAFT,
  selectedVehicles: [],
  currentStep: {},
  leasingSteps: [],
  requisitionId: null,
  requisitionNumber: null,
  createdByUserId: null,
  destinationType: null,
  destinationTypeLoadedFromDraft: false,
  countries: null,
  countryCodes: null,
  states: null,
  shipmentState: 'Select state',
  shipmentLocation: '',
  shipmentCountry: 'Select country',
  showAvailableVehicles: false,
  availableVehicleTypes: [],
  selectedStandardItem: null,
  vehicleQuantity: 1,
  availableVehicleSubTypes: null,
  resolvedOptions: [],
  isCalculatePriceDisabled: false,
  displayCalculatePriceModal: false,
  compareSelect: {
    standardItemInfo: null,
    programName: null,
    activeContracts: [],
    simplifiedContractsData: [],
    activeEngineInfo: [],
    addOptions: [],
    minOptions: [],
    optionalOptions: [],
    calculatedPriceBreakdown: [],
    featureOptions: [],
    selectedOptions: [],
    selectedDeliveryOption: [],
    affectedOption: null,
    pagination: { page: 0, offset: 5 },
    selectedContract: null,
    collisionState: [],
    conflictDataOnYes: [],
  },
  afvIncrementalCost: 0,
  paintAndGraphics: {
    selectedColors: [],
    paintAndGraphicsDescription: [],
    selectedContract: {},
    addOptionErrors: 0,
  },
  selectedVehicleType: null,
  vehicleTypeFilters: [],
  vehicleSubTypeFilters: [],
  vehicleListingsPagination: {
    currentPage: 1,
    itemsPerPage: 10,
  },
  requisitionCustomerAccount: null,
  vehicleListings: { rows: [], count: 0, hasMore: false },
  attachmentTypes: [],
  attachments: [],
  dealershipDeliveryAddress: {},
  marshallingAddress: {},
  billingInformation: {},
  referralReplacement: null,
  requisitionCartValidations: {},
  displayReqValidationModal: false,
  nonLowBidJustification: '',
  vehiclePurchaseType: '',
};

const leasingSlice = createSlice({
  name: 'leasing',
  initialState: leasingInitialState,
  reducers: {
    resetLeasingState: () => {
      return leasingInitialState;
    },
    setLeasingAccount: (state, action) => {
      return { ...state, account: action.payload };
    },
    setReplacementVehiclesTableTotal: (state, action) => {
      return {
        ...state,
        replacementVehiclesTableState: {
          ...state.replacementVehiclesTableState,
          total: action.payload,
        },
      };
    },
    setReplacementVehiclesTableState: (state, action) => {
      return {
        ...state,
        replacementVehiclesTableState: {
          ...state.replacementVehiclesTableState,
          ...action.payload,
        },
      };
    },
    addReplacementVehicle: (state, action) => {
      const temp = {
        ...state,
        selectedVehicles: [...state.selectedVehicles, action.payload],
      };

      if (!temp.account) {
        temp.account = action.payload;
      }

      temp.replacementVehiclesTableState = {
        ...temp.replacementVehiclesTableState,
        total: temp.selectedVehicles.length,
      };

      return temp;
    },
    setReplacementVehicles: (state, action) => {
      let temp = {
        ...state,
        selectedVehicles: action.payload,
        replacementVehiclesTableState: {
          ...state.replacementVehiclesTableState,
          total: action.payload.length,
        },
      };

      if (temp.selectedVehicles.length === 0) {
        temp.account = null;
      }

      return temp;
    },
    removeSelectedVehicle: (state, action) => {
      let temp = {
        ...state,
        selectedVehicles: state.selectedVehicles.filter(
          (vehicle) => vehicle.assetId !== action.payload.assetId,
        ),
      };

      if (temp.selectedVehicles.length === 0) {
        temp.account = null;
      }

      temp.replacementVehiclesTableState = {
        ...temp.replacementVehiclesTableState,
        total: temp.selectedVehicles.length,
      };

      return temp;
    },
    setZoneData: (state, action) => {
      return { ...state, zones: action.payload };
    },
    setCurrentStep: (state, action) => {
      return { ...state, currentStep: action.payload };
    },
    setStepIndicator: (state, action) => {
      return { ...state, stepIndicator: action.payload };
    },
    setLeasingSteps: (state, action) => {
      return { ...state, leasingSteps: action.payload };
    },
    setRequisitionId: (state, action) => {
      return { ...state, requisitionId: action.payload };
    },
    setDestinationType: (state, action) => {
      if (typeof action.payload === 'string') {
        return {
          ...state,
          destinationType: action.payload,
          destinationTypeLoadedFromDraft: false,
        };
      } else {
        return {
          ...state,
          destinationType: action.payload.type,
          destinationTypeLoadedFromDraft:
            action.payload.loadedFromDraft || false,
        };
      }
    },
    setShipmentState: (state, action) => {
      return { ...state, shipmentState: action.payload };
    },
    setShipmentLocation: (state, action) => {
      return { ...state, shipmentLocation: action.payload };
    },
    setShipmentCountry: (state, action) => {
      return { ...state, shipmentCountry: action.payload };
    },
    setStates: (state, action) => {
      return { ...state, states: action.payload };
    },
    setCountries: (state, action) => {
      return { ...state, countries: action.payload };
    },
    setCountryCodes: (state, action) => {
      return { ...state, countryCodes: action.payload };
    },
    setShowAvailableVehicles: (state, action) => {
      return { ...state, showAvailableVehicles: action.payload };
    },
    setAvailableVehicleTypes: (state, action) => {
      return { ...state, availableVehicleTypes: action.payload };
    },
    setAvailableVehicleSubTypes: (state, action) => {
      return { ...state, availableVehicleSubTypes: action.payload };
    },
    setClearVehicleTypeSelection: (state, action) => {
      return {
        ...state,
        availableVehicleTypes: [],
        showAvailableVehicles: false,
      };
    },
    setStandardItemInfo: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          standardItemInfo: action.payload,
        },
      };
    },
    setProgramName: (state, action) => {
      return {
        ...state,
        compareSelect: { ...state.compareSelect, programName: action.payload },
      };
    },
    setActiveContracts: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          activeContracts: action.payload,
        },
      };
    },
    setSimplifiedContractsData: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          simplifiedContractsData: action.payload,
        },
      };
    },
    setActiveEngineInfo: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          activeEngineInfo: action.payload,
        },
      };
    },
    setAddOptions: (state, action) => {
      return {
        ...state,
        compareSelect: { ...state.compareSelect, addOptions: action.payload },
      };
    },
    setMinOptions: (state, action) => {
      return {
        ...state,
        compareSelect: { ...state.compareSelect, minOptions: action.payload },
      };
    },
    setCalculatedPriceBreakdown: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          calculatedPriceBreakdown: action.payload,
        },
      };
    },
    setFeatureOptions: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          featureOptions: action.payload,
        },
      };
    },
    setSelectedVehicleType: (state, action) => {
      return { ...state, selectedVehicleType: action.payload };
    },
    setSelectedVehicleSubType: (state, action) => {
      return { ...state, selectedVehicleSubType: action.payload };
    },
    setVehicleTypeFilters: (state, action) => {
      return { ...state, vehicleTypeFilters: action.payload };
    },
    setVehicleSubTypeFilters: (state, action) => {
      return { ...state, vehicleSubTypeFilters: action.payload };
    },
    setSelectedStandardItem: (state, action) => {
      return { ...state, selectedStandardItem: action.payload };
    },
    setVehicleListingsPagination: (state, action) => {
      return { ...state, vehicleListingsPagination: action.payload };
    },
    setVehicleListings: (state, action) => {
      return { ...state, vehicleListings: action.payload };
    },

    setSelectedOptions: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          selectedOptions: action.payload,
        },
      };
    },
    setSelectedDeliveryOption: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          selectedDeliveryOption: action.payload,
        },
      };
    },
    setAffectedOption: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          affectedOption: action.payload,
        },
      };
    },
    setPagination: (state, action) => {
      return {
        ...state,
        compareSelect: { ...state.compareSelect, pagination: action.payload },
      };
    },
    setSelectedContract: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          selectedContract: action.payload,
        },
      };
    },
    setSelectedEngine: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          selectedEngine: action.payload,
        },
      };
    },
    setSelectedColors: (state, action) => {
      return {
        ...state,
        paintAndGraphics: {
          ...state.paintAndGraphics,
          selectedColors: action.payload,
        },
      };
    },
    setAttachmentTypes: (state, action) => {
      return { ...state, attachmentTypes: action.payload };
    },
    setAttachments: (state, action) => {
      return { ...state, attachments: action.payload };
    },
    addAttachment: (state, action) => {
      return { ...state, attachments: [...state.attachments, action.payload] };
    },
    setPaintAndGraphicsDescription: (state, action) => {
      return {
        ...state,
        paintAndGraphics: {
          ...state.paintAndGraphics,
          paintAndGraphicsDescription: action.payload,
        },
      };
    },
    setAddOptionErrors: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          addOptionErrors: action.payload,
        },
      };
    },
    setVehicleQuantity: (state, action) => {
      return { ...state, vehicleQuantity: action.payload };
    },
    setCollisionState: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          collisionState: action.payload,
        },
      };
    },
    setConflictDataOnYes: (state, action) => {
      return {
        ...state,
        compareSelect: {
          ...state.compareSelect,
          conflictDataOnYes: action.payload,
        },
      };
    },
    setResolvedOptions: (state, action) => {
      return { ...state, resolvedOptions: action.payload };
    },
    setCreatedByUserId: (state, action) => {
      return { ...state, createdByUserId: action.payload };
    },
    setDealershipDeliveryAddress: (state, action) => {
      return { ...state, dealershipDeliveryAddress: action.payload };
    },
    setMarshallingAddress: (state, action) => {
      return { ...state, marshallingAddress: action.payload };
    },
    setRequisitionCustomerAccount: (state, action) => {
      return { ...state, requisitionCustomerAccount: action.payload };
    },
    setDeliveryStateContext: (state, action) => {
      return { ...state, deliveryStateContext: action.payload };
    },
    setBillingInformation: (state, action) => {
      return { ...state, billingInformation: action.payload };
    },
    setViewMode: (state, action) => {
      return { ...state, viewMode: action.payload };
    },
    setRequisitionName: (state, action) => {
      return { ...state, requisitionName: action.payload };
    },
    setRequisitionStatus: (state, action) => {
      return { ...state, requisitionStatus: action.payload };
    },
    setRequisitionNumber: (state, action) => {
      return { ...state, requisitionNumber: action.payload };
    },
    setSubmitButtonDisabled: (state, action) => {
      return { ...state, submitButtonDisabled: action.payload };
    },
    setCalculatePriceDisabled: (state, action) => {
      return { ...state, isCalculatePriceDisabled: action.payload };
    },
    setDisplayCalculatePriceModal: (state, action) => {
      return { ...state, displayCalculatePriceModal: action.payload };
    },
    setReferralReplacement: (state, action) => {
      return { ...state, referralReplacement: action.payload };
    },
    setRequisitionCartValidations: (state, action) => {
      return { ...state, requisitionCartValidations: action.payload };
    },
    setDisplayReqValidationModal: (state, action) => {
      return { ...state, displayReqValidationModal: action.payload };
    },
    setNonLowBidJustification: (state, action) => {
      return {
        ...state,
        nonLowBidJustification: action.payload,
      };
    },
    setAfvIncrementalCost: (state, action) => {
      return {
        ...state,
        afvIncrementalCost: action.payload,
      };
    },
  },
});

export const {
  resetLeasingState,
  setReplacementVehicles,
  removeSelectedVehicle,
  setCurrentStep,
  setStepIndicator,
  setLeasingSteps,
  setZoneData,
  setRequisitionId,
  addReplacementVehicle,
  setLeasingAccount,
  setReplacementVehiclesTableTotal,
  setReplacementVehiclesTableState,
  setDestinationType,
  setShipmentCountry,
  setShipmentState,
  setShipmentLocation,
  setStates,
  setCountries,
  setCountryCodes,
  setShowAvailableVehicles,
  setAvailableVehicleTypes,
  setStandardItemInfo,
  setProgramName,
  setActiveContracts,
  setAddOptions,
  setMinOptions,
  setCalculatedPriceBreakdown,
  setFeatureOptions,
  setAvailableVehicleSubTypes,
  setClearVehicleTypeSelection,
  setSelectedVehicleType,
  setSelectedVehicleSubType,
  setVehicleListingsPagination,
  setVehicleTypeFilters,
  setVehicleSubTypeFilters,
  selectedVehicleSubType,
  setVehicleListings,
  setSelectedOptions,
  setSelectedDeliveryOption,
  setAffectedOption,
  setPagination,
  setSelectedContract,
  setSelectedEngine,
  setSelectedStandardItem,
  setSelectedColors,
  setAttachmentTypes,
  setAttachments,
  addAttachment,
  setPaintAndGraphicsDescription,
  setAddOptionErrors,
  setVehicleQuantity,
  setCreatedByUserId,
  setMarshallingAddress,
  setDealershipDeliveryAddress,
  setRequisitionCustomerAccount,
  setDeliveryStateContext,
  setBillingInformation,
  setCollisionState,
  setResolvedOptions,
  setConflictDataOnYes,
  setViewMode,
  setRequisitionName,
  setRequisitionStatus,
  setRequisitionNumber,
  setSubmitButtonDisabled,
  setSimplifiedContractsData,
  setActiveEngineInfo,
  setCalculatePriceDisabled,
  setDisplayCalculatePriceModal,
  setReferralReplacement,
  setRequisitionCartValidations,
  setDisplayReqValidationModal,
  setNonLowBidJustification,
  setAfvIncrementalCost,
} = leasingSlice.actions;

export default leasingSlice.reducer;
