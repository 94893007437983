/* eslint-disable react/jsx-curly-brace-presence */
import React, { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import {
  EmptyState,
  TextInput,
  SelectDropdown,
} from '@gsa/afp-component-library';
import { cloneDeep } from 'lodash';
import { InfoIcon } from '../../../../assets/images/info-icon';
import './ColorSelection.scss';
// import WarningSvg from '../../../../assets/images/warning-icon.svg';
import {
  VALIDATION_ERRORS_SECTIONS,
  RequisitionStep,
} from '../../constants/VehicleRequisitionConstants';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import ErrorContext from '../../../../context/ErrorContext/ErrorContext';
import VehicleRequisitionErrors from '../../constants/VehicleRequisitionErrors';
import ErrorActions from '../../../../context/ErrorContext/ErrorActions';
import PageAlerts from '../../../../components/PageAlert/PageAlert';
import FilesUpload from '../FilesUpload/FilesUpload';
import { GET_CONTRACT_LINE_MODEL_COLORS } from '../../../../services/data-layer';
import { isAreqType } from '../../../non-standard-purchase/constants';
import { TRANSACTION_TYPES } from '../../../../utilities/CompareVehicleUtils';

const ColorSelection = ({ requisitionDraftId, setPreventNavigation }) => {
  const { state, dispatch, updateDraftRequisition } = useContext(
    VehicleRequisitionContext,
  );

  const {
    dispatch: errorDispatch,
    hasError,
    state: errorState,
  } = useContext(ErrorContext);

  const {
    draftRequisition,
    availableColors,
    vehicleColors,
    vehicleQuantity,
    paintAndGraphicsOptions,
    addOptionsState,
    selectedContract,
    requisitionType,
    isAreq,
  } = state;

  const reg = /^[0-9\b]+$/;

  // TODO: This is a temporary solution to check if the requisition is an AREQ
  const isAreqRequisition = useMemo(() => {
    return (
      isAreqType(requisitionType) ||
      isAreqType(draftRequisition?.requisitionType) ||
      isAreq
    );
  }, [requisitionType, draftRequisition?.requisitionType, isAreq]);

  // state.customPaintOptionsContext seems like a good contender but it doesn't update
  // when all options are cleared. This could be a bug if the values are only checked
  // if truthy but need to be cleared if falsy
  const checkForCPT = () => {
    const exPaint = addOptionsState.find((option) => {
      return (
        option.optionCategoryCode === 'ExPaint' &&
        option?.options?.some((opt) => {
          return opt.optionCode === 'CPT' && opt.isChecked;
        })
      );
    });

    if (addOptionsState.length && !exPaint) {
      return false;
    }

    const draftCPT =
      draftRequisition?.clientData?.selectedOptions?.selectedOptions?.find(
        (option) => option.optionCode === 'CPT' && option.isChecked,
      );

    if (draftCPT) {
      return !!draftCPT;
    }
    return !!exPaint;
  };

  const isRequisitionCPT = checkForCPT();

  const isRecordCPL = (optionCode) => {
    return optionCode === 'CPL';
  };

  const isRequisitionCPL = !!paintAndGraphicsOptions?.some(
    (paintAndGraphicsOption) => {
      return isRecordCPL(paintAndGraphicsOption.optionCode);
    },
  );

  const validateAndUpdateDraftRequisition = () => {
    if (
      !requisitionDraftId ||
      hasError(
        RequisitionStep.COLOR_SELECTION,
        'colors',
        'colorSelection',
        VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
      )
    ) {
      return;
    }

    updateDraftRequisition(requisitionDraftId);
  };

  useEffect(() => {
    if (checkForCPT()) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
        payload: [],
      });
    }
  }, [draftRequisition, addOptionsState]);

  useEffect(() => {
    if (availableColors && availableColors.length) {
      const newSelectedColorsState = [];
      const [colorToAdd] = availableColors;
      newSelectedColorsState.push({
        color: colorToAdd,
        quantity: vehicleQuantity,
      });

      if (!checkForCPT()) {
        if (!(vehicleColors && vehicleColors.length > 0)) {
          const colorsFromDraft = draftRequisition?.vehicles
            ? draftRequisition.vehicles[0]
            : false;

          if (colorsFromDraft) {
            const listOfColors = [];
            listOfColors.push({
              color: {
                label: colorsFromDraft.makeColorName,
                value: colorsFromDraft.makeColorCode,
                price: colorsFromDraft.makeColorPriceToCustomer,
                vendorPrice: colorsFromDraft.makeColorPriceToGsa,
              },
              quantity: colorsFromDraft.quantity || 1,
            });

            dispatch({
              type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
              payload: listOfColors,
            });
          } else {
            dispatch({
              type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
              payload: newSelectedColorsState,
            });
          }
        }
      }
    }
  }, [availableColors]);

  const [getModelStandardItemColors] = useLazyQuery(
    GET_CONTRACT_LINE_MODEL_COLORS,
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const colors = data?.storeGetContractLineColors;
        if (colors.length > 0) {
          dispatch({
            type: VehicleRequisitionContextActions.UPDATE_AVAILABLE_COLORS,
            payload: colors.map((x) => ({
              label: x.description,
              value: x.colorCode,
              price: x.price,
              vendorPrice: x.vendorPrice,
            })),
          });
        }
      },
    },
  );

  useEffect(() => {
    if (selectedContract?.contractLineId) {
      getModelStandardItemColors({
        variables: {
          contractLineId: parseInt(selectedContract?.contractLineId, 10),
          transactionType: TRANSACTION_TYPES.PURCHASING,
        },
      });
    }
  }, [selectedContract, isAreqRequisition]);

  const errorForColorSelection = (errorAction) => {
    errorDispatch({
      type: errorAction,
      payload: {
        page: RequisitionStep.COLOR_SELECTION,
        form: 'colors',
        error: VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
      },
    });
  };

  const onChangeQuantity = ({ target }, index) => {
    const { value } = target;
    if (value !== '' && !reg.test(value)) {
      return;
    }
    if (!requisitionDraftId) {
      setPreventNavigation(true);
    }
    const newSelectedColorState = [...vehicleColors];
    newSelectedColorState[index].quantity = value;
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
      payload: newSelectedColorState,
    });
  };

  const selectColor = (value, index) => {
    const selectedColorObject = availableColors.find(
      (color) => color.value === value,
    );
    const newSelectedColorState = [...vehicleColors];
    newSelectedColorState[index].color = selectedColorObject || {
      label: '',
      value: '',
      price: 0,
      vendorPrice: 0,
    };

    //  NOTE: If a color is selected and it is not a CPT, force all colors to be the same
    if (!isRequisitionCPT) {
      const payload = [
        { color: selectedColorObject, quantity: vehicleQuantity },
      ];

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
        payload,
      });
      validateAndUpdateDraftRequisition();
      return;
    }
    if (newSelectedColorState.length > 1) {
      newSelectedColorState.forEach((color, ind) => {
        if (ind !== index && color.color.value === selectedColorObject?.value) {
          /* eslint no-param-reassign: "error" */
          color.color = {
            label: '',
            value: '',
            price: 0,
            vendorPrice: 0,
          };
        }
      });
    }
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
      payload: newSelectedColorState,
    });

    validateAndUpdateDraftRequisition();
  };

  useEffect(() => {
    if (vehicleColors.length > 0 && availableColors.length > 0) {
      if (!availableColors.map(x => x.value).includes(vehicleColors[0].color.value)) {
        selectColor(availableColors[0].value, 0);
      } else {
        selectColor(vehicleColors[0].color.value, 0);
      }
    }
  }, [vehicleColors, availableColors]);
  

  const deleteCurrentColor = (event, index) => {
    const newColorsState = [...vehicleColors];
    newColorsState.splice(index, 1);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SELECTED_COLORS,
      payload: newColorsState,
    });

    if (
      requisitionDraftId &&
      !hasError(
        'colorSelection',
        'colors',
        VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
      )
    ) {
      validateAndUpdateDraftRequisition(825);
      return;
    }

    setPreventNavigation(true);
  };

  const commentsErrorMsg = (errorMessage, asCPL = false) => {
    if (!hasError(RequisitionStep.COMMENTS_SECTION, 'comment', errorMessage)) {
      return null;
    }

    return (
      <span
        className="usa-error-message"
        data-testid="vehicle-requisition-color-selection__vehicle-quantity-error"
      >
        {asCPL
          ? 'Please provide up to 24 characters of custom lettering'
          : 'Must enter description with at least 6 characters'}
      </span>
    );
  };

  const getErrorTextIfAny = () => {
    if (
      hasError(
        'colorSelection',
        'colors',
        VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
      )
    ) {
      if (availableColors.length === 1) {
        return (
          <span
            className="usa-error-message"
            data-testid="vehicle-requisition-color-selection__vehicle-quantity-error"
          >
            Must match total quantity
          </span>
        );
      }
      return (
        <span
          className="usa-error-message"
          data-testid="vehicle-requisition-color-selection__vehicle-quantity-error"
        >
          Please check quantity
        </span>
      );
    }
    return null;
  };

  const getErrorForColor = () => {
    const totalSelectedVehicles = vehicleColors.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.quantity),
      0,
    );
    const areAllColorsValid =
      totalSelectedVehicles === Number(vehicleQuantity) &&
      vehicleColors?.filter((i) => !!i.color?.label).length ===
        vehicleColors?.length;

    if (
      hasError(
        'colorSelection',
        'colors',
        VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS],
      )
    ) {
      if (!vehicleColors?.length || !areAllColorsValid) {
        return (
          <span
            className="usa-error-message"
            data-testid="vehicle-requisition-color-selection__required-error"
          >
            Please select a color
          </span>
        );
      }
    }
    return null;
  };

  const handleValidations = () => {
    const newSelectedColorState = [...vehicleColors];
    const totalQuantitySelected = newSelectedColorState.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.quantity),
      0,
    );

    if (
      totalQuantitySelected === Number(vehicleQuantity) &&
      errorState?.colorSelection?.colors
    ) {
      errorForColorSelection(ErrorActions.REMOVE_ERROR);
    }

    validateAndUpdateDraftRequisition();
  };

  const handleOnBlurHandler = (inputValue, optionCode, asCPL = false) => {
    const clonedPaintAndGraphicsOptions = cloneDeep(paintAndGraphicsOptions);
    const newArr = clonedPaintAndGraphicsOptions.map((item) => {
      if (item.optionCode === optionCode) {
        if (asCPL) {
          item.customerCPLInput = inputValue;
        } else {
          item.customerInput = inputValue;
        }

        if (
          !asCPL &&
          item?.customerInput?.length > 0 &&
          errorState?.commentSection?.comment
        ) {
          errorDispatch({
            type: ErrorActions.REMOVE_ERROR,
            payload: {
              page: RequisitionStep.COMMENTS_SECTION,
              form: 'comment',
              error: `${
                VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
              }#${optionCode}`,
            },
          });
        }

        if (
          asCPL &&
          item?.customerCPLInput?.length > 0 &&
          errorState?.commentSection?.comment
        ) {
          errorDispatch({
            type: ErrorActions.REMOVE_ERROR,
            payload: {
              page: RequisitionStep.COMMENTS_SECTION,
              form: 'comment',
              error: `${
                VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS]
              }#${optionCode}#CPL`,
            },
          });
        }
      }

      return item;
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC,
      payload: newArr,
    });

    if (requisitionDraftId) {
      validateAndUpdateDraftRequisition();
      return;
    }

    setPreventNavigation(true);
  };

  const handleTextArea = (inputValue, optionCode, asCPL = false) => {
    const clonedPaintAndGraphicsOptions = cloneDeep(paintAndGraphicsOptions);

    const updatedArr = clonedPaintAndGraphicsOptions.map((item) => {
      if (item.optionCode === optionCode) {
        if (asCPL) {
          item.customerCPLInput = inputValue;
        } else {
          item.customerInput = inputValue;
        }
      }
      return item;
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC,
      payload: updatedArr,
    });
  };

  const paintAndGraphicsOpt = () => {
    if (!paintAndGraphicsOptions?.length) {
      return null;
    }

    return (
      <>
        <div className="selected-paint-list-section">
          <div className="selected-paint-heading-section">
            SELECTED PAINT AND GRAPHIC OPTIONS
          </div>
          <PageAlerts page={RequisitionStep.COMMENTS_SECTION} form="comment" />
          <ul>
            {paintAndGraphicsOptions.map((list, index) => {
              return (
                <li key={list.optionCode} data-testid={`color_${index}`}>
                  {list.optionCode} {'-'} {list.optionDescription}
                  {list.customerInputExist && (
                    <span className="provide-details">
                      {' '}
                      (Provide details below)
                    </span>
                  )}
                </li>
              );
            })}
          </ul>

          {paintAndGraphicsOptions.map((paintAndGraphicsOption, index) => {
            if (paintAndGraphicsOption.customerInputExist) {
              const errorColor =
                VehicleRequisitionErrors[VALIDATION_ERRORS_SECTIONS.COLORS];
              const message = `${errorColor}#${paintAndGraphicsOption.optionCode}`;
              const CPLmessage = `${errorColor}#${paintAndGraphicsOption.optionCode}#CPL`;

              return (
                <div
                  className="description-container"
                  key={paintAndGraphicsOption.optionCode}
                >
                  <div className="description-label">
                    {' '}
                    {paintAndGraphicsOption.optionCode} -{' '}
                    {paintAndGraphicsOption.optionDescription}{' '}
                    <InfoIcon role="img" aria-label="info" />
                  </div>
                  {isRecordCPL(paintAndGraphicsOption.optionCode) && (
                    <div
                      className={`${
                        hasError(
                          RequisitionStep.COMMENTS_SECTION,
                          'comment',
                          CPLmessage,
                        )
                          ? 'usa-form-group--error description-box '
                          : 'description-box '
                      }  ${'cpl-desc '}`}
                    >
                      {commentsErrorMsg(CPLmessage, true)}
                      {/* prettier-ignore */}
                      <TextInput
                        data-testid={`text-cpl-description-testid-${index}`}
                        type='input'
                        label='Enter the exact custom lettering to be displayed'
                        id='colorOptionCPLDescription'
                        className={`${hasError(
                          RequisitionStep.COMMENTS_SECTION,
                          'comment',
                          CPLmessage,
                        )
                          ? 'usa-input--error'
                          : ''
                          } comment`}
                        name={paintAndGraphicsOption.optionCode}
                        value={paintAndGraphicsOption.customerCPLInput}
                        onChange={(e) =>
                          handleTextArea(
                            e.target.value,
                            paintAndGraphicsOption.optionCode,
                            true,
                          )}
                        onBlur={(e) =>
                          handleOnBlurHandler(
                            e.target.value,
                            paintAndGraphicsOption.optionCode,
                            true,
                          )}
                        required
                        maxLength='24'
                        containerClassName='usa-form-group'
                      />
                      <div className="comment-box-desc">
                        24 characters allowed
                      </div>
                    </div>
                  )}

                  <div
                    className={`${
                      hasError(
                        RequisitionStep.COMMENTS_SECTION,
                        'comment',
                        message,
                      )
                        ? 'usa-form-group--error description-box '
                        : 'description-box '
                    } ${
                      isRecordCPL(paintAndGraphicsOption.optionCode)
                        ? 'cpl-addl-desc'
                        : ''
                    }`}
                  >
                    {commentsErrorMsg(message)}
                    {/* prettier-ignore */}
                    <TextInput
                      data-testid={`text-description-testid-${index}`}
                      type='textarea'
                      label='Enter a description of your requested option'
                      id='colorOptionDescription'
                      className={`${hasError(
                        RequisitionStep.COMMENTS_SECTION,
                        'comment',
                        message,
                      )
                        ? 'usa-input--error'
                        : ''
                        } comment `}
                      name={paintAndGraphicsOption.optionCode}
                      value={paintAndGraphicsOption.customerInput}
                      key='agencyOrderNumber'
                      onChange={(e) => handleTextArea(e.target.value, paintAndGraphicsOption.optionCode)}
                      onBlur={(e) => handleOnBlurHandler(e.target.value, paintAndGraphicsOption.optionCode)}
                      required
                      maxLength='1000'
                    />
                    <div className="comment-box-desc">
                      1000 characters allowed
                    </div>

                    <FilesUpload
                      optionCode={paintAndGraphicsOption.optionCode}
                      page="COLORS_SELECTION"
                    />
                  </div>
                </div>
              );
            }

            return null;
          })}
        </div>
      </>
    );
  };

  if (!availableColors?.length && !isAreqRequisition) {
    return (
      <EmptyState
        data-testid="table-no-items"
        containerStyles="table-no-items"
        topText="No available colors"
        topTextStyles="title"
        bottomText={
          <p>
            {
              'We do not show any colors available for this vehicle model. Please contact '
            }
            <a href="mailto:vehicle.buying@gsa.gov">
              {'vehicle.buying@gsa.gov'}
            </a>
            {' for assistance.'}
          </p>
        }
        bottomTextStyles="description"
      />
    );
  }

  const buildColorList = () => {
    return availableColors.map((x) => ({
      value: x.value,
      label: `${x.label} ${x.price > 0 ? `+$${x.price}` : ''}`,
    }));
  };

  return (
    <>
      <div className="colors-selection">
        {!!vehicleColors && (
          <>
            <div role="tab" tabIndex="0">
              <h2
                className="usa-h2"
                data-testid="vehicle-requisition-color-selection__title"
              >
                Choose colors and provide details
              </h2>
              <p
                className="colors-page-description"
                data-testid="vehicle-requisition-color-selection__info"
              >
                If applicable, enter description(s) for the options selected,
                and upload relevant attachments.
              </p>
            </div>
            <PageAlerts page={RequisitionStep.COLOR_SELECTION} form="colors" />

            {(
              <>
                <div
                  className={classNames('color-selection-container', {
                    'more-options':
                      isRequisitionCPT ||
                      isRequisitionCPL ||
                      paintAndGraphicsOptions?.length,
                  })}
                >
                  {!isRequisitionCPT && (
                    <div className="paint-heading-section">
                      STANDARD and ADDITIONAL COST COLORS
                    </div>
                  )}
                  <div className="top-section">
                    <span
                      className="quantity-selection-title"
                      data-testid="vehicle-requisition-color-selection__vehicle-quantity"
                    >
                      Selected Quantity of Vehicles:{' '}
                      <span className="selected-count">{vehicleQuantity}</span>
                    </span>
                  </div>
                  {!isRequisitionCPT && (
                    <div className="color-selection-body">
                      <div
                        className={classNames('color-selection-list', {
                          'more-options': isRequisitionCPT,
                        })}
                      >
                        {!vehicleColors.length && (
                          <>
                            <div className="display-flex flex-row flex-justify-center">
                              <p data-testid="vehicle-requisition-color-selection__no-color">
                                No Colors selected
                              </p>
                            </div>
                            <div className="display-flex flex-row flex-justify-center">
                              <EmptyState />
                            </div>
                          </>
                        )}
                        {!!vehicleColors.length && (
                          <>
                            {vehicleColors.map((color, index) => (
                              <div
                                className={classNames(
                                  'grid-row selected-color-row',
                                )}
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                data-testid="vehicle-requisition-color-selection__selected-color-rows"
                              >
                                {!isRequisitionCPT && (
                                  <div
                                    className="cpt-label"
                                    key={`cpt-label-${color.value}`}
                                  >
                                    <span className="selection-title">
                                      Select color
                                    </span>
                                    <span className="selection-description">
                                      (If you require a Custom Paint Option,
                                      please go back to the Vehicle Comparison
                                      page and select CPT option, if available.)
                                    </span>
                                  </div>
                                )}
                                <div className="grid-col-7">
                                  <div
                                    className={classNames(
                                      'usa-form-group quantity-form-group',
                                      hasError(
                                        'colorSelection',
                                        'colors',
                                        VehicleRequisitionErrors[
                                          VALIDATION_ERRORS_SECTIONS.COLORS
                                          ],
                                      ) && getErrorForColor() !== null
                                        ? 'usa-form-group--error'
                                        : '',
                                    )}
                                  >
                                    {getErrorForColor()}
                                    <SelectDropdown
                                      label="Selected Color"
                                      data-testid={`vehicle-requisition-color-selection__select-color-${index}`}
                                      id="color-selection-dropdown"
                                      name="color-selection-dropdown"
                                      options={buildColorList()}
                                      onChange={(e) =>
                                        selectColor(e?.target?.value, index)
                                      }
                                      defaultValue={
                                        vehicleColors[index]?.color?.value
                                      }
                                      value={vehicleColors[index]?.color?.value}
                                    />
                                  </div>
                                </div>
                                {isRequisitionCPT && (
                                  <>
                                    <div className="grid-col-4">
                                      <div
                                        className={classNames(
                                          'usa-form-group quantity-form-group',
                                          hasError(
                                            'colorSelection',
                                            'colors',
                                            VehicleRequisitionErrors[
                                              VALIDATION_ERRORS_SECTIONS.COLORS
                                              ],
                                          )
                                            ? 'usa-form-group--error'
                                            : '',
                                        )}
                                      >
                                        {getErrorTextIfAny()}
                                        <input
                                          type="number"
                                          className={classNames(
                                            'usa-input color-quantity-input',
                                            hasError(
                                              'colorSelection',
                                              'colors',
                                              VehicleRequisitionErrors[
                                                VALIDATION_ERRORS_SECTIONS
                                                  .COLORS
                                                ],
                                            )
                                              ? 'usa-input--error'
                                              : '',
                                          )}
                                          onChange={
                                            (event) =>
                                              onChangeQuantity(event, index) // eslint-disable-next-line
                                          }
                                          onBlur={() => handleValidations()}
                                          value={vehicleColors[index]?.quantity}
                                          data-testid={`vehicle-requisition-color-selection__vehicle-quantity-${index}`}
                                          aria-label="quantity per color"
                                        />
                                      </div>
                                    </div>
                                    <div className="grid-col-1 delete-button-container">
                                      <button
                                        type="button"
                                        className="usa-button--unstyled delete-button"
                                        onClick={
                                          (event) =>
                                            deleteCurrentColor(event, index) // eslint-disable-next-line
                                        }
                                        data-testid={`vehicle-requisition-color-selection__delete-selected-color-${index}`}
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </>
                                )}
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {paintAndGraphicsOpt()}
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

ColorSelection.propTypes = {
  requisitionDraftId: PropTypes.string,
  notifyUserRef: PropTypes.shape({}).isRequired,
};

ColorSelection.defaultProps = {
  requisitionDraftId: '',
};

export default ColorSelection;
