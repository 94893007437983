export const VehicleRequisitionContextActions = {
  UPDATE_CURRENT_STANDARD_ITEM: 'updateCurrentStandardItem',
  UPDATE_COMPARE_VEHICLES_CURRENT_PAGE: 'updateCompareVehiclesCurrentPage',
  UPDATE_ALL_ACTIVE_CONTRACTS: 'updateActiveContracts',
  UPDATE_ACTIVE_ENGINE_INFO: 'updateActiveEngineInfo',
  UPDATE_VEHICLE_DATA_FLATTENED: 'updateVehicleDataFlattened',
  UPDATE_DISPLAYING_ACTIVE_CONTRACTS: 'updateDisplayingActiveContracts',
  UPDATE_ALL_MINIMUM_REQS: 'updateAllMinReqs',
  UPDATE_DISPLAYING_MINIMUM_REQS: 'updateDisplayingMinimumReqs',
  UPDATE_ALL_OPTIONAL_REQS: 'updateAllOptionalReqs',
  UPDATE_DELIVERY_OPTIONS: 'updateDeliveryOptions',
  UPDATE_DISPLAYING_OPTIONAL_REQS: 'updateDisplayingOptionalReqs',
  UPDATE_ALL_VEHICLE_OVERVIEW_DATA: 'updateAllVehicleOverviewData',
  UPDATE_DISPLAYING_VEHICLE_OVERVIEW_DATA:
    'updateDisplayingVehicleOverviewData',
  UPDATE_ALL_VEHICLE_ENG_FUEL_DATA: 'updateAllVehicleEngFuelData',
  UPDATE_DISPLAYING_VEHICLE_ENG_FUEL_DATA: 'updateDisplayingVehicleEngFuelData',
  UPDATE_ALL_VEHICLE_INFORMATION_DATA: 'updateAllVehicleInformationData',
  UPDATE_DISPLAYING_VEHICLE_INFORMATION_DATA:
    'updateDisplayingVehicleInformationData',
  UPDATE_ALL_COST_BREAKDOWN_DATA: 'updateAllCostBreakDownData',
  UPDATE_DISPLAYING_COST_BREAKDOWN_DATA: 'updateDisplayingCostBreakdownData',
  UPDATE_DISPLAYING_OTHER_FEATURES_DATA: 'updateDisplayingOtherFeaturesData',
  UPDATE_SHOW_DRAFT_MESSAGE: 'updateShowDraftMessage',
  UPDATE_DRAFT_REQUISITION: 'updateDraftRequisition',
  UPDATE_INACTIVE_CONTRACT: 'updateInactiveContract',
  UPDATE_SIN: 'updateSin',
  UPDATE_SELECTED_OPTIONS_FOR_PRICE: 'updateSelectedOptionForPrice',
  UPDATE_FEATURES_SECTION: 'updateFeaturesSection',
  UPDATE_MIN_REQ_SECTION: 'updateMinReqSection',
  UPDATE_COMPARE_VEHICLES_PAGE_INFO: 'updateCompareVehiclesPageInfo',
  UPDATE_COMPARE_VEHICLES_ENGINE_FUEL_INFO: 'updateCompareVehiclesEngineFuelInfo',
  UPDATE_VEHICLE_QUANTITY: 'updateVehicleQuantity',
  UPDATE_VEHICLE_QUANTITY_HAS_ERROR: 'updateVehicleQuantityHasError',
  UPDATE_DRAFT_PROPERTIES: 'updateDraftProperties',
  UPDATE_ADD_OPTIONS_STATE: 'updateAddOptionsState',
  UPDATE_ADD_OPTIONS_WITH_COLLISIONS: 'updateAddOptionsWithCollisions',
  UPDATE_SELECTED_ITEMS_FOR_PURCHASE: 'updateSelectedItemsForPurchase',
  UPDATE_OPTIONS_WITH_COLLISIONS_PROPERTIES:
    'updateOptionsWithCollisionsProperties',
  RESET_OLD_SELECTED_CONTRACT_INFO: 'resetOldSelectedContractInfo',
  UPDATE_SELECTED_CONTRACT_INFO: 'updateSelectedContractInfo',
  UPDATE_REQUISITION_HAS_ERROR: 'updateRequisitionHasError',
  UPDATE_SELECTED_COLORS: 'updateSelectedColors',
  UPDATE_NON_LOW_BID_JUSTIFICATION: 'updateNonLowBidJustification',
  UPDATE_AVAILABLE_COLORS: 'updateAvailableColors',
  UPDATE_VEHICLE_REQUISITION_METHODS: 'updateVehicleRequisitionMethods',
  UPDATE_PURCHASE_COLLISIONS_INFO: 'updatePurchaseCollisionsInfo',
  UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION: 'useRequiredOptionsFromSession',
  UPDATE_IS_CALCULATE_PRICE_BUTTON_DISABLED:
    'updateCalculatePriceButtonDisabled',
  UPDATE_IS_CALCULATE_PRICE_ERROR: 'updateCalculatePriceError',
  UPDATE_CONTRACT_AGENCY_INFORMATION: 'updateContractAgencyInformation',
  UPDATE_CURRENT_REQUISITION_STEP: 'updateCurrentRequisitionStep',
  RESET_REQUISITION_STATE: 'resetRequisitionState',
  UPDATE_COLLISION_INFO: 'updateCollisionInfo',
  UPDATE_CALCULATED_PRICE_INFO: 'updateCalculatedPriceInfo',
  UPDATE_ADDRESS_VERIFICATION_MODAL: 'updateAddressVerificationModal',
  UPDATE_USPS_MODAL_OPTIONS_SELECTED_STATE:
    'updateUspsOptionsSelectedModalState',
  INIT_USPS_MODAL_OPTIONS_SELECTED_STATE: 'initUspsOptionsSelectedModalState',
  UPDATE_REQUISITION_VALIDATED_ADDRESS: 'updateRequisitionValidatedAddress',
  UPDATE_MAILING_VALIDATED_ADDRESS: 'updateMailingValidatedAddress',
  UPDATE_DELIVERY_VALIDATED_ADDRESS: 'updateDeliveryValidatedAddress',
  UPDATE_VALIDATED_ADDRESS_NOT_FOUND: 'updateValidatedAddressNotFound',
  CLEAR_VALIDATED_ADDRESS_NOT_FOUND: 'clearValidatedAddressNotFound',
  RESET_USPS_VALIDATION: 'resetUspsValidation',
  UPDATE_REQ_ADDRESS_STATE: 'updateReqAddressState',
  UPDATE_REQUISITION_STATE: 'updateRequisitionState',
  UPDATE_MAILING_STATE: 'updateMailingState',
  UPDATE_DELIVERY_STATE: 'updateDeliveryState',
  UPDATE_INITIAL_REQUISITION_STATE: 'updateInitialRequisitionState',
  UPDATE_INITIAL_MAILING_STATE: 'updateInitialMailingState',
  UPDATE_INITIAL_DELIVERY_STATE: 'updateInitialDeliveryState',
  UPDATE_INITIAL_ENTITY_NAME: 'updateInitialEntityName',
  UPDATE_DEALERSHIP_DELIVERY: 'updateDealershipDelivery',
  UPDATE_REQUISITION_FETCH_STATES: 'updateRequisitionFetchStates',
  UPDATE_MAILING_FETCH_STATES: 'updateMailingFetchStates',
  UPDATE_DELIVERY_FETCH_STATES: 'updateDeliveryFetchStates',
  UPDATE_REQUISITION_ADDRESS_HAS_ERROR: 'udateRequisitionAddressHasError',
  UPDATE_REQUISITION_MAILING_HAS_ERROR: 'udateMailingAddressHasError',
  UPDATE_REQUISITION_DELIVERY_HAS_ERROR: 'udateDeliveryAddressHasError',
  RESET_CONTRACT_SELECTIONS: 'resetContractSelections',
  UPDATE_IS_CALCULATE_PRICE_ERROR_AND_BUTTON_DISABLED:
    'udateIsCalculatePriceErrorAndButtonDisabled',
  UPDATE_ADD_OPTIONS_ERRORS: 'updateAddOptionsErrors',
  UPDATE_SUBMIT_REQUISITION_MODAL: 'updateSubmitRequisitionModal',
  UPDATE_SUBMIT_REQUISITION_LOADING: 'updateSubmitRequisitionLoading',
  RESET_EXPIRED_SELECTED_CONTRACT_INFO: 'resetExpiredSelectedContractInfo',
  SET_FORM_ERROR_FOCUS: 'setFormErrorFocus',
  UPDATE_REQUISITION_SUBMIT_COMMENT: 'updateRequisitionSubmitComment',
  UPDATE_GET_CATALOGS_FOR_CATEGORY_CALLING_CODES:
    'updateGetCatalogsForCategoryCallingCodes',
  UPDATE_CUSTOM_PAINT_OPTIONS: 'updateCustomPaintOptions',
  UPDATE_PAINT_AND_GRAPHIC_OPTIONS_DESC: 'updatePaintAndGraphicOptionsDesc',
  UPDATE_FILE_UPLOAD_SECTION: 'updateFileUploadSection',
  UPDATE_TOTAL_UPLOADED_FILES: 'updateTotalUploadedFiles',
  UPDATE_TAGGED_OPTIONS_CUSTOMER_INPUT: 'updateTaggedOptionsCustomerInput',
  UPDATE_TAGGED_OPTIONS: 'updateTaggedOptions',
  SUBMIT_REQUISITION_SUCCESS_ACTION: 'submitRequisitionSuccessAction',
  VALIDATIONS_ERROR_STATE: 'validationErrorsOnSubmit',
  SET_VALIDATIONS_MODAL_OPEN: 'setModalOpenOnSubmitValidations',
  SET_REQ_NUMBER_VALIDATIONS_MODAL_OPEN: 'setReqNumberValidationModalOpen',
  SET_NOTIFY_MODAL_OPEN: 'setModifyModalOpen',
  SELECTED_AGECY_ORGANIZATION_CODE: 'updateAgencyOrganizationCode',
  IS_DOMESTIC_SHIPMENT: 'updateShippmentForDomestic',
  GET_BOAC_FOR_CORL_ERRORS: 'getBoacForSignalCodeCOrLErrors',
  GET_BOAC_FOR_CORL: 'getBoacForSignalCodeCOrL',
  SET_IS_AREQ: 'setIsAreq',
  EXCLUDE_AREQ_OPTION: 'excludeAreqOption',
  UPDATE_AREQ_VENDOR_QUOTES: 'updateAreqVendorQuotes',
  UPDATE_AREQ_PENDING_CUSTOMER_RESPONSE: 'updateAreqPendingCustomerResponse',
  UPDATE_AREQ_PRINT_PREVIEW: 'updateAreqPrintPreview',
  UPDATE_SOP_PRINT_PREVIEW: 'updateSOPPrintPreview',
  UPDATE_MAS_PRINT_PREVIEW: 'updateMasPrintPreview',
  UPDATE_URGENT_REQ_PRINT_PREVIEW: 'updateUrgReqPrintPreview',
  UPDATE_AGENCY_REF_NUMBER: 'updateAgencyReferenceNumber',
  UPDATE_ADDITIONAL_REQUIREMENTS: 'updateAdditionalRequirements',
  UPDATE_NON_SOP_OPTION: 'updateNonSopOption',
  UPDATE_SPECIAL_DELIVERY_INSTRUCTIONS: 'updateSpecialDeliveryInstructions',
  UPDATE_DELIVERY_DATE: 'updateDeliveryDate',
  UPDATE_URGENT_REQUIREMENT_JUSTIFICATION:
    'updateUrgentRequirementJustification',
  UPDATE_NON_SOP_DATA: 'updateNonSopData',
  UPDATE_MAS_REQUIREMENT_SPECS: 'updateMasRequirementSpecs',
  UPDATE_URG_REQ_ERROR_BANNER: 'updateUrgReqErrorBanner',
  UPDATE_MAS_REQ_ERROR_BANNER: 'updateMasReqErrorBanner',
  UPDATE_URG_REQ_JUSTIFICATION: 'updateUrgReqJustification',
  UPDATE_AREQ_JUSTIFICATION: 'updateAreqJustification',
  UPDATE_URGENT_REQUIREMENT_CURRENT_STEP: 'updateUrgentRequirementCurrentStep',
  UPDATE_MAS_REQUIREMENT_CURRENT_STEP: 'updateMasRequirementCurrentStep',
  UPDATE_REVIEW_SUBMIT_TAB: 'updateReviewSubmitTab',
  UPDATE_FILES_UPLOADED_LIST: 'updateFilesUploadedList',
  UPDATE_URG_REQUIREMENT_DELIVERY_DATE: 'updateUrgRequirementDeliveryDate',
  UPDATE_URGENT_REQUIREMENT_STEPS_INDICATOR:
    'updateUrgentRequirementStepsIndicator',
  UPDATE_MAS_REQUIREMENT_STEPS_INDICATOR: 'updateMasRequirementStepsIndicator',
  SET_REQUISITION_TYPE: 'setRequisitionType',
  SET_IS_LEASING_ADDITIONAL_REQUIREMENTS_PREVIEW:
    'setIsLeasingAdditionalRequirementsPreview',
  SET_REQUISITION_ATTACHMENT_TYPES: 'setRequisitionAttachmentTypes',
  UPDATE_PRE_DRAFT_COMMENTS: 'updatePreDraftComments',
  UPDATE_URGENT_REQUIREMENT_PLACE_ORDER_STATE:
    'updateUrgentRequirementPlaceOrderState',
  UPDATE_MAS_PLACE_ORDER_STATE: 'updateMasContractNumber',
  UPDATE_AREQ_ENGINEER_QUOTES: 'updateAreqEngineerQuotes',

};

export default VehicleRequisitionContextActions;
